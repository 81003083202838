import React, { useState } from "react";
import Styles from "./faqs.module.scss";

export default function Faqs({ faqList }) {
  const [expandItem, setExpandItem] = useState("");
  return (
    <div>
      {faqList.map((faq, index) => {
        return (
          <div className={Styles.faq} onClick={() => setExpandItem(index)} key={index}>
            <div className={Styles.question}>
              {faq.ques}
              <div className={Styles.expandArrow}>
                <span className="material-symbols-outlined">{ expandItem === index ? "expand_more" : "expand_less"}</span>
              </div>
            </div>
            {expandItem === index ? (
              <div className={Styles.answer}>{faq.ans}</div>
            ) : (
              ""
            )}
          </div>
        );
      })}
      <br></br>
    </div>
  );
}
