import React from "react";
import Styles from "./bottomNavigation.module.scss";
import { Link } from "react-router-dom";

export function BottomNavigation({ navigationSelection }: any) {
  return (
    <div
      className={`${Styles.bottomNavigation} d-flex align-items-center justify-content-evenly fixed-bottom`}
    >
      <div>
        <Link to="/">
          <div
            className={`${Styles.item} ${
              navigationSelection === "home" ? Styles.active : ""
            }`}
          >
            <div className={Styles.iconImage}>
              <span className="material-symbols-outlined">home</span>
            </div>
            <div className={Styles.title}>Home</div>
          </div>
        </Link>
      </div>
      <div>
        <Link to="/consult">
          <div
            className={`${Styles.item} ${
              navigationSelection === "consult" ? Styles.active : ""
            }`}
          >
            <div className={Styles.iconImage}>
              <span className="material-symbols-outlined">stethoscope</span>
            </div>
            <div className={Styles.title}>Consult</div>
          </div>
        </Link>
      </div>
      <div>
        <Link to="/medicine">
          <div
            className={`${Styles.item} ${
              navigationSelection === "medicine" ? Styles.active : ""
            }`}
          >
            <div className={Styles.iconImageMedicine}>
              <span
                className="material-symbols-outlined"
                style={{ fontSize: "30px", marginTop: "15px" }}
              >
                medication
              </span>
            </div>
            <div className={Styles.title} style={{ marginBottom: "5px" }}>
              Medicine
            </div>
          </div>
        </Link>
      </div>
      <div>
        <Link to="/ehr">
          <div
            className={`${Styles.item} ${
              navigationSelection === "ehr" ? Styles.active : ""
            }`}
          >
            <div className={Styles.iconImage}>
              <span className="material-symbols-outlined">article</span>
            </div>
            <div className={Styles.title}>EHR</div>
          </div>
        </Link>
      </div>

      <div>
        <Link to="/profile">
          <div
            className={`${Styles.item} ${
              navigationSelection === "profile" ? Styles.active : ""
            }`}
          >
            <div className={Styles.iconImage}>
              <span className="material-symbols-outlined">account_circle</span>
            </div>
            <div className={Styles.title}>Profile</div>
          </div>
        </Link>
      </div>
      {/* <div>
        <Link to="/cart">
          <div
            className={`${Styles.item} ${
              navigationSelection === "cart" ? Styles.active : ""
            }`}
          >
            <div className={Styles.iconImage}>
              <span className="material-symbols-outlined">shopping_cart</span>
            </div>
            <div className={Styles.title}>Cart</div>
          </div>
        </Link>
      </div> */}
    </div>
  );
}
