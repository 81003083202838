import React, { useState } from "react";
import Styles from "./couponChecker.module.scss";

export default function CouponChecker(props) {
  const [couponCode, setCouponCode] = useState("");
  // Check the Coupon Code
  function verifyCouponCode() {
    if (couponCode.length > 7) {
      console.log("Check with Coupon API");
      props.handleCallback(true);
    } else {
      setCouponCode("");
      alert("Invalid Code");
      props.handleCallback(false);
    }
  }
  return (
    <div className={`${Styles.couponCodeChecker} d-flex mt-3`}>
      <input
        type="text"
        placeholder="Enter Coupon code here"
        onChange={(e) => setCouponCode(e.target.value)}
        onBlur={verifyCouponCode}
        value={couponCode}
      />
      <div className={Styles.checkLabel} onClick={verifyCouponCode}>
        Check
      </div>
    </div>
  );
}
