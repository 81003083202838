import React, { useEffect, useState } from "react";
import Styles from "./checkout.module.scss";
import AppBar from "shared/appBar/appBar";
import Heading from "shared/heading/heading";
import { LEFT, SMALL } from "shared/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/button/button";
import { primaryButtonStyle } from "shared/buttonStyles";
import { LARGE } from "shared/buttonSize";
import CouponChecker from "shared/couponChecker/couponChecker";
import { AppointmentAPI } from "apis/appointmentAPI";
import { ToastMessage } from "shared/toast/toastMsg";
import Loading from "shared/loading/loading";
import { appointmentCheckoutData, bookSlotRequestBody } from "apis/interface";

export default function Checkout() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [couponStatus, setCoupnStatus] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [checkoutData, setCheckoutData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledCheckoutButton, setIsDisabledCheckoutButton] =
    useState(true);

  const walletBalance = 0;

  let gstAmount_5 = 0;
  let gstAmount_12 = 0;
  let gstAmount_18 = 0;
  let gstAmount_28 = 0;
  let billGrandTotal = 0;

  function updateGstAmount(product: any) {
    let amount = product.pricePreGst * product.qty;
    switch (product.gstSlab) {
      case 5:
        gstAmount_5 = amount * (5 / 100);
        break;
      case 12:
        gstAmount_12 = amount * (12 / 100);
        break;
      case 18:
        gstAmount_18 = amount * (18 / 100);
        break;
      case 28:
        gstAmount_28 = amount * (28 / 100);
        break;
      default:
        amount = 0; // Zero gst case;
    }
  }

  function couponCodeCallback(couponStatusData: boolean) {
    console.log(couponStatusData);
    if (couponStatusData !== false) setCoupnStatus(couponStatusData);
  }

  function getCheckoutDetails() {
    setIsLoading(true);
    const body: appointmentCheckoutData = {
      memberId: state.patient.id,
      doctorId: state.doctor.id,
      slot: state.time,
      date: state.slot,
      isWallet: false,
      isUseSlot: false,
    };
    AppointmentAPI.getAppointmentCheckout(body).then((response) => {
      console.log(response.data);
      setIsLoading(false);
      if (response.success === true && response.data !== null) {
        setIsDisabledCheckoutButton(false);
        const billData = {
          checkoutId: response.data._id,
          gstSlab: response.data.tax.percentage,
          productName: "Consultation Fees: ",
          pricePreGst:
            response.data.totalPayAmount - response.data.tax.applyTaxAmount,
          qty: 1,
        };
        setCheckoutData([billData]);
      } else {
        // Slot Not available
        ToastMessage.show(
          "Slot Already booked. Please select another Date/Time."
        );
        navigate("/consult/booking", { state: state });
      }
    });
  }

  /**
   *    Proceed To Pay
   */
  async function proceedToPay() {
    setLoading(true);
    setIsDisabledCheckoutButton(true);
    // Hit the book Slot API
    // Based on this response proceed to Razorpay Gateway or not
    const body: bookSlotRequestBody = {
      patientId: state.patient.id,
      checkoutId: checkoutData[0].checkoutId,
      deviceId: "TE1A.220922.010",
      platform: "android",
      appVersion: "1.0.3",
      symptom: state.symptoms[0] ?? "Other",
      userRemark: "",
    };
    const response = await AppointmentAPI.bookSlot(body);
    setLoading(false);
    setIsDisabledCheckoutButton(false);
    if (response.success === true) {
      const razorpay_checkout_data = {
        amount: response.data.orderInstance.amount_due.toString(),
        currency: response.data.currency,
        business_name: "Kapeefit",
        description: "Order",
        image: "https://www.kapeefit.com/favicon.png",
        razorpay_order_id: response.data.razorpayOrderId,
        patient_name: state.patient.title,
        patient_mobile: response.data.patientMobile,
        patient_email: "temp@kapeefit.com",
        appointment_id: response.data._id,
        patient_id: response.data.patientId,
      };
      navigate("/payment", {
        state: { razorpay_checkout_data, checkoutState: state },
      });
    } else {
      ToastMessage.show(
        "Slot unavailable now. Please select another Date/Time."
      );
    }
  }

  useEffect(() => {
    getCheckoutDetails();
  }, []);
  return (
    <>
      <AppBar title="Checkout" />

      <>
        <Heading
          size={SMALL}
          align={LEFT}
          title="Appointment Details"
          width={"70%"}
        />
        <div
          className={`${Styles.boxContainerOutline} ${Styles.appointmentDetailsContainer}`}
        >
          <div className={`${Styles.infoRow} d-flex`}>
            <div className={Styles.infoRowTitle}>Doctor's Name : </div>
            <div className={Styles.infoRowData}>{state.doctor.title}</div>
          </div>
          <div className={`${Styles.infoRow} d-flex`}>
            <div className={Styles.infoRowTitle}>Specialization : </div>
            <div className={Styles.infoRowData}>
              {state.doctor.specialization}
            </div>
          </div>
          <div className={`${Styles.infoRow} d-flex`}>
            <div className={Styles.infoRowTitle}>Date & Time : </div>
            <div className={Styles.infoRowData}>
              &nbsp;
              {state.slot.split("-")[2] +
                "/" +
                state.slot.split("-")[1] +
                "/" +
                state.slot.split("-")[0]}{" "}
              | {state.time}
            </div>
          </div>
          <div className={`${Styles.infoRow} d-flex`}>
            <hr />
          </div>
          <div className={`${Styles.infoRow} d-flex`}>
            <div className={Styles.infoRowTitle}>Patient's Name : </div>
            <div className={Styles.infoRowData}>{state.patient.title}</div>
          </div>
          <div className={`${Styles.infoRow} d-flex`}>
            <div className={Styles.infoRowTitle}>Age : </div>
            <div className={Styles.infoRowData}>{state.patient.age}</div>
          </div>
          <div className={`${Styles.infoRow} d-flex`}>
            <div className={Styles.infoRowTitle}>Gender : </div>
            <div className={Styles.infoRowData}>{state.patient.gender}</div>
          </div>
          <div className={`${Styles.infoRow} d-flex`}>
            <hr />
          </div>
          <div className={`${Styles.infoRow} d-flex`}>
            <div className={Styles.infoRowTitle}>Symptoms : </div>
            <div className={Styles.infoRowData}>
              {state.symptoms.map((symptom, index) =>
                index === state.symptoms.length - 1 ? symptom : symptom + ", "
              ) ?? "Other"}
            </div>
          </div>
          <div className={`${Styles.infoRow} d-flex`}>
            <div className={Styles.infoRowTitle}>Description : </div>
            <div className={Styles.infoRowData}>
              {state.symptoms?.description}
            </div>
          </div>
        </div>
      </>

      {/* {checkoutFor && checkoutFor !== "appointment" && <ProductCartList />} */}
      <div className="mt-4"></div>

      {/* Cupon Code Checker */}
      <CouponChecker handleCallback={couponCodeCallback} />

      {/* Wallet Selection */}
      <div className={`${Styles.walletSelection} d-flex mt-3`}>
        <div className={Styles.walletSelectionContent}>
          <b>Wallet</b>
          <br></br>
          <span>Your wallet balance is {walletBalance}</span>
        </div>
        <div className={Styles.walletCheckbox}>
          <input type="checkbox" />
        </div>
      </div>
      <div className="mt-4"></div>
      {/* Total Amount Calculations */}

      <div>
        <Heading size={LARGE} align={LEFT} title="Total Amount" width={"70%"} />
        {isLoading ? (
          <Loading loadingColor={"#E85222"} />
        ) : (
          <div className={`${Styles.billDetailsContainer} mt-2`}>
            {checkoutData.map((product, index) => {
              updateGstAmount(product);
              billGrandTotal +=
                gstAmount_5 +
                gstAmount_12 +
                gstAmount_18 +
                gstAmount_28 +
                product.pricePreGst * product.qty;
              return (
                <div className={`${Styles.billProductRow} d-flex`} key={index}>
                  <div className={Styles.billProductName}>
                    {product.productName}
                  </div>
                  <div className={Styles.billProductQty}>X {product.qty}</div>
                  <div className={Styles.billProductTotal}>
                    {(product.pricePreGst * product.qty).toFixed(2)}
                  </div>
                </div>
              );
            })}

            {/* GST Tax Rows */}
            <div className="mt-3"></div>
            {gstAmount_5 !== 0 ? (
              <div className={`${Styles.gstAmountRow} d-flex`}>
                <div className={Styles.billGstTitle}>GST @ 5%</div>
                <div className={Styles.billGstValue}>
                  &#8377;{gstAmount_5.toFixed(2)}
                </div>
              </div>
            ) : (
              <></>
            )}
            {gstAmount_12 !== 0 ? (
              <div className={`${Styles.gstAmountRow} d-flex`}>
                <div className={Styles.billGstTitle}>GST @ 12%</div>
                <div className={Styles.billGstValue}>
                  &#8377;{gstAmount_12.toFixed(2)}
                </div>
              </div>
            ) : (
              <></>
            )}
            {gstAmount_18 !== 0 ? (
              <div className={`${Styles.gstAmountRow} d-flex`}>
                <div className={Styles.billGstTitle}>GST @ 18%</div>
                <div className={Styles.billGstValue}>
                  &#8377;{gstAmount_18.toFixed(2)}
                </div>
              </div>
            ) : (
              <></>
            )}
            {gstAmount_28 !== 0 ? (
              <div className={`${Styles.gstAmountRow} d-flex`}>
                <div className={Styles.billGstTitle}>GST @ 28%</div>
                <div className={Styles.billGstValue}>
                  &#8377;{gstAmount_28.toFixed(2)}
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className={`${Styles.billTotal} d-flex`}>
              <div className={Styles.billTotalLabel}>Total</div>
              <div className={Styles.billTotalValue}>
                {billGrandTotal.toFixed(2)}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={Styles.proceedButton}
        onClick={() => {
          if (!isDisabledCheckoutButton) {
            proceedToPay();
          }
        }}
      >
        <Button
          disabled={isDisabledCheckoutButton}
          style={primaryButtonStyle}
          hoveredStyle={primaryButtonStyle}
          size={LARGE}
          isLoading={loading}
        >
          Proceed to checkout
        </Button>
      </div>
    </>
  );
}
