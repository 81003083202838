import MobileNumberSreen from "components/onboarding/mobileNumberScreen";
import PersonalDetails from "components/onboarding/personalDetails";
import VerifyOtpScreen from "components/onboarding/verifyOtpScreen";
import RegistrationStatus from "components/onboarding/registrationStatus";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "components/home/home";
import ProductView from "shared/product/productView";
import Wishlist from "components/misc/wishlist/wishlist";
import PageNotFound from "shared/pageNotFound/pageNotFound";
import Ehr from "components/ehr/ehr";
import Consult from "components/consult/consult";
import Profile from "components/profile/profile";
import FamilyMembers from "components/profile/familyMembers/familyMembers";
import NotificationsScreen from "components/misc/notificationsScreen/notificationsScreen";
import AppointmentsScreen from "components/consult/myAppointments/myAppointments";
import ProfileAddresses from "components/profile/profileAddresses/profileAddresses";
import OrdersScreen from "components/profile/orders/medicineOrders/ordersScreen";
import Wallet from "components/wallet/wallet";
import Subscriptions from "components/profile/subscriptions/subscriptions";
import PathologyBookingsScreen from "components/pathology/pathologyBookingsScreen/pathologyBookingsScreen";
import AboutUs from "components/misc/aboutUs/aboutUs";
import AppointmentDetails from "components/consult/appointmentDetails/appointmentDetails";
import EditProfileAddresses from "components/profile/profileAddresses/editProfileAddresses";
import WalletTransactions from "components/wallet/walletTransactions/walletTransactions";
import Plans from "components/profile/subscriptions/plans";
import Shop from "components/shop/shop";
import Cart from "components/cart/cart";
import MedicineCart from "components/cart/medicineCart/medicineCart";
import PathologyCart from "components/cart/pathlogyCart/pathologyCart";
import AppointmentBooking from "components/consult/appointmentBooking/appointmentBooking";
import Checkout from "components/checkout/checkout";
import Pathology from "components/pathology/pathology";
import PathologyTestDetailsScreen from "shared/pathologyTest/pathologyTestDetailsScreen/pathalogyTestDetailsScreen";
import UpdateProfile from "components/profile/updateProfile/updateProfile";
import EditAddFamilyMember from "components/profile/familyMembers/editAddFamilyMember";
import WalletHelp from "components/wallet/walletHelp/walletHelp";
import Medicine from "components/medicine/medicine";
import FilterScreen from "shared/filterScreen/filterScreen";
import Blogs from "components/blogs/blogs";
import RazorpayView from "shared/paymentView/razorpayView/razorpayView";
import LobbyScreen from "components/videoConfrencing/lobbyScreen";
import Logout from "components/profile/logout";
import ReadBlog from "components/blogs/readBlog";
import ChatWidget from "shared/chatWidget/chatWidget";
import MedicinePayView from "shared/paymentView/razorpayView/medicinePayView";
import OrderDetailsScreen from "components/profile/orders/medicineOrders/orderDetailsScreen";
import LabTestDetails from "components/shop/labTestDetails";
import Vitals from "components/ehr/vitals/vitals";
import CallScreen from "components/videoConfrencing/callScreen";

export default function SysRouter() {
  const PAYMENT_COMPONENT = {
    razorpay: <RazorpayView />,
  };
  return (
    <Routes>
      {/* Access/Authentications */}
      <Route path={"/login"} element={<MobileNumberSreen />} />
      <Route path={"/logout"} element={<Logout />} />
      <Route path={"/verifyOtp"} element={<VerifyOtpScreen />} />
      <Route path={"/personalDetails"} element={<PersonalDetails />} />
      <Route
        path={"/registrationStatusScreen"}
        element={<RegistrationStatus />}
      />
      {/* Default/Home */}
      <Route path={"/"} element={<Home />} />
      {/* E-Commerce/Shop/LabTest/Pathology */}
      <Route path={"/product/:id"} element={<ProductView />} />
      <Route path={"/pathology/:id"} element={<LabTestDetails />} />
      <Route path={"/wishlist"} element={<Wishlist />} />
      <Route path={"/medicine"} element={<Medicine />} />
      <Route path={"/shop"} element={<Shop />} />
      <Route path={"/shop/order/track"} element={<OrderDetailsScreen />} />

      {/* Orders */}
      <Route path={"/medicine/orders"} element={<OrdersScreen />} />
      <Route path={"/medicine/order/track"} element={<OrderDetailsScreen />} />
      {/* EHR */}
      <Route path={"/ehr"} element={<Ehr />} />
      <Route path={"/ehr/vitals"} element={<Vitals />} />
      {/* Appointments & Consultation */}
      <Route path={"/consult"} element={<Consult />} />
      <Route path={"/consult/booking"} element={<AppointmentBooking />} />
      <Route path={"/appointments"} element={<AppointmentsScreen />} />
      <Route
        path={"/appointment/:appointmentId"}
        element={<AppointmentDetails />}
      />
      <Route path={"/appointment/lobby"} element={<LobbyScreen />} />
      <Route path={"/appointment/chat"} element={<ChatWidget />} />
      <Route path={"/appointment/confrence"} element={<CallScreen />} />
      {/* User Profile, Family Members and Details */}
      <Route path={"/profile"} element={<Profile />} />
      <Route path={"/profile/familyMembers"} element={<FamilyMembers />} />
      <Route
        path={"/profile/familyMembers/addNew"}
        element={<EditAddFamilyMember />}
      />
      <Route
        path={"/profile/familyMembers/edit/:id"}
        element={<EditAddFamilyMember />}
      />
      <Route path={"/profile/addresses"} element={<ProfileAddresses />} />
      <Route path={"/profile/addAddress"} element={<EditProfileAddresses />} />
      <Route
        path={"/profile/editAddress/:id"}
        element={<EditProfileAddresses />}
      />
      <Route path={"/profile/update"} element={<UpdateProfile />} />
      <Route path={"/subscription"} element={<Subscriptions />} />
      <Route path={"/subscription/plans"} element={<Plans />} />
      {/* Cart */}
      <Route path={"/checkout"} element={<Checkout />} />
      <Route path={"/cart"} element={<Cart />} />
      <Route path={"/cart/medicine"} element={<MedicineCart />} />
      <Route path={"/cart/pathology"} element={<PathologyCart />} />
      {/* Pathology and Bookings */}
      <Route path={"/pathology"} element={<Pathology />} />
      <Route
        path={"/pathologyTestDetail/:id"}
        element={<PathologyTestDetailsScreen />}
      />
      <Route
        path={"/pathology/bookings"}
        element={<PathologyBookingsScreen />}
      />
      {/* Blogs */}
      <Route path={"/blogs"} element={<Blogs />} />
      <Route path={"/readBlog"} element={<ReadBlog />} />
      {/* Miscellaneous Screen Routes */}
      <Route path={"/notifications"} element={<NotificationsScreen />} />
      <Route path={"/wallet"} element={<Wallet />} />
      <Route path={"/wallet/transactions"} element={<WalletTransactions />} />
      <Route path={"/wallet/help"} element={<WalletHelp />} />
      <Route path={"/aboutUs"} element={<AboutUs />} />
      <Route path={"/filter"} element={<FilterScreen />} />
      <Route
        path={"/payment"}
        element={PAYMENT_COMPONENT[process.env.REACT_APP_PAYMENT_GATEWAY!]}
      />
      <Route path={"/cart/medicine/payment"} element={<MedicinePayView />} />
      <Route path={"*"} element={<PageNotFound />} />
    </Routes>
  );
}
