import React, { useEffect, useState } from "react";
import Styles from "./profile.module.scss";
import AppBar from "shared/appBar/appBar";
import { BottomNavigation } from "shared/bottomNavigation/bottomNavigation";
import { PROFILE, UserDefaultImage_Male } from "shared/constants";
import { Link } from "react-router-dom";
import List from "shared/list/list";
import Button from "shared/button/button";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { IntProfileData } from "apis/interface";
import { getAgeFromDOB } from "utils/utils";

export default function Profile() {
  const [profileData, setProfileData] = useState<any>({});

  const listItems = [
    {
      id: 1,
      link: "/notifications",
      icon: "notifications_active",
      title: "Notifications",
      action: "chevron_right",
    },
    {
      id: 2,
      link: "/appointments",
      icon: "medical_services",
      title: "Appointments",
      action: "chevron_right",
    },
    {
      id: 3,
      link: "/profile/addresses",
      icon: "location_on",
      title: "My Addresses",
      action: "chevron_right",
    },
    {
      id: 10,
      link: "/wishlist",
      icon: "favorite",
      title: "Wishlist",
      action: "chevron_right",
    },
    {
      id: 4,
      link: "/medicine/orders",
      icon: "local_mall",
      title: "My Orders",
      action: "chevron_right",
    },
    {
      id: 5,
      link: "/wallet",
      icon: "account_balance_wallet",
      title: "Wallet",
      action: "chevron_right",
    },
    {
      id: 6,
      link: "/subscription",
      icon: "subscriptions",
      title: "My Subscription",
      action: "chevron_right",
    },
    {
      id: 7,
      link: "/pathology/bookings",
      icon: "biotech",
      title: "Pathology Bookings",
      action: "chevron_right",
    },
    {
      id: 8,
      link: "/aboutUs",
      icon: "info",
      title: "About Us",
      action: "chevron_right",
    },
    {
      id: 9,
      link: "/logout",
      icon: "logout",
      title: "Log Out",
      action: "chevron_right",
    },
  ];

  const loggedOutListItems = [
    {
      id: 8,
      link: "/aboutUs",
      icon: "info",
      title: "About Us",
      action: "chevron_right",
    },
  ];
  useEffect(() => {
    const profileInfo: IntProfileData = localStorage.getItem("profileData")
      ? JSON.parse(localStorage.getItem("profileData")!)
      : {
          id: "",
          name: "Guest",
          dob: 0,
          mobile: "",
          gender: "",
          image: UserDefaultImage_Male,
        };
    profileInfo.image = profileInfo.image ?? UserDefaultImage_Male;
    setProfileData(profileInfo);
  }, []);
  return (
    <>
      <AppBar title="Profile" />
      {/* Profile Account Card */}
      {!Object.keys(profileData).includes("access_token") ? (
        <div className={`${Styles.loginText} d-flex`}>
          <div>Login to get better expirience</div>
          <div className="flex-grow-1">
            <Link to="/login">
              <Button
                disabled={false}
                style={primaryButtonStyle}
                hoveredStyle={primaryButtonHoveredStyle}
              >
                Login
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className={`${Styles.profileCard} d-flex`}>
          <div className={Styles.profileCard_Info}>
            <div className={Styles.profileName}>{profileData.name}</div>
            <div className={Styles.ageGender}>
              {`Age: ${getAgeFromDOB(profileData.dob)}`},{" "}
              {profileData.gender.toUpperCase()}
            </div>
            <div className={Styles.mobile}>+91{profileData.mobile}</div>
            <div className={Styles.addMemberLable}>
              <Link to="/profile/familyMembers">
                <span className="material-symbols-outlined">add</span> Add a
                Member
              </Link>
            </div>
          </div>
          <div className={Styles.profileCard_Image}>
            <img
              src={profileData.image}
              alt="Profile Pic"
              className={Styles.profileImage}
            />
            <Link to={"/profile/update"}>
              <div className={Styles.editIcon}>
                <span className="material-symbols-outlined">edit</span>
              </div>
            </Link>
          </div>
        </div>
      )}
      {/* List options */}
      <List
        listItems={
          !Object.keys(profileData).includes("access_token")
            ? loggedOutListItems
            : listItems
        }
      />
      <BottomNavigation navigationSelection={PROFILE} />
    </>
  );
}
