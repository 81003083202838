import React, { useEffect, useState } from "react";
import Styles from "./medicine.module.scss";
import { BottomNavigation } from "shared/bottomNavigation/bottomNavigation";
import {
  DiseaseDefaultImage,
  Icon_BuyMedicinesAndEssentials,
  Icon_OneClickAppointment,
  LEFT,
  MEDICINE,
  ProductDefaultImage,
  SMALL,
} from "shared/constants";
import TopNavigation from "shared/topNavigation/topNavigation";
import IconArrowButton from "shared/iconArrowButton/iconArrowButton";
import ProductMiniCard from "shared/product/productMiniCard";
import { Link } from "react-router-dom";
import Heading from "shared/heading/heading";
import ProductCard from "shared/product/productCard";
import ChipCardView from "shared/chipCardView/chipCardView";
import CarouselSlider from "shared/carouselSystem/carouselSlider";
import { ContentAPI } from "apis/contentAPI";
import { SymptomsAPI } from "apis/symptomsAPI";

const Medicine = () => {
  const [filters, setFilters] = useState({
    sale: ["On Sale"],
    symptoms: [],
    category: [""],
    brand: [""],
  });
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [healthConditions, setHealthConditions] = useState([]);
  const [topCategory, setTopCategory] = useState([]);
  const [mostBuyProducts, setMostBuyProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [banners, setBanners] = useState<any>({
    MedicineTop: [],
    MedicineMid: [],
    MedicineBottom: [],
  });

  /**
   *    Get Medicine Page Data
   */
  function getPageData() {
    ContentAPI.getMedicinePage().then((response) => {
      if (response?.success === true) {
        Object.keys(response.data).map((group) => {
          // Case to Handel Banners seprately as it is an Object and others are array
          if (group === "bannerList") {
            let topBanners: string[] = [];
            let midBanners: string[] = [];
            let bottomBanners: string[] = [];
            for (const bannerGroup in response.data[group]) {
              response.data[group][bannerGroup].map((banner) => {
                if (bannerGroup === "PatientMedicTopBanner") {
                  topBanners.push(banner.image);
                } else if (bannerGroup === "PatientMedicMidBanner") {
                  midBanners.push(banner.image);
                } else if (bannerGroup === "PatientMedicBottomBanner") {
                  bottomBanners.push(banner.image);
                }
                return true;
              });
            }
            setBanners({
              MedicineTop: topBanners,
              MedicineBottom: bottomBanners,
              MedicineMid: midBanners,
            });
          }
          const dataArray =
            response.data[group].length > 0
              ? response.data[group].map((info: any) => {
                  if (group === "productOnSale") {
                    return {
                      id: info._id,
                      title: info.name,
                      thumbnail:
                        info.images && info.images.length > 0
                          ? info.images[0].src
                          : ProductDefaultImage,
                      regularPrice: Number(info.regular_price),
                      sellingPrice: Number(info.sale_price),
                      ratingValue: Number(info.average_rating).toFixed(1),
                      ratingCount: Number(info.rating_count).toFixed(0),
                    };
                  } else if (group === "topProductList") {
                    return {
                      id: info._id,
                      title: info.name,
                      thumbnail:
                        info.images &&
                        info.images.length > 0 &&
                        info.images[0].src
                          ? info.images[0].src
                          : ProductDefaultImage,
                      regularPrice: Number(info.regular_price),
                      sellingPrice: Number(info.sale_price),
                      ratingValue: Number(info.average_rating).toFixed(1),
                      ratingCount: Number(info.rating_count).toFixed(0),
                    };
                  } else if (group === "healthConditionList") {
                    return {
                      id: info._id,
                      title: info.name,
                      img:
                        info.image && info.image.length > 0
                          ? info.image
                          : DiseaseDefaultImage,
                    };
                  }
                  return true;
                })
              : "";
          if (group === "productOnSale") {
            setRecommendedProducts(dataArray);
          } else if (group === "topProductList") {
            setTopProducts(dataArray);
          } else if (group === "healthConditionList") {
            setHealthConditions(dataArray);
          }
          return true;
        });
      }
    });
  }

  /**
   *    Get Medicine Page Data
   *    1. Top Category
   *    2. Most Buy Products
   *    3. Brands
   */
  function getPageData2() {
    ContentAPI.getMedicinePageExtraData().then((response) => {
      if (response?.success === true) {
        Object.keys(response.data).map((group) => {
          const dataArray =
            response.data[group].length > 0
              ? response.data[group].map((info: any) => {
                  if (group === "topCategory") {
                    return {
                      id: info.id,
                      title: info.name,
                      img:
                        info.image && info.image.length > 0
                          ? info.image
                          : DiseaseDefaultImage,
                    };
                  } else if (group === "mostBuyProducts") {
                    return {
                      id: info._id,
                      title: info.name,
                      thumbnail:
                        info.images &&
                        info.images.length > 0 &&
                        info.images[0].src
                          ? info.images[0].src
                          : ProductDefaultImage,
                      regularPrice: Number(info.regular_price),
                      sellingPrice: Number(info.sale_price),
                      ratingValue: Number(info.average_rating).toFixed(1),
                      ratingCount: Number(info.rating_count).toFixed(0),
                    };
                  } else if (group === "brands") {
                    return {
                      id: info._id,
                      title: info.name,
                      img:
                        info.image && info.image.length > 0
                          ? info.image
                          : DiseaseDefaultImage,
                    };
                  }
                  return true;
                })
              : "";
          if (group === "topCategory") {
            setTopCategory(dataArray);
          } else if (group === "mostBuyProducts") {
            setMostBuyProducts(dataArray);
          } else if (group === "brands") {
            setBrands(dataArray);
          }
          return true;
        });
        setFiltersData();
      }
    });
  }

  /**
   *    Get symptoms Data for Filter
   */
  function setFiltersData() {
    // Get Symptoms
    SymptomsAPI.getAll()
      .then((response) => {
        //  Check if status is true proceed to create filtered object
        if (response.data.status === true) {
          const symptomNames = response.data.data.map((symptom: any) => {
            return symptom.name;
          });
          // Set Filters Data
          setFilters({
            ...filters,
            symptoms: symptomNames,
            category: topCategory.map((category: any) => category.title),
            brand: brands.map((brand: any) => brand.title),
          });
        }
      })
      .catch((e) => {
        return "Error while fetching symptoms";
      });
  }
  useEffect(() => {
    getPageData();
    getPageData2();
  }, []);

  return (
    <>
      {/* Top Navigtion */}
      <TopNavigation />
      <div className={"mb-3"} style={{ height: "10px" }}>
        {" "}
      </div>

      {/* Carousle */}
      <CarouselSlider imagesList={banners.MedicineTop} />

      {/* Consultation Book Button */}
      <IconArrowButton
        goto="/consult"
        title="One-Click Appointment"
        iconImage={Icon_OneClickAppointment.default}
      />

      <IconArrowButton
        goto="/medicine/orders"
        title="Track Orders"
        iconImage={Icon_BuyMedicinesAndEssentials.default}
      />

      {/* Recommended Products Section */}
      <section className={Styles.recommendedProductsContainer}>
        <div className={"d-flex justify-content-between"}>
          <div>
            <Heading size={SMALL} align={LEFT} title="Recommended" />
          </div>
          <div className={Styles.viewMore}>
            <Link to={"/shop"}>More</Link>
          </div>
        </div>
        <ProductMiniCard productsList={recommendedProducts} />
      </section>

      {/* Shop By Health Conditions */}
      <div className={"mt-5"}></div>
      <section className={Styles.shopByhealthContainer}>
        <div className="d-flex justify-content-between">
          <div>
            <Heading
              size={SMALL}
              align={LEFT}
              title="Shop By Health Conditions"
            />
          </div>
          <div className={Styles.viewMore}>
            <Link to={"/filter"} state={{ filters, activeFilter: "symptoms" }}>
              More
            </Link>
          </div>
        </div>

        <div className={`${Styles.cardsContainer} d-flex mt-2`}>
          {healthConditions &&
            healthConditions.map((brand: any, index) => {
              return (
                <ChipCardView
                  key={index}
                  title={brand.title}
                  imageURI={brand.img}
                />
              );
            })}
        </div>
      </section>

      {/* Our Top Products */}
      <div className={"mt-5"}></div>
      <section className={Styles.topProductsContainer}>
        <div className="d-flex justify-content-between">
          <div>
            <Heading size={SMALL} align={LEFT} title="Top Products" />
          </div>
          <div className={Styles.viewMore}>
            <Link to={"/shop"}>More</Link>
          </div>
        </div>

        <div>
          <ProductCard productsList={topProducts} />
        </div>
      </section>

      {/* Middle Carousle */}
      <div className={"pt-4 pb-3"}>
        <CarouselSlider imagesList={banners.MedicineMid} />
      </div>

      {/* Shop By Brand */}
      <div className={"mt-1"}></div>
      <section className={Styles.shopByBrandContainer}>
        <div className="d-flex justify-content-between">
          <div>
            <Heading size={SMALL} align={LEFT} title="Shop By Brand" />
          </div>
          <div className={Styles.viewMore}>
            <Link to={"/shop"}>More</Link>
          </div>
        </div>

        <div className={`${Styles.cardsContainer} d-flex mt-2`}>
          {brands &&
            brands.map((brand: any, index) => {
              return (
                <ChipCardView
                  key={index}
                  title={brand.title}
                  imageURI={brand.img}
                />
              );
            })}
        </div>
      </section>

      {/* Bottom Carousle */}
      <div className={"pt-4 pb-1"}>
        <CarouselSlider imagesList={banners.MedicineBottom} />
      </div>

      {/* Shop By Category */}
      <div className={"mt-4"}></div>
      <section className={Styles.shopByCategoryContainer}>
        <div className="d-flex justify-content-between">
          <div>
            <Heading size={SMALL} align={LEFT} title="Shop By Category" />
          </div>
          <div className={Styles.viewMore}>
            <Link to={"/shop"}>More</Link>
          </div>
        </div>
        <div className={`${Styles.cardsContainer} d-flex mt-2`}>
          {topCategory &&
            topCategory.map((brand: any, index) => {
              return (
                <ChipCardView
                  key={index}
                  title={brand.title}
                  imageURI={brand.img}
                />
              );
            })}
        </div>
      </section>

      {/* Most Bought Products */}
      <div className={"mt-5"}></div>
      <section className={Styles.mostBoughtProductsContainer}>
        <div className="d-flex justify-content-between">
          <div>
            <Heading size={SMALL} align={LEFT} title="Most Bought Products" />
          </div>
          <div className={Styles.viewMore}>
            <Link to={"/shop"}>More</Link>
          </div>
        </div>
        <div>
          <ProductMiniCard productsList={mostBuyProducts} />
        </div>
      </section>
      <br />
      <br />
      <br />

      {/* Bottom Navigtion */}
      <BottomNavigation navigationSelection={MEDICINE} />
    </>
  );
};

export default Medicine;
