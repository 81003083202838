import React from "react";
import Styles from "./doctorsCard.module.scss";
import { DoctorDefaultImage_Male } from "shared/constants";

function DoctorsCard({
  doctorName = "Dr.Kapeefit",
  doctorImage = DoctorDefaultImage_Male,
  doctorDesignation = "Kapeefit Designated",
  experience = 1,
  patientsTreated = 0,
  reviews = 0,
  rating = 1.1,
}: any) {
  return (
    <div className={`${Styles.cardContainer} hstack p-3 mt-3`}>
      <div className={`${Styles.cardImage}`}>
        <img
          src={doctorImage.length !== 0 ? doctorImage : DoctorDefaultImage_Male}
          alt="Kapeefit Doctor"
          width="100%"
        />
        {rating !== 0 && (
          <div
            className={`${Styles.ratingBadge} p-2 rounded-start-pill shadow`}
          >
            <span style={{ color: "#E85222" }}>&#9733;</span> {rating}
          </div>
        )}
      </div>
      <div className={`${Styles.cardContent} col-7 ms-3`}>
        <span className={Styles.doctorName}>{doctorName}</span>
        <span className={Styles.doctorDesignation}>{doctorDesignation}</span>
        <div
          className={`${Styles.basicDetails} hstack rounded shadow-sm mt-4 text-center p-2`}
        >
          <div className="col-4">
            <div className={`${Styles.valueTitle}`}>Experience</div>
            <div className={`${Styles.valueData}`}>{experience}yrs</div>
          </div>
          <div className="col-4">
            <div className={`${Styles.valueTitle}`}>Patients</div>
            <div className={`${Styles.valueData}`}>{patientsTreated}</div>
          </div>
          <div className="col-4">
            <div className={`${Styles.valueTitle}`}>Reviews</div>
            <div className={`${Styles.valueData}`}>{reviews}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorsCard;
