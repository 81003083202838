import React from "react";
import Styles from "./pathologyTest.module.scss";
import { Link } from "react-router-dom";
import { SMALL } from "shared/buttonSize";
import {
  primaryButtonStyle,
  primaryButtonHoveredStyle,
} from "shared/buttonStyles";
import Button from "shared/button/button";
import { DiseaseDefaultImage } from "shared/constants";

export default function PathologyTest({ labTestsList }) {
  return (
    <div>
      {labTestsList.map((product: any) => {
        return (
          <div key={product.id}>
            <div className={`${Styles.productCardContainer} d-flex mb-3`}>
              <div className="">
                <Link to={`/pathologyTestDetail/${product.id}`}>
                  <div className="d-flex">
                    <div
                      className={`${Styles.productCardImage} d-flex align-items-center`}
                    >
                      <img src={DiseaseDefaultImage} alt="Product Title" />
                    </div>
                    <div className={`${Styles.productCardContent}`}>
                      <div className={`${Styles.productCardTitle}`}>
                        {product.title}
                      </div>
                      <div className={`${Styles.productCardSubTitle}`}>
                        Company/Categroy
                      </div>
                      <div
                        className={`${Styles.productCardPriceContainer} d-flex`}
                      >
                        <div className={`${Styles.productCardSellingPrice}`}>
                          &#8377;{product.sellingPrice}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className={Styles.productCardActions}>
                <div className={Styles.productCardBuyButton}>
                  <Button
                    disabled={false}
                    style={primaryButtonStyle}
                    hoveredStyle={primaryButtonHoveredStyle}
                    size={SMALL}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
