import React from "react";
const NotFoundImage = require("../../assets/images/noDataFound.svg");

export default function NotFound({
  displayText = "Not Found",
  altText = "Not Found",
  imageSrc = NotFoundImage.default,
}) {
  return (
    <div
      className={"align-items-center"}
      style={{ width: "350px", margin: "auto" }}
    >
      <img
        src={imageSrc}
        alt={altText}
        style={{ marginTop: "70%", marginBottom: "20px" }}
      />
      {displayText}
    </div>
  );
}
