import React, { useEffect, useState } from "react";
import Styles from "./walletTransactions.module.scss";
import AppBar from "shared/appBar/appBar";
import { WalletAPI } from "apis/walletAPI";
import Loading from "shared/loading/loading";
import NotFound from "shared/notFound/notFound";
import { PRIMARYORANGE } from "shared/colors";

export default function WalletTransactions() {
  const [transactions, setTransactions] = useState<any>();

  const getTransactions = (patientId: string) => {
    WalletAPI.getTransactions(patientId)
      .then((response) => {
        if (response.success === true) {
          const data = response.data.map((transaction: any) => {
            return {
              id: transaction._id,
              type: transaction.transactionType,
              transaction_by: transaction.mode,
              amount: transaction.points,
            };
          });
          setTransactions(data);
        }
      })
      .catch((err) => {
        return "Error fetching wallet transactions!";
      });
  };

  useEffect(() => {
    getTransactions("");
  }, []);
  return (
    <div>
      <AppBar title="Transactions" />
      {transactions ? (
        transactions.length === 0 ? (
          <NotFound
            displayText={"No Transactions Found"}
            altText={"No transactions found"}
          />
        ) : (
          transactions.map((transaction, index) => {
            return (
              <div
                className={`${Styles.transactionContainer} d-flex`}
                key={index}
              >
                <div className={Styles.lhs}>
                  <div className={Styles.transactionType}>
                    {transaction.type}
                  </div>
                  <div className={Styles.transactionBy}>
                    {transaction.transaction_by}
                  </div>
                </div>
                <div className={Styles.rhs}>
                  <div
                    className={`${Styles.amount} ${
                      transaction.type === "debit" ? Styles.red : ""
                    }`}
                  >
                    &#8377;{transaction.amount}
                  </div>
                  <div className={Styles.date}>20-10-2023</div>
                </div>
              </div>
            );
          })
        )
      ) : (
        <Loading loadingColor={PRIMARYORANGE} />
      )}
    </div>
  );
}
