import React from "react";
import Styles from "./subscriptions.module.scss";
import AppBar from "shared/appBar/appBar";
import { Link } from "react-router-dom";
import Button from "shared/button/button";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { LARGE } from "shared/buttonSize";

export default function Subscriptions() {
  return (
    <>
      <AppBar title="My Subscription" />
      No active subscription yet
      <div className={Styles.mainContainer}>
        <div className={Styles.upgradeButton}>
          <Link to="/subscription/plans">
            <Button
              disabled={false}
              style={primaryButtonStyle}
              hoveredStyle={primaryButtonHoveredStyle}
              size={LARGE}
            >
              Upgrade
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
