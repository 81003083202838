import React, { useEffect, useState } from "react";
import Styles from "./wallet.module.scss";
import AppBar from "shared/appBar/appBar";
import List from "shared/list/list";
import { WalletAPI } from "apis/walletAPI";
import { IntProfileData } from "apis/interface";

export default function Wallet() {
  const [walletPoints, setWalletPoints] = useState<Number>();
  const profileInfo: IntProfileData = localStorage.getItem("profileData")
    ? JSON.parse(localStorage.getItem("profileData")!)
    : {
        walletPoints: 0,
        referralCode: "",
      };

  const listItems = [
    {
      id: 1,
      link: "/wallet/transactions",
      icon: "device_reset",
      title: "Wallet Transactions",
      action: "chevron_right",
    },
    {
      id: 2,
      link: "/wallet/help",
      icon: "question_mark",
      title: "Help & Support",
      action: "chevron_right",
    },
  ];

  const referalCode = profileInfo.referralCode;
  function copyCode() {
    navigator.clipboard
      .writeText(
        referalCode && referalCode.length > 0 ? referalCode : "No referral Code"
      )
      .then(
        () => {
          alert("Text Copied");
        },
        () => {
          alert("Error: Text cannot be copied. Premissions not allowed.");
        }
      )
      .catch(() => {
        alert("Error: referral code not copied");
      });
    // alert("Referral Code Copied");
  }

  useEffect(() => {
    // fetch latest wallet info
    WalletAPI.getWallet()
      .then((response) => {
        if (response.success === true) {
          localStorage.setItem(
            "profileData",
            JSON.stringify({
              ...profileInfo,
              walletPoints: response.data.points,
            })
          );
          setWalletPoints(response.data.points);
        }
      })
      .catch((error) => {
        return "Error fetching Wallet Points!";
      });
  }, []);
  return (
    <>
      <AppBar title="Wallet" />
      <div className={`${Styles.topContainer} d-flex`}>
        <div className={Styles.top_InfoContainer}>
          <div className={Styles.walletPointsLabel}>Wallet Points</div>
          <div className={Styles.points}>{`${walletPoints}`}</div>
          <div className={Styles.referalCode}>
            Referral Code: {`${referalCode}`} &nbsp;&nbsp;
            <span className={Styles.copyShareIcons}>
              <span className="material-symbols-outlined" onClick={copyCode}>
                content_copy
              </span>
            </span>
            &nbsp;
            <span className={Styles.copyShareIcons}>
              <span className="material-symbols-outlined">share</span>
            </span>
          </div>
        </div>
        <div className={Styles.walletLogo}>
          <span className="material-symbols-outlined">
            account_balance_wallet
          </span>
        </div>
      </div>

      <List listItems={listItems}></List>
    </>
  );
}
