import React from "react";
import Styles from "./inputField.module.scss";

export default function InputField({
  disabled = false,
  isValidState = "",
  errorText = "Error! Invalid value entered",
  isIconLabel = false,
  labelTitle = "",
  ...props
}: any) {
  return (
    <div className={`${Styles.inputContainer}`}>
      <label>
        {labelTitle === "" ? (
          ""
        ) : (
          <div className={`${Styles.iconLabel}`}>{labelTitle}</div>
        )}
        <input
          disabled={disabled}
          className={`${Styles.inputField} form-control form-control-lg ${
            isIconLabel ? "ps-5" : "ps-3"
          } ${
            isValidState === "error"
              ? "is-invalid"
              : `${isValidState === "valid" ? "is-valid" : ""}`
          }`}
          {...props}
        />
      </label>
      {isValidState === "error" && (
        <p className={`${Styles.errorText} mt-2`}>{errorText}</p>
      )}
    </div>
  );
}
