import React from "react";
import Styles from "./doctorsProfile.module.scss";
import Rating from "shared/rating/rating";
import { DoctorDefaultImage_Male } from "shared/constants";

export default function DoctorsProfile(props: any) {
  const doctorInfo = props.doctor.doctor;
  return (
    <div>
      <div className={`${Styles.doctorInfo} d-flex`}>
        <div className={Styles.doctorImage}>
          <img
            src={
              doctorInfo.profile_image && doctorInfo.profile_image.length > 0
                ? doctorInfo.profile_image
                : DoctorDefaultImage_Male
            }
            alt="Doctor Profile"
          />
        </div>
        <div className={Styles.doctorDetails}>
          <div className={Styles.name}>{doctorInfo.title}</div>
          <div className={Styles.category}>{doctorInfo.specialization}</div>
          <div>
            <Rating rating={doctorInfo.rating} />
          </div>
          {/* <div className={`${Styles.consultationTypeIcons} shadow d-flex`}>
            <span className="material-symbols-outlined">call</span>
            <span className="material-symbols-outlined">video_call</span>
            <span className="material-symbols-outlined">chat</span>
          </div> */}
        </div>
      </div>
      {/* <div className={Styles.doctorExpRating}>
        <div
          className={`${Styles.basicDetails} hstack rounded shadow-sm mt-4 mb-4 text-center p-3`}
        >
          <div className="col-4">
            <div className={`${Styles.valueTitle}`}>Experience</div>
            <div className={`${Styles.valueData}`}>{doctorInfo.experience}yrs</div>
          </div>
          <div className="col-4">
            <div className={`${Styles.valueTitle}`}>Patients</div>
            <div className={`${Styles.valueData}`}>{doctorInfo.patients}</div>
          </div>
          <div className="col-4">
            <div className={`${Styles.valueTitle}`}>Reviews</div>
            <div className={`${Styles.valueData}`}>{doctorInfo.reviews}</div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
