import React from "react";
import Styles from "./searchInput.module.scss";

export default function SeachInput() {
  return (
    <div className={Styles.searchBar}>
      <input type="search" name="search" id="search" placeholder="Search" />
    </div>
  );
}
