import React, { useEffect, useState } from "react";
import Styles from "./profileAddresses.module.scss";
import AppBar from "shared/appBar/appBar";
import InputField from "shared/inputField/inputField";
import Button from "shared/button/button";
import { LARGE } from "shared/buttonSize";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { STATELIST } from "shared/constants";
import { ToastMessage } from "shared/toast/toastMsg";
import { useParams } from "react-router-dom";
import { ProfileAPI } from "apis/profileAPI";

type AddressInfo = {
  name: string;
  mobile: string;
  flatHouse: string;
  areaStreet?: string;
  landmark?: string;
  pincode: string;
  city: string;
  state: string;
};

export default function EditProfileAddresses() {
  const { id = null } = useParams();
  const [addressDetails, setAddressDetails] = useState<AddressInfo>({
    name: "",
    mobile: "",
    flatHouse: "",
    areaStreet: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
  });

  function getAddressDetails(addressId: string) {
    ProfileAPI.editAddress(addressId)
      .then((response) => {
        if (response.success === true) {
          const addressData = {
            name: response.data.name,
            mobile: response.data.mobile,
            flatHouse: response.data.house_flat_no,
            areaStreet: response.data.area_street,
            landmark: response.data.landmark,
            pincode: response.data.pincode,
            city: response.data.city,
            state: response.data.state,
          };

          setAddressDetails(addressData);
        }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    if (id) {
      getAddressDetails(id);
    }
  }, [id]);
  return (
    <>
      <AppBar title="My Address" />
      <div className={Styles.editAddressContainer}>
        <form action="">
          <InputField placeholder="Full Name" value={addressDetails.name} />
          <br></br>
          <InputField placeholder="Mobile Number" value={addressDetails.mobile} />
          <br></br>
          <InputField placeholder="Flat/House No., Building Name" value={addressDetails.flatHouse} />
          <br></br>
          <InputField placeholder="Area Name, Street, Sector, Colony" value={ addressDetails.areaStreet} />
          <br></br>
          <InputField placeholder="Landmark" value={addressDetails.landmark} />
          <br></br>
          <InputField placeholder="Pincode" value={ addressDetails.pincode} />
          <br></br>
          <InputField placeholder="City" value={addressDetails.city} />
          <br></br>
          <select
            className={`${Styles.dropdown} form-control form-control-lg`}
            name=""
            id=""
            defaultValue={""}
            onChange={(e) => {}}
          >
            <option value="" disabled>
              State
            </option>
            {STATELIST.map((state, index) => (
              <option value={state} key={index}>
                {state}
              </option>
            ))}
          </select>
        </form>

        <div
          id="liveToastBtn"
          className={Styles.buttonContainer}
          onClick={(e) => {
            ToastMessage.show("Updated");
          }}
        >
          <Button
            size={LARGE}
            disabled={false}
            style={primaryButtonStyle}
            hoveredStyle={primaryButtonHoveredStyle}
          >
            Save Address
          </Button>
        </div>
      </div>
    </>
  );
}
