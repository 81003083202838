import React from "react";
import Styles from "./rating.module.scss";

export default function Rating({
  rating = 4.3,
  voteCount = 0,
  foregroundColor = "#008080",
  backgroundColor = "#808080",
}) {
  return (
    <div className={`${Styles.rating} d-flex`}>
      <div className={`${Styles.ratingStars} d-flex`}>
        {Array(5)
          .fill(1)
          .map((val, index) => {
            return (
              <span
                style={{
                  color:
                    rating >= index + 1 ? foregroundColor : backgroundColor,
                }}
                key={index}
              >
                &#9733;
              </span>
            );
          })}
      </div>
      <div className={Styles.ratingValue} style={{ color: foregroundColor }}>
        &nbsp;({rating})
      </div>
      {/* <div className={Styles.voteCount} style={{ color: foregroundColor }}>
        &nbsp;&nbsp;({voteCount})
      </div> */}
    </div>
  );
}
