import React, { useState } from "react";
import Styles from "./inputFieldWithLabel.module.scss";

const InputFieldWithLabel = (props) => {
  const [inputValue, setInputValue] = useState<any>("");
  return (
    <>
      <div className={`${Styles.inputContainer}`}>
        <input
          type={props.typeOfInput ?? "text"}
          className={`${Styles.inputField}`}
          placeholder={props.placeholderText ?? ""}
          min={props.typeOfInput && props.typeOfInput === "number" ? 1 : ""}
          max={props.typeOfInput && props.typeOfInput === "number" ? 999 : ""}
          value={props.value ?? inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            props.getValue(e.target.value);
            // sendValueToParent(e.target.value);
          }}
        />
        <div className={`${Styles.inputLabel}`}>{props.placeholderText}</div>
      </div>
    </>
  );
};

export default InputFieldWithLabel;
