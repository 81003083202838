import { getAccessTokenFromLocal } from "utils/utils";
import {
  appointment_bookSlotDetails,
  appointment_checkoutDetails,
  appointment_getAll,
  appointment_getDetailsByID,
  appointment_reschedule,
  appointment_verifyPaymentDetails,
} from "./apiEndpoints";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";
import {
  apiResponse,
  bookSlotRequestBody,
  rescheduleAppointmentReqBody,
} from "./interface";
const AUTH_TOKEN = getAccessTokenFromLocal() ?? "";
export const AppointmentAPI = {
  getAll: async function (cancel = false) {
    const response = await api.request({
      url: appointment_getAll,
      method: "GET",
      headers: {
        Authorization: AUTH_TOKEN,
      },
      signal: cancel
        ? cancelApiObject[this.getAll.name].handelRequestCancellation().signal
        : undefined,
    });

    // Validate response
    const result = validateResponse(response);
    return result;
  },
  getDetails: async function (appointmentId: any, cancel = false) {
    const response = await api.request({
      url: appointment_getDetailsByID + appointmentId,
      method: "GET",
      headers: {
        Authorization: AUTH_TOKEN,
      },
      signal: cancel
        ? cancelApiObject[this.getDetails.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  getAppointmentCheckout: async function (data: any, cancel = false) {
    const response = await api.request({
      url: appointment_checkoutDetails,
      method: "POST",
      headers: {
        Authorization: AUTH_TOKEN,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[
            this.getAppointmentCheckout.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  bookSlot: async function (data: bookSlotRequestBody, cancel = false) {
    try {
      const response = await api.request({
        url: appointment_bookSlotDetails,
        method: "POST",
        headers: {
          Authorization: AUTH_TOKEN,
        },
        data: data,
        signal: cancel
          ? cancelApiObject[this.bookSlot.name].handelRequestCancellation()
              .signal
          : undefined,
      });
      console.log("API Response: ", response);
      const result = validateResponse(response);
      return result;
    } catch (error) {
      const result = {
        success: false,
        message: "Error with Appointment processing.",
        data: null,
      };
      return result;
    }
  },
  verifyAppointmentPaymentDuringCheckout: async function (
    data: any,
    cancel = false
  ) {
    try {
      const response = await api.request({
        url: appointment_verifyPaymentDetails,
        method: "POST",
        headers: {
          Authorization: AUTH_TOKEN,
        },
        data: data,
        signal: cancel
          ? cancelApiObject[this.bookSlot.name].handelRequestCancellation()
              .signal
          : undefined,
      });
      const result = validateResponse(response);
      return result;
    } catch (error) {
      const result = {
        success: false,
        message: "Error with Appointment processing.",
        data: null,
      };
      return result;
    }
  },
  rescheduleAppointment: async function (
    appointmentId: string,
    data: rescheduleAppointmentReqBody,
    cancel = false
  ) {
    const response = await api.request({
      url: appointment_reschedule + appointmentId,
      method: "PUT",
      headers: {
        Authorization: AUTH_TOKEN,
      },
      data,
      signal: cancel
        ? cancelApiObject[
            this.rescheduleAppointment.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
};

/**
 *    Validate Response from API
 *    If response is success than return success+data else return error
 */
function validateResponse(response: any) {
  let result: apiResponse;
  if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === true
  ) {
    result = {
      success: true,
      message: "Appointment details fetched successfully",
      data: response.data.data,
    };
  } else {
    result = {
      success: false,
      message: "Error with Appointment processing.",
      data: response,
    };
  }
  return result;
}

// defining the cancel API object for WishlistAPI
const cancelApiObject = defineCancelApiObject(AppointmentAPI);
