import React from "react";
import Styles from "./list.module.scss";
import { Link } from "react-router-dom";

export default function List({ listItems }: any) {
  return (
    <div>
      {listItems.map((item) => {
        return (
          <Link to={item.link} key={item.id}>
            <div className={`${Styles.item} d-flex`}>
              <div className={Styles.icon}>
                <span className="material-symbols-outlined">{item.icon}</span>
              </div>
              <div className={Styles.title}>{item.title}</div>
              <div className={Styles.action}>
                <span className="material-symbols-outlined">{item.action}</span>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}
