import { validateResponse } from "utils/apiValidations";
import { membership_getAllPlans } from "./apiEndpoints";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";
import { apiResponse } from "./interface";

let result: apiResponse;

export const MembershipAPI = {
  getMembershipPlans: async function (cancel = false) {
    try {
      const response = await api.request({
        url: membership_getAllPlans,
        method: "GET",
        signal: cancel
          ? cancelApiObject[
              this.getMembershipPlans.name
            ].handelRequestCancellation().signal
          : undefined,
      });
      result = validateResponse(response);
      return result;
    } catch (error) {
      result.message = "Error getting Membership Plans";
      result.data = error;
      return result;
    }
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(MembershipAPI);
