import { validateResponse as ValidateResponse } from "utils/apiValidations";
import {
  banners_getAll,
  blogs_getAll,
  blogs_getTop,
  homePage_getAll,
  medicinePage_getAll,
  medicinePage_getProductsBifurcations,
  woocomerce_getCategories,
} from "./apiEndpoints";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";
import { apiResponse } from "./interface";

export const ContentAPI = {
  getBanners: async function (cancel = false) {
    try {
      const response = await api.request({
        url: banners_getAll,
        method: "GET",
        signal: cancel
          ? cancelApiObject[this.getBanners.name].handelRequestCancellation()
              .signal
          : undefined,
      });
      const result = validateResponse(response);
      if (result.success === true) {
        return result;
      }
    } catch (err) {
      const result = {
        success: false,
        message: "Error fetching Banners",
        data: null,
      };
      return result;
    }
    return;
  },
  getHomePage: async function (cancel = false) {
    try {
      const response = await api.request({
        url: homePage_getAll,
        method: "GET",
        signal: cancel
          ? cancelApiObject[this.getHomePage.name].handelRequestCancellation()
              .signal
          : undefined,
      });
      const result = validateResponse(response);
      if (result.success === true) {
        return result;
      }
    } catch (err) {
      const result = {
        success: false,
        message: "Error fetching HomePage Data",
        data: null,
      };
      return result;
    }
    return;
  },
  getTopBlogs: async function (cancel = false) {
    try {
      const response = await api.request({
        url: blogs_getTop,
        method: "GET",
        signal: cancel
          ? cancelApiObject[this.getTopBlogs.name].handelRequestCancellation()
              .signal
          : undefined,
      });
      const result = validateResponse(response);
      if (result.success === true) {
        return result;
      }
    } catch (err) {
      const result = {
        success: false,
        message: "Error fetching Banners",
        data: null,
      };
      return result;
    }
    return;
  },
  getAllBlogs: async function (cancel = false) {
    try {
      const response = await api.request({
        url: blogs_getAll,
        method: "GET",
        signal: cancel
          ? cancelApiObject[this.getAllBlogs.name].handelRequestCancellation()
              .signal
          : undefined,
      });
      const result = validateResponse(response);
      if (result.success === true) {
        return result;
      }
    } catch (err) {
      const result = {
        success: false,
        message: "Error fetching Banners",
        data: null,
      };
      return result;
    }
    return;
  },
  getShopCategories: async function (cancel = false) {
    const response = await api.request({
      url: woocomerce_getCategories,
      method: "GET",
      signal: cancel
        ? cancelApiObject[
            this.getShopCategories.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  getMedicinePage: async function (cancel = false) {
    const response = await api.request({
      url: medicinePage_getAll,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getMedicinePage.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = ValidateResponse(response);
    return result;
  },
  getMedicinePageExtraData: async function (cancel = false) {
    const response = await api.request({
      url: medicinePage_getProductsBifurcations,
      method: "GET",
      signal: cancel
        ? cancelApiObject[
            this.getMedicinePageExtraData.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = ValidateResponse(response);
    return result;
  },
};

/**
 *    Validate Response from API
 *    If response is success than return success+data else return error
 */
function validateResponse(response: any) {
  let result: apiResponse;
  if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === true
  ) {
    result = {
      success: true,
      message: "Data fetched successfully",
      data: response.data.data,
    };
  } else {
    result = {
      success: false,
      message: "Error fetching data",
      data: response,
    };
  }
  return result;
}

// defining the cancel API object for ContentAPI
const cancelApiObject = defineCancelApiObject(ContentAPI);
