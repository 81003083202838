import React, { useEffect, useState } from "react";
import Styles from "./appointmentBooking.module.scss";
import AppBar from "shared/appBar/appBar";
import Heading from "shared/heading/heading";
import {
  LARGE,
  LEFT,
  UserDefaultImage_Female,
  UserDefaultImage_Male,
} from "shared/constants";
import DoctorsProfile from "shared/doctors/doctorsProfile/doctorsProfile";
import Button from "shared/button/button";
import { primaryButtonStyle } from "shared/buttonStyles";
import { useLocation, useNavigate } from "react-router-dom";
import DoctorDateTimeSlots from "shared/doctorDateTimeSlots/doctorDateTimeSlots";
import Symptoms from "shared/symptoms/symptoms";
import { SymptomsAPI } from "apis/symptomsAPI";
import Loading from "shared/loading/loading";
import { ProfileAPI } from "apis/profileAPI";
import { getAgeFromDOB } from "utils/utils";
import { ToastMessage } from "shared/toast/toastMsg";
import { IntProfileData } from "apis/interface";

export default function AppointmentBooking() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [symptomsData, setSymptomsData] = useState([]);
  const [patientsData, setPatientsData] = useState<any>([]);
  const [checkoutData, setCheckoutData] = useState<any>({});

  function getSymptomsData() {
    SymptomsAPI.getAll()
      .then((response) => {
        //  Check if status is true proceed to create filtered object
        if (response.data.status === true) {
          const symptomNames = response.data.data.map((symptom: any) => {
            return {
              id: symptom._id,
              title: symptom.name,
              image: symptom.image,
            };
          });
          setSymptomsData(symptomNames);
        }
      })
      .catch((e) => {
        return "Error while fetching symptoms";
      });
  }

  function getPatientsList() {
    // Get Current User from LocalStorage
    const loggedInUserData: IntProfileData = JSON.parse(
      localStorage.getItem("profileData")!
    );

    // Get Family Members Data
    ProfileAPI.getFamilyMembers().then((response) => {
      if (response.success === true) {
        const familyMembers = response.data.map((member: any) => {
          return {
            id: member._id,
            title: member.name,
            image:
              member.image && member.image.length > 0
                ? member.image
                : member.gender === "male"
                ? UserDefaultImage_Male
                : UserDefaultImage_Female,
            gender: member.gender,
            age: getAgeFromDOB(member.dob),
          };
        });
        setPatientsData([
          {
            id: loggedInUserData.id,
            title: "(Self) " + loggedInUserData.name,
            image: loggedInUserData.image ?? UserDefaultImage_Male,
            gender: loggedInUserData.gender,
            age: getAgeFromDOB(loggedInUserData.dob),
          },
          ...familyMembers,
        ]);
      }
    });
  }

  /**
   * Add Patient To Checkout State
   * @param patientId - recieves from Symptoms/Circular Image Bottom Text view
   * @returns nothing
   */
  function addPatientToCheckout(patientId: string) {
    if (checkoutData.patient && checkoutData.patient.id === patientId) {
      setCheckoutData({ ...checkoutData, patient: undefined });
      return;
    }
    const patient = patientsData.filter(
      (patientInfo) => patientInfo.id === patientId
    );
    setCheckoutData({ ...checkoutData, patient: patient[0] });
  }

  function updateSymptomToCheckout(symptomId: string) {
    let symptomsList: any = checkoutData.symptoms ?? [];
    let selectedSymptom: any = symptomsData.find((symptom: any) => {
      if (symptom.id === symptomId) return symptom;
    });
    // check if Symptom Name exists in Checkout Data
    if (symptomsList.indexOf(selectedSymptom.title) === -1) {
      symptomsList.push(selectedSymptom.title);
    } else {
      symptomsList.splice(symptomsList.indexOf(selectedSymptom.title), 1);
    }

    setCheckoutData({ ...checkoutData, symptoms: symptomsList });
    return;
  }

  const addSlotSelectionToCheckoutObj = (data: any) => {
    const doctorData = checkoutData.doctor;
    const patientData = checkoutData.patient;
    const symptomsData = checkoutData.symptoms;
    setCheckoutData({
      ...data,
      doctor: doctorData,
      patient: patientData,
      symptoms: symptomsData,
    });
  };

  const proceedToCheckout = () => {
    // Check if all data required is available in CheckoutData
    if (!checkoutData.slot || !checkoutData.time) {
      ToastMessage.show("Please select Date/Time slot!");
      return false;
    } else if (!checkoutData.patient) {
      ToastMessage.show("Please select user/Patient");
      return false;
    } else if (!checkoutData.doctor) {
      ToastMessage.show("Error selecting Doctor. Go back and try again.");
      return false;
    }
    navigate("/checkout", { state: checkoutData });
    return true;
  };

  useEffect(() => {
    getSymptomsData();
    getPatientsList();
    setCheckoutData({ doctor: state.doctor });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <AppBar title="Consultation Booking" />

      {/* Doctor's Profile */}
      <DoctorsProfile doctor={state} />

      {/* Availability / Booking Slots */}
      <div className={"mt-3"}>
        <Heading size={LARGE} align={LEFT} title={"Availability"} />
        <DoctorDateTimeSlots
          doctorID={state.doctor.id}
          sendDataToParent={addSlotSelectionToCheckoutObj}
        />
      </div>

      {/* Symptoms / Diseases */}
      <div className={"mt-3"}>
        <Heading size={LARGE} align={LEFT} title={"Symptoms/Diseases"} />
        <div className={`${Styles.symptomsContainer} mt-3`}>
          {symptomsData.length > 0 ? (
            <Symptoms
              symptoms={symptomsData}
              multiSelection={true}
              style={`d-flex flex-nowrap`}
              sendItemIdToParent={updateSymptomToCheckout}
            />
          ) : (
            <Loading loadingColor={"#E85222"} />
          )}
        </div>
      </div>

      {/* Patients Selection */}
      <div className={"mt-3"}>
        <Heading size={LARGE} align={LEFT} title={"Choose your booking for"} />
        <Symptoms
          symptoms={patientsData}
          style={`d-flex flex-nowrap`}
          sendItemIdToParent={addPatientToCheckout}
        />
      </div>

      <div className={Styles.bookAppointmentButton} onClick={proceedToCheckout}>
        <Button
          disabled={false}
          style={primaryButtonStyle}
          hoveredStyle={primaryButtonStyle}
          size={LARGE}
        >
          Book Appointment
        </Button>
      </div>
    </div>
  );
}
