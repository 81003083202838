import React, { useEffect, useRef, useState } from "react";
import AppBar from "shared/appBar/appBar";
import Styles from "./ehr.module.scss";
import SeachInput from "shared/searchInput/searchInput";
import { BottomNavigation } from "shared/bottomNavigation/bottomNavigation";
import { EHR, LARGE } from "shared/constants";
import BottomSheet from "shared/bottomSheet/bottomSheet";
import { EhrAPI } from "apis/ehrAPI";
import EhrFileCard from "shared/ehrFileCard/ehrFileCard";
import Loading from "shared/loading/loading";
import Symptoms from "shared/symptoms/symptoms";
import { Link, useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { getProfileDataFromLocal } from "utils/utils";
import InputFieldWithLabel from "shared/inputFieldWithLabel/inputFieldWithLabel";
import Button from "shared/button/button";
import { primaryButtonStyle } from "shared/buttonStyles";
import NameTag from "shared/nameTag/nameTag";
import { ToastMessage } from "shared/toast/toastMsg";

export default function Ehr() {
  const navigate = useNavigate();
  const [tabState, setTabState] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [ehrData, setEhrData] = useState<any>({
    patientEHR: [],
    familyEHR: {},
  });
  const [ehrDataProp, setEhrDataProp] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showReportFiles, setShowReportFiles] = useState(false);
  const [reportFiles, setReportFiles] = useState([]);
  const [ehrOptionsToggle, setEhrOptionsToggle] = useState(0);
  const [selectedMemberID, setSelectedMemberID] = useState({
    id: getProfileDataFromLocal().id,
    name: getProfileDataFromLocal().name,
  });
  const [tagsList, setTagsList] = useState<any>([]);
  const [tagInput, setTagInput] = useState([]);
  const hiddenFileInput = useRef<any>(null);
  const [uploadFileName, setUploadFileName] = useState("Upload File(s)");
  const [uploadFiles, setUploadFiles] = useState<any>({});

  function onClose() {
    setIsOpen(false);
  }

  function onOpen() {
    // empty the files selected
    setUploadFiles({});
    setTagInput([]);
    setTagInput([]);
    setUploadFileName("Upload File(s)");
    setIsOpen(true);
  }

  function getEhrData() {
    setIsLoading(true);
    EhrAPI.getCompleteEhr()
      .then((response) => {
        if (response.success === true) {
          // Process Patient's Reports
          let patientFiles: any = [];
          response.data.patientEHR.forEach((ehr) => {
            patientFiles.push(
              ehr.report.map((reportFile) => {
                return {
                  fileName: reportFile.originalName,
                  fileLink: reportFile.report,
                };
              })
            );
          });
          // Process Family Member's Rreports
          let familyEhrHM = new Map();
          response.data.familyEHR.forEach((ehr: any) => {
            const patientId = ehr.patientId._id;
            let familyMemberEhr: any = [];
            if (familyEhrHM.has(patientId)) {
              familyMemberEhr = familyEhrHM.get(patientId);
            }
            familyMemberEhr = [
              ...familyMemberEhr,
              ehr.report.map((reportObj) => {
                return {
                  fileName: reportObj.originalName,
                  fileLink: reportObj.report,
                };
              }),
            ];

            familyEhrHM.set(patientId, familyMemberEhr);
          });
          setEhrData({ patientEHR: patientFiles, familyEHR: familyEhrHM });
          setEhrDataProp(patientFiles);
          setIsLoading(false);

          // Set Family Members Info to show on Family Reports Section
          let familyMembersInfo: any = [];
          response.data.familyEHR.forEach((report: any) => {
            if (
              !familyMembersInfo.some(
                (item: any) => item.id === report.patientId._id
              )
            ) {
              familyMembersInfo.push({
                id: report.patientId._id,
                image: report.patientId.image,
                title: report.patientId.name,
              });
            } else {
              return;
            }
          });
          setFamilyMembers(familyMembersInfo);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function selectTab(tabId) {
    setTabState(tabId);
    //  For Family Report tabId == 1
    if (tabId === 1) {
      // set Inital Report display to empty
      setEhrDataProp([]);
    } else {
      setEhrDataProp(ehrData.patientEHR);
      // Set Selected MemberID for Vitals and Upload EHR ref
      setSelectedMemberID({
        id: getProfileDataFromLocal().id,
        name: getProfileDataFromLocal().name,
      });
    }
  }

  function updateReportsForFamilyMemberSelected(
    memberId: string,
    memberName: string
  ) {
    const familyMemberEhr = ehrData.familyEHR.get(memberId);
    setEhrDataProp(familyMemberEhr);
    // Set Selected MemberID for Vitals and Upload EHR ref
    setSelectedMemberID({ id: memberId, name: memberName });
  }

  // Show Report Files View
  function showReportFilesOnMemberSelect(reports: any) {
    setShowReportFiles(true);
    const reportData = reports.map((report, index) => {
      return {
        id: index,
        title: report.fileName,
        image: report.fileLink,
      };
    });
    setReportFiles(reportData);
  }

  // Handle Cross button on name tag
  function handleNameTagRemove(tagTitle: string) {
    console.log(tagTitle);
    setTagsList(
      tagsList.filter((tag) => {
        return tag !== tagTitle;
      })
    );
  }

  // Get Value of Tag Name from Input field
  function handleTagInput(inputValue) {
    setTagInput(inputValue);
  }

  // Handle file upload button click behavior
  const handleProfileImageUpload = (event: any) => {
    if (event.target.files.length > 1)
      setUploadFileName("Multiple Files selected");
    else if (event.target.files.length === 1)
      setUploadFileName(event.target.files[0].name);
    setUploadFiles(event.target.files);
    console.log("Selected file: ", event.target.files);
  };

  function handleUploadEhrReport() {
    const formData = new FormData();
    uploadFiles.forEach((file) => {
      formData.append("report[]", file);
    });
    formData.append(
      "reportNameTag",
      tagsList && tagsList.length === 0 ? "report" : tagsList
    );
    formData.append(
      "tag",
      tagsList && tagsList.length === 0 ? "report" : tagsList
    );
    formData.append("relationId", selectedMemberID.id);
    EhrAPI.uploadEhrReport(formData)
      .then((response) => {
        if (response.success === true) {
          ToastMessage.show("Reports Uploaded Successfully!");
          console.log("EHR Reponse: ", response);
          // Close Bottom Sheet
          onClose();
        } else {
          ToastMessage.show("Error. File size too large!");
        }
      })
      .catch((error) => {
        ToastMessage.show("Error uploading Reports. Please try again later.");
      });
  }

  // HTML For Bottom Sheet to Upload EHR
  const htmlForBottomSheet = (
    <div className={`${Styles.bottomSheetContent} p-3 pt-4`}>
      <div>
        Uploading EHR for:{" "}
        <span className={`${Styles.selectedMemberBottomTitle}`}>
          {selectedMemberID.name}
        </span>
      </div>
      <div
        className={`${Styles.uploadFileIcon} mt-3 ms-3 d-flex align-items-center`}
        onClick={() => hiddenFileInput.current.click()}
      >
        <span className="material-symbols-outlined">drive_folder_upload</span>
        &nbsp;&nbsp;{uploadFileName}
      </div>
      <input
        type="file"
        onChange={(e) => handleProfileImageUpload(e)}
        ref={hiddenFileInput}
        style={{ display: "none" }}
        multiple={true}
        accept={"application/pdf, image/png, image/jpeg, image/jpg"}
      />
      <div className={`${Styles.uploadFileSubTitle} ms-3 mt-2`}>
        Allowed: (jpg, png, pdf) file only
      </div>
      <div className={`${Styles.tagsContainer} mt-5 d-flex`}>
        {tagsList.map((tag, index) => {
          return (
            <NameTag
              key={index}
              title={tag}
              sendBackText={handleNameTagRemove}
            />
          );
        })}

        {/* <NameTag title={"def"} sendBackText={handleNameTagRemove} /> */}
      </div>
      <div className={`${Styles.bottomSheetBottomContainer} mb-3`}>
        <InputFieldWithLabel
          placeholderText={"Enter Tag Name"}
          getValue={handleTagInput}
          value={tagInput}
        />
        <div
          className={`${Styles.addButtonBottomSheet}`}
          onClick={() => {
            setTagsList([...tagsList, tagInput]);
            setTagInput([]);
          }}
        >
          Add
        </div>
        <br></br>
        <div onClick={() => handleUploadEhrReport()}>
          <Button style={primaryButtonStyle} size={LARGE}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
  useEffect(() => {
    selectTab(0);
    // Fetch Data on page Load only.
    getEhrData();
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      {showReportFiles === true ? (
        <>
          <div
            className={`${Styles.hideReportActionBackButton}`}
            onClick={() => setShowReportFiles(false)}
          >
            <span className="material-symbols-outlined">arrow_back</span>
          </div>
          <AppBar title={"Reports"} />
          {reportFiles &&
            reportFiles.map((files: any, index) => {
              return (
                <div className={`${Styles.reportFile} d-flex`} key={index}>
                  <div className={`${Styles.reportFileImage}`}>
                    <img src={files.image} alt="Report File" />
                  </div>
                  <div className={`${Styles.reportFileText}`}>
                    {files.title}
                    <div className={`${Styles.actionButtonsContainer}`}>
                      <Link to={files.image} target="_blank" download={true}>
                        <span
                          className={`${Styles.reportActionButtons} material-symbols-outlined`}
                        >
                          download
                        </span>
                      </Link>
                      <RWebShare
                        data={{
                          text: `${files.title}`,
                          url: `${files.image}`,
                          title: "Share EHR Report",
                        }}
                      >
                        <span
                          className={`${Styles.reportActionButtons} material-symbols-outlined`}
                        >
                          share
                        </span>
                      </RWebShare>
                    </div>
                  </div>
                </div>
              );
            })}
        </>
      ) : (
        <>
          {/* <TopNavigation /> */}
          <AppBar title={"EHR"} />

          {/* Tabs selection */}
          <div className={`${Styles.tabs} d-flex`}>
            <div
              className={`${Styles.tabItem} ${
                tabState === 0 ? Styles.active : ""
              }`}
              onClick={() => selectTab(0)}
            >
              My Report
            </div>
            <div
              className={`${Styles.tabItem} ${
                tabState === 1 ? Styles.active : ""
              }`}
              onClick={() => selectTab(1)}
            >
              Family Reports
            </div>
          </div>
          <div className="mt-3"></div>

          {/* Search Bar */}
          <SeachInput />

          {/* Display Profiles if Family Reports is Selcted in Symptoms Style UI */}
          {familyMembers && tabState === 1 && (
            <div className={`${Styles.symptomsContainer} mt-3`}>
              <Symptoms
                symptoms={familyMembers}
                multiSelection={false}
                style={`d-flex flex-nowrap`}
                sendItemIdToParent={updateReportsForFamilyMemberSelected}
              />
            </div>
          )}

          {/* Display EHR Files */}
          <section className={`${Styles.filesContainer} mt-3`}>
            {isLoading ? (
              <Loading />
            ) : (
              <EhrFileCard
                ehrData={ehrDataProp}
                sendReportsArrayToParent={showReportFilesOnMemberSelect}
              />
            )}
          </section>
          {/* Open EHR Options */}
          <button
            className={Styles.floatingButton}
            onClick={(e) => setEhrOptionsToggle(ehrOptionsToggle === 0 ? 1 : 0)}
          >
            <span className="material-symbols-outlined">reorder</span>
          </button>
        </>
      )}
      {/* Bottom Navigtion */}
      <BottomNavigation navigationSelection={EHR} />
      {/* EHR Options */}
      <div
        className={`${Styles.ehrOptions} ${
          ehrOptionsToggle === 1 ? Styles.ehrActive : ""
        }`}
      >
        <div className={`${Styles.ehrOptionsContainer}`}>
          <div
            className={`${Styles.ehrOption} d-flex align-items-center justify-content-end`}
            onClick={() => {
              // close the EHR Options Overlay
              setEhrOptionsToggle(0);

              // Open Bottom Sheet
              onOpen();
            }}
          >
            <div className={`${Styles.ehrOptionLable}`}>Upload EHR</div>
            <div className={`${Styles.ehrOptionButton}`}>
              <span className="material-symbols-outlined">cloud_upload</span>
            </div>
          </div>
          <div
            className={`${Styles.ehrOption} d-flex align-items-center justify-content-end`}
            onClick={() => {
              // close the EHR Options Overlay
              setEhrOptionsToggle(0);

              // Navigte to vitals page
              navigate("/ehr/vitals/", { state: selectedMemberID });
            }}
          >
            <div className={`${Styles.ehrOptionLable}`}>Vitals</div>
            <div className={`${Styles.ehrOptionButton}`}>
              <span className="material-symbols-outlined">edit_note</span>
            </div>
          </div>
        </div>
        <div
          className={`${Styles.ehrOptionsCloseButton} d-flex align-items-center`}
          onClick={(e) => setEhrOptionsToggle(ehrOptionsToggle === 0 ? 1 : 0)}
        >
          <span className="material-symbols-outlined">close</span>
        </div>
      </div>

      <BottomSheet
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        content={htmlForBottomSheet}
      />
    </div>
  );
}
