import React from "react";
import Styles from "./nameTag.module.scss";

const NameTag = (props) => {
  return (
    <div className={`${Styles.tag} d-flex align-items-center`}>
      <div className={`${Styles.tagTitle}`}>{props.title}</div>
      <div
        className={`${Styles.tagRemove}`}
        onClick={() => props.sendBackText(props.title)}
      >
        <span className="material-symbols-outlined">close</span>
      </div>
    </div>
  );
};

export default NameTag;
