export const ERROR = "error";
export const VALID = "valid";
export const ACTIVE = "active";
export const REG_DIGIT = new RegExp(/^\d+$/);
export const SMALL = "small";
export const NORMAL = "normal";
export const LARGE = "large";
export const LEFT = "left";
export const RIGHT = "right";
export const MIDDLE = "center";
export const HOME = "home";
export const EHR = "ehr";
export const CONSULT = "consult";
export const PROFILE = "profile";
export const CART = "cart";
export const MEDICINE = "medicine";
export const STATELIST = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujrat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu",
  "Jharkhand",
  "Krnataka",
  "Kashmir",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttrakhand",
  "Uttar Pradesh",
  "West Bengal",
];

export const relationsList = {
  mother: "Mother",
  father: "Father",
  sister: "Sister",
  brother: "Brother",
  aunt: "Aunt",
  uncle: "Uncle",
  cousin: "Cousin",
  "grand-mother": "Grand Mother",
  "grand-father": "Grand Father",
  nephew: "Nephew",
  daughter: "Daughter",
  son: "Son",
  "grand-daughter": "Grand Daughter",
  "grand-son": "Grand Son",
  wife: "Wife",
  husband: "Husband",
  "mother-in-law": "Mother In Law",
  "father-in-law": "Father In Law",
  "sister-in-law": "Sister In Law",
  "brother-in-law": "Brother In Law",
  "daughter-in-law": "Daughter In Law",
  "son-in-law": "Son In Law",
};

/**
 *    Image Constants
 */
export const UserDefaultImage_Male = require("../assets/images/defaultUser-male.png");
export const UserDefaultImage_Female = require("../assets/images/defaultUser-female.png");
export const DoctorDefaultImage_Male = require("../assets/images/defaultDoc-male.png");
export const DoctorDefaultImage_Female = require("../assets/images/defaultDoc-female.png");
export const Icon_OneClickAppointment = require("../assets/icons/symptoms/Icon_OneClickAppointment.svg");
export const Icon_BuyMedicinesAndEssentials = require("../assets/icons/symptoms/Icon_BuyMedicineAndEssentials.svg");
export const DiseaseDefaultImage = require("../assets/images/disease_default.png");
export const ProductDefaultImage = require("../assets/images/default_product.png");
