import { validateResponse } from "utils/apiValidations";
import { auth_getOtp, auth_singupSignIn } from "./apiEndpoints";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";
import { generateOtpRequest, singupSignInRequest } from "./interface";

export const AuthAPI = {
  getOTP: async function (requestBody: generateOtpRequest, cancel = false) {
    const response = await api.request({
      url: auth_getOtp,
      method: "POST",
      data: requestBody,
      signal: cancel
        ? cancelApiObject[this.getOTP.name].handelRequestCancellation().signal
        : undefined,
    });

    // Validate response
    const result = validateResponse(response);
    return result;
  },
  // Signup & SingIn
  singupSignIn: async function (
    requestBody: singupSignInRequest,
    cancel = false
  ) {
    const response = await api.request({
      url: auth_singupSignIn,
      method: "POST",
      data: requestBody,
      signal: cancel
        ? cancelApiObject[this.singupSignIn.name].handelRequestCancellation()
            .signal
        : undefined,
    });

    // Validate response
    const result = validateResponse(response);
    return result;
  },
  //   getDetails: async function (appointmentId: any, cancel = false) {
  //     const response = await api.request({
  //       url: appointment_getDetailsByID + appointmentId,
  //       method: "GET",
  //       signal: cancel
  //         ? cancelApiObject[this.getDetails.name].handelRequestCancellation()
  //             .signal
  //         : undefined,
  //     });
  //     const result = validateResponse(response);
  //     return result;
  //   },
  //   getAppointmentCheckout: async function (data: any, cancel = false) {
  //     const response = await api.request({
  //       url: appointment_checkoutDetails,
  //       method: "POST",
  //       headers: {
  //         Authorization: process.env.REACT_APP_AUTH_TOKEN,
  //       },
  //       data: data,
  //       signal: cancel
  //         ? cancelApiObject[
  //             this.getAppointmentCheckout.name
  //           ].handelRequestCancellation().signal
  //         : undefined,
  //     });
  //     const result = validateResponse(response);
  //     return result;
  //   },
  //   bookSlot: async function (data: bookSlotRequestBody, cancel = false) {
  //     try {
  //       const response = await api.request({
  //         url: appointment_bookSlotDetails,
  //         method: "POST",
  //         headers: {
  //           Authorization: process.env.REACT_APP_AUTH_TOKEN,
  //         },
  //         data: data,
  //         signal: cancel
  //           ? cancelApiObject[this.bookSlot.name].handelRequestCancellation()
  //               .signal
  //           : undefined,
  //       });
  //       const result = validateResponse(response);
  //       return result;
  //     } catch (error) {
  //       const result = {
  //         success: false,
  //         message: "Error with Appointment processing.",
  //         data: null,
  //       };
  //       return result;
  //     }
  //   },
  //   verifyAppointmentPaymentDuringCheckout: async function (
  //     data: any,
  //     cancel = false
  //   ) {
  //     try {
  //       const response = await api.request({
  //         url: appointment_verifyPaymentDetails,
  //         method: "POST",
  //         headers: {
  //           Authorization: process.env.REACT_APP_AUTH_TOKEN,
  //         },
  //         data: data,
  //         signal: cancel
  //           ? cancelApiObject[this.bookSlot.name].handelRequestCancellation()
  //               .signal
  //           : undefined,
  //       });
  //       const result = validateResponse(response);
  //       return result;
  //     } catch (error) {
  //       const result = {
  //         success: false,
  //         message: "Error with Appointment processing.",
  //         data: null,
  //       };
  //       return result;
  //     }
  //   },
};

// defining the cancel API object
const cancelApiObject = defineCancelApiObject(AuthAPI);
