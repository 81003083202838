import React from "react";
import Styles from "./ehrFileCard.module.scss";

const EhrFileCard = ({ ehrData, sendReportsArrayToParent }) => {
  return (
    <div
      className={`${Styles.ehrFilesContainer} d-flex justify-content-between flex-wrap`}
    >
      {ehrData.map((ehrDocument, index) => {
        return (
          <div
            className={Styles.ehrFile}
            key={index}
            onClick={() => sendReportsArrayToParent(ehrDocument)}
          >
            <div className={Styles.ehrFileIcon}>
              <span
                className={`${Styles.ehrFileMaterialIcon} material-symbols-outlined`}
              >
                file_copy
              </span>
            </div>
            <div className={Styles.ehrFileTitle}>{ehrDocument[0].fileName}</div>
            <div
              className={Styles.ehrFileQty}
            >{`${ehrDocument.length} File(s)`}</div>
          </div>
        );
      })}
    </div>
  );
};

export default EhrFileCard;
