import { getAccessTokenFromLocal } from "utils/utils";
import { wallet_getTransactions, wallet_getWallet } from "./apiEndpoints";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";
import { apiResponse } from "./interface";

export const WalletAPI = {
  getWallet: async function (cancel = false) {
    const response = await api.request({
      url: wallet_getWallet,
      method: "GET",
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.getWallet.name].handelRequestCancellation()
            .signal
        : undefined,
    });

    // Validate response
    const result = validateResponse(response);
    return result;
  },
  getTransactions: async function (patientId: any, cancel = false) {
    const response = await api.request({
      url: wallet_getTransactions,
      method: "GET",
      data: {
        patientId: patientId,
      },
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.getTransactions.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
};

/**
 *    Validate Response from API
 *    If response is success than return success+data else return error
 */
function validateResponse(response: any) {
  let result: apiResponse;
  if (response.status === 200 && response.data.status === true) {
    result = {
      success: true,
      message: "All wishlist items fetched successfully",
      data: response.data.data,
    };
  } else {
    result = {
      success: false,
      message: "Error fetching wishlist",
      data: response,
    };
  }
  return result;
}

// defining the cancel API object for WishlistAPI
const cancelApiObject = defineCancelApiObject(WalletAPI);
