import { WishlistAPI } from "apis/wishListAPI";
import React, { useEffect, useState } from "react";
import AppBar from "shared/appBar/appBar";
import { PRIMARYGREEN } from "shared/colors";
import Loading from "shared/loading/loading";
import NotFound from "shared/notFound/notFound";
import ProductCard from "shared/product/productCard";

export default function Wishlist() {
  const [productsList, setProductsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function getWishlistProducts() {
    setIsLoading(true);
    WishlistAPI.getAll()
      .then((response) => {
        if (response.data && response.success === true) {
          if (response.data.length > 0) {
            let products = response.data.map((product: any) => {
              return {
                id: product.productId._id,
                title: product.productId.name,
                sellingPrice: product.productId.price,
                regularPrice: product.productId.regular_price,
                ratingValue: product.productId.rating_count,
                thumbnail:
                  product.productId.images &&
                  product.productId.images.length > 0
                    ? product.productId.images[0].src
                    : null,
              };
            });
            setProductsList(products);
          }
        }
      })
      .catch((e) => {
        return "Wishlist Items could not be fetched";
      }).finally(() => {
        setIsLoading(false);  
      });
  }

  useEffect(() => {
    getWishlistProducts();

    // const carousel = new bootstrap.Carousel("#myCarousel");
  }, []);
  return (
    <div>
      <AppBar title="Wishlist" />
      {isLoading ? (
        <Loading loadingColor={PRIMARYGREEN} />
      ) : productsList.length === 0 ? (
        <NotFound displayText={"No Products in Wishlist"}/>
      ) : (
        <ProductCard productsList={productsList} />
      )}
    </div>
  );
}
