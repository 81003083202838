import React, { useEffect } from "react";
import Styles from "./appBar.module.scss";
import { useNavigate, useLocation } from "react-router-dom";

export default function AppBar({ title = "", action = <></> }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  function goBack() {
    navigate(-1);
  }
  useEffect(() => {}, [pathname]);
  return (
    <div className={`${Styles.appBar} d-flex align-items-centre`}>
      <div className={Styles.backButton} onClick={goBack}>
        <span className="material-symbols-outlined">arrow_back</span>
      </div>
      <div className={Styles.title}>{title}</div>
      <div className={Styles.actionButtons}>{action}</div>
    </div>
  );
}
