import React, { useState } from "react";
import Styles from "./pathalogyTestDetails.module.scss";
import AppBar from "shared/appBar/appBar";
import Button from "shared/button/button";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { LARGE, SMALL } from "shared/buttonSize";
import BottomSheet from "shared/bottomSheet/bottomSheet";

export default function PathologyTestDetailsScreen() {
  const [isOpen, setIsOpen] = useState(false);
  const htmlContentForBottomSheet = (
    <div className={Styles.bottomSheetContent}>
      <div className={Styles.heading}>Choose your booking for</div>
      <div className={Styles.patientsList}>
        <ul>
          <li>Patient 1</li>
          <li>Patient 2</li>
        </ul>
      </div>

      <div className={Styles.note}>
        Note: You can remove selected member from cart
      </div>

      <Button
        disabled={false}
        style={primaryButtonStyle}
        hoveredStyle={primaryButtonHoveredStyle}
        size={LARGE}
      >
        Confirm
      </Button>
    </div>
  );
  function onClose() {
    setIsOpen(false);
  }

  function onOpen() {
    setIsOpen(true);
  }
  function selectPatient() {
    console.log("Foo");
    setIsOpen(true);
  }

  return (
    <div>
      <AppBar title="Pathology Lab Test" />
      <div className={`${Styles.mainDetails} ${Styles.card} d-flex`}>
        <div className={Styles.lhs}>
          <div className={Styles.testTitle}>Test Name/Title</div>
          <div className={Styles.subTitle}>XYZ Laboratory/Provider</div>
          <div className={Styles.warningLabel}>
            <span className="material-symbols-outlined">warning</span>
            {" Warning/Pre-requisite goes here"}
          </div>
        </div>
      </div>
      <div className={`${Styles.card} ${Styles.contentContainer}`}>
        <div className={Styles.heading}>About</div>
        <div className={Styles.content}>
          ajkhsdkl fkja sbdkjfbak jsdb fkjabhsd kf Get Well Soon.
        </div>
      </div>

      <div className={`${Styles.bottomContent} d-flex flex-fill`}>
        <div className={Styles.testPrice}>&#8377;400</div>
        <div className={Styles.addTocartButton} onClick={selectPatient}>
          <Button
            disabled={false}
            style={primaryButtonStyle}
            hoveredStyle={primaryButtonHoveredStyle}
            size={LARGE}
          >
            Add to cart
          </Button>
        </div>
      </div>

      <BottomSheet
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        size={SMALL}
        content={htmlContentForBottomSheet}
      />
    </div>
  );
}
