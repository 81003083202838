import { baseURL } from "apis/apiEndpoints";
import axios from "axios";

export const api = axios.create({
  withCredentials: false,
  baseURL: baseURL,
});

// Error Handler
const errorHandler = (error) => {
  const statusCode = error.response?.status;

  // Log all errors except 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

// Registering the above Error handler to the API axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
