import React from "react";
import Styles from "./doctorsCard2.module.scss";
import Button from "shared/button/button";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { SMALL } from "shared/buttonSize";
import { Link } from "react-router-dom";
import Rating from "shared/rating/rating";
import { DoctorDefaultImage_Male } from "shared/constants";

export default function DoctorsCard2({ doctorsData }) {
  return (
    <>
      {doctorsData.map((doctor) => {
        return (
          <div key={doctor.id}>
            <div className={`${Styles.cardView} my-3 p-2 d-flex`}>
              <div className={Styles.cardImage}>
                <img
                  src={doctor.profile_image ?? DoctorDefaultImage_Male}
                  alt="Kapeefit Doctor"
                  width="100%"
                  height="100%"
                />
              </div>
              <div className={Styles.cardInfo}>
                <div className={Styles.infoTitle}>
                  {doctor.title} &nbsp;
                  <span
                    className={`${Styles.materialIconFilled} material-symbols-outlined`}
                  >
                    verified
                  </span>
                </div>
                <div className={Styles.infoSubTitle}>
                  {doctor.specialization} <br />{" "}
                </div>
                <div className={Styles.infoExtra}>
                  <span>Exp.: 5 years</span>
                  <Rating rating={doctor.rating} />
                </div>
              </div>
              <div className={Styles.actionInfo}>
                <div className={Styles.fees}>
                  Fee: <span className={Styles.bold}>&#8377;{doctor.fees}</span>
                </div>
                <div className={Styles.bookNowButton}>
                  <Link to={`/consult/booking`} state={{ doctor }}>
                    <Button
                      disabled={false}
                      style={primaryButtonStyle}
                      hoveredStyle={primaryButtonHoveredStyle}
                      size={SMALL}
                    >
                      Book Now
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
