import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useRef } from "react";
import Styles from "./bottomSheet.module.scss";
import { LARGE, SMALL } from "shared/constants";

export default function BottomSheet({
  isOpen,
  onOpen,
  onClose,
  size = SMALL,
  content = <></>,
}) {
  const prevIsOpen = usePrevious(isOpen);
  const controls = useAnimation();

  function onDragEnd(event, info) {
    const shouldClose =
      info.velocity.y > 80 || (info.velocity.y >= 0 && info.point.y > 45);
    if (shouldClose) {
      controls.start("hidden");
      onClose();
    } else {
      controls.start("visible");
      onOpen();
    }
  }

  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      controls.start("hidden");
    } else if (!prevIsOpen && isOpen) {
      controls.start("visible");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, isOpen]);

  return (
    <div
      className={Styles.bottomSheetContainer}
      style={isOpen ? { visibility: "visible" } : { visibility: "hidden" }}
    >
      <motion.div
        drag="y"
        onDragEnd={onDragEnd}
        initial="hidden"
        animate={controls}
        transition={{ type: "spring", damping: 40, stiffness: 300 }}
        variants={{ visible: { y: 0 }, hidden: { y: "100%" } }}
        dragConstraints={{ top: 0 }}
        dragElastic={0.2}
        className={Styles.sheet}
        style={{
          display: isOpen ? "inline-block" : "none",
          backgroundColor: "white",
          width: "100vw",
          height: size === SMALL ? "416px" : size === LARGE ? "916px" : "616px",
          borderTopRightRadius: 32,
          borderTopLeftRadius: 32,
          boxShadow: "3px -1px 10px #00000055",
          position: "absolute",
          bottom: -50,
          left: 0,
        }}
      >
        <div
          className={Styles.closeButton}
          onClick={() => {
            controls.start("hidden");
            onClose();
          }}
        >
          <span className="material-symbols-outlined">close</span>
        </div>
        <div className={Styles.sheetContent} >
          {content}
        </div>
      </motion.div>
    </div>
  );
}

function usePrevious(value) {
  const previousValueRef = useRef();

  useEffect(() => {
    previousValueRef.current = value;
  }, [value]);

  return previousValueRef.current;
}
