import React, { useEffect, useState } from "react";
import Styles from "./myAppointments.module.scss";
import AppBar from "shared/appBar/appBar";
import { Link } from "react-router-dom";
import { AppointmentAPI } from "apis/appointmentAPI";
import Loading from "shared/loading/loading";
import { PRIMARYORANGE } from "shared/colors";
import NotFound from "shared/notFound/notFound";
import {
  DoctorDefaultImage_Female,
  DoctorDefaultImage_Male,
} from "shared/constants";

export default function MyAppointments() {
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  function getAppointments() {
    AppointmentAPI.getAll()
      .then((response) => {
        if (response.success === true) {
          const data = response.data.appointmentsList.map(
            (appointment: any) => {
              return {
                id: appointment._id,
                appointmentNumber: appointment.appointmentNo,
                doctorName: appointment.doctorId[0]?.name,
                docCategory: appointment.doctorId[0]?.specialization,
                image:
                  appointment.doctorId[0]?.profileImage.length > 10
                    ? appointment.doctorId[0]?.profileImage
                    : appointment.doctorId[0]?.gender === "Male"
                    ? DoctorDefaultImage_Male
                    : DoctorDefaultImage_Female,
                status: appointment.status,
                date:
                  appointment.date.split("-")[2] +
                  "-" +
                  appointment.date.split("-")[1] +
                  "-" +
                  appointment.date.split("-")[0],
                time: appointment.slot,
                patientName: appointment.patientId[0].name,
              };
            }
          );
          setIsLoading(false);
          setAppointments(data);
        }
      })
      .catch((err) => {
        return "Error fetching Appointments!" + err;
      });
  }
  useEffect(() => {
    setIsLoading(true);
    getAppointments();
  }, []);
  return (
    <>
      <AppBar title="My Appointments" />
      {isLoading ? (
        <Loading loadingColor={PRIMARYORANGE} />
      ) : appointments.length === 0 ? (
        <NotFound displayText={"No Appointments Found!"} />
      ) : (
        appointments.map((appointment: any) => {
          return (
            <Link
              to={`/appointment/${appointment.id}`}
              state={{ patientName: appointment.patientName }}
              key={appointment.id}
            >
              <div className={`${Styles.appointmentCard} mb-3`}>
                <div className={`${Styles.body} d-flex`}>
                  <div className={Styles.docImage}>
                    <img
                      src={appointment.image ?? DoctorDefaultImage_Male}
                      alt="Kapeefit Doctor"
                    />
                  </div>
                  <div className={Styles.bodyInfo}>
                    <div className={Styles.title}>{appointment.doctorName}</div>
                    <div className={Styles.category}>
                      {appointment.docCategory}
                    </div>
                    <div className={Styles.appointmentNum}>
                      Appointment No.: {appointment.appointmentNumber}
                    </div>
                  </div>
                </div>
                <div className={`${Styles.appointmentCard_bottom} d-flex`}>
                  <div
                    className={`${Styles.status} d-flex ${
                      appointment.status === "confirmed"
                        ? Styles.statusConfirm
                        : appointment.status === "rejected"
                        ? Styles.statusRejected
                        : ""
                    }`}
                  >
                    <div className={Styles.dot}>&#183;</div>
                    <div>&nbsp;{appointment.status}</div>
                  </div>
                  <div className={`${Styles.dateTime} flex-fill`}>
                    {appointment.date} <b>{appointment.time}</b>
                  </div>
                </div>
              </div>
            </Link>
          );
        })
      )}
    </>
  );
}
