import React from "react";
import { LARGE, MIDDLE, NORMAL, RIGHT, SMALL } from "shared/constants";
import Styles from "./heading.module.scss";

export default function Heading({
  size = NORMAL,
  align = MIDDLE,
  title = "",
  width = "100%",
}: any) {
  return (
    <h3
      className={Styles.defaultStyle}
      style={{
        textAlign:
          `${align}` === MIDDLE
            ? "center"
            : `${align}` === RIGHT
            ? "right"
            : "left",
        width: `${width}`,
        fontSize:
          `${size}` === LARGE ? "24px" : `${size}` === SMALL ? "18px" : "20px",
      }}
    >
      {title}
    </h3>
  );
}
