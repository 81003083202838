import React from "react";
import Styles from "./chipCardView.module.scss";

const ChipCardView = (props) => {
  return (
    <>
      <div
        className={`${Styles.chipCardViewContainer} d-flex align-items-center me-4`}
      >
        <div className={`${Styles.chipImageContainer} `}>
          <img src={props.imageURI} alt="" width="30px" />
        </div>
        <div className={Styles.chipTitleContainer}>{props.title}</div>
      </div>
    </>
  );
};

export default ChipCardView;
