export function defineCancelApiObject(apiObject) {
  const cancelApiObject = {};

  Object.getOwnPropertyNames(apiObject).forEach((apiPropertyName) => {
    let abortSinglaController: AbortController;

    cancelApiObject[apiPropertyName] = {
      handelRequestCancellation: () => {
        if (abortSinglaController) {
          abortSinglaController.abort();
        }

        abortSinglaController = new AbortController();

        return abortSinglaController;
      },
    };
  });

  return cancelApiObject;
}
