import React, { useEffect, useState } from "react";
import Styles from "./medicinesCart.module.scss";
import AppBar from "shared/appBar/appBar";
import ProductCartList from "shared/product/productCartList/productCartList";
import { LARGE, LEFT, ProductDefaultImage, SMALL } from "shared/constants";
import Heading from "shared/heading/heading";
import { useNavigate } from "react-router-dom";
import Button from "shared/button/button";
import { primaryButtonStyle } from "shared/buttonStyles";
import CouponChecker from "shared/couponChecker/couponChecker";
import NotFound from "shared/notFound/notFound";
import { ToastMessage } from "shared/toast/toastMsg";
import { CartAPI } from "apis/cartAPI";
import { MEDIUM } from "shared/buttonSize";
import Loading from "shared/loading/loading";
import BottomSheet from "shared/bottomSheet/bottomSheet";
import AddressSelection from "shared/addressSelection/addressSelection";
import { getCartIdFromLocal, getProfileDataFromLocal } from "utils/utils";
import { OrderAPI } from "apis/orderAPI";

export default function MedicineCart() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [addressDetails, setAddressDetails] = useState<any>({});
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const walletBalance = 0;

  // Fetch Medicine cart
  function fetchMedicineCart() {
    CartAPI.getCartProducts()
      .then((response) => {
        if (response.success === true && response.data !== null) {
          const products = response.data.items.map((product) => {
            return {
              id: product.productId._id,
              name: product.productId.name,
              // pricePreGst: product.productId.price ?? 0,
              // gstSlab: product.tax,
              price: product.productId.price ?? 0,
              qty: product.quantity,
              thumbnail:
                product.productId.images.length > 0
                  ? product.productId.images[0].src
                  : ProductDefaultImage,
            };
          });
          setTotalPayableAmount(response.data.totalPayAmount);
          setProductList(products);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          return ToastMessage.show("Data Not Found");
        }
      })
      .catch((error) => {
        return ToastMessage.show(
          "Network Error! Please try agian later",
          error
        );
      });
  }

  // Left commented if GST calculation is required seperately
  // let gstAmount_5 = 0;
  // let gstAmount_12 = 0;
  // let gstAmount_18 = 0;
  // let gstAmount_28 = 0;
  // let billGrandTotal = 0;

  // function updateGstAmount(product: any) {
  //   let amount = product.pricePreGst * product.qty;
  //   switch (product.gstSlab) {
  //     case 5:
  //       gstAmount_5 = amount * (5 / 100);
  //       break;
  //     case 12:
  //       gstAmount_12 = amount * (12 / 100);
  //       break;
  //     case 18:
  //       gstAmount_18 = amount * (18 / 100);
  //       break;
  //     case 28:
  //       gstAmount_28 = amount * (28 / 100);
  //       break;
  //     default:
  //       amount = 0; // Zero gst case;
  //   }
  // }

  /* Logic for Post Coupon Code validation */
  function couponCodeCallback(couponStatusData: boolean) {
    console.log(couponStatusData);
  }

  /**
   *    Handle Products List update using callback from Component
   *    Both for delete and qty change
   */
  const handleCartUpdateViaComponent = () => {
    // Refetch and load the cart details
    fetchMedicineCart();
  };

  /**
   *    Handle address Selection from Component
   */
  function handleAddressSelection(data: any) {
    onClose();
    setAddressDetails(data);
  }

  function onClose() {
    setIsOpen(false);
  }

  function onOpen() {
    setIsOpen(true);
  }

  function onToggle() {
    setIsOpen(true);
  }

  const addressSelectionComponent = (
    <div>
      <AddressSelection addressSelectionCallback={handleAddressSelection} />
    </div>
  );

  /**
   *    Create order if success proceed to Payment(RazorPay)
   */
  function createOrderAndProceedToPayment() {
    setIsCreatingOrder(true);
    console.log("Creating Order...", addressDetails);
    if (!addressDetails.addressId) {
      return ToastMessage.show("Please select address before proceeding");
    }
    const createOrderReqBody = new FormData();
    createOrderReqBody.append("patientId", getProfileDataFromLocal().id);
    createOrderReqBody.append("addressId", addressDetails.addressId);
    createOrderReqBody.append("cartId", getCartIdFromLocal());
    createOrderReqBody.append("userRemark", "User Remarks: default");
    OrderAPI.createOrderProducts(createOrderReqBody)
      .then((response) => {
        if (response.success === true) {
          // if order is generated clear it from local
          localStorage.removeItem("cartProducts");
          const razorpay_checkout_data = {
            amount:
              response.data.placeProductOrder.orderInstance.amount.toString(),
            currency: "INR",
            business_name: "Kapeefit",
            description: "Order",
            image: "https://www.kapeefit.com/favicon.png",
            razorpay_order_id: response.data.placeProductOrder.razorpayOrderId,
            patient_name: getProfileDataFromLocal().name,
            patient_mobile: response.data.placeProductOrder.patientMobile,
            patient_email:
              response.data.placeProductOrder.email ?? "temp@kapeefit.com",
            order_id: response.data.placeProductOrder._id,
            patient_id: response.data.placeProductOrder.patientId,
          };
          setIsCreatingOrder(false);
          navigate("/cart/medicine/payment", {
            state: { razorpay_checkout_data: razorpay_checkout_data },
          });
        } else {
          return ToastMessage.show(
            "Error creating order. Refresh and try again"
          );
        }
      })
      .catch((error) => {
        return ToastMessage.show("Error. Please try again later");
      });
  }

  useEffect(() => {
    fetchMedicineCart();
  }, []);
  return (
    <>
      <AppBar title="Cart" />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {productList && productList.length > 0 ? (
            <div>
              <ProductCartList
                productList={productList}
                qtyUpdateCallback={handleCartUpdateViaComponent}
                deleteProductCallback={handleCartUpdateViaComponent}
              />
              <div className="mt-4"></div>

              {/* Shipping Address Details */}
              <div>
                <div className="d-flex">
                  <Heading
                    size={SMALL}
                    align={LEFT}
                    title="Shipping Address"
                    width={"70%"}
                  />
                  <div className={Styles.rightViewMore} onClick={onToggle}>
                    Change Address
                  </div>
                </div>
                <div className={Styles.address}>
                  {addressDetails && addressDetails.userName ? (
                    <>
                      {addressDetails.userName}
                      <br></br>
                      {addressDetails.address}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* Cupon Code Checker */}
              <CouponChecker handleCallback={couponCodeCallback} />

              {/* Wallet Selection */}
              <div className={`${Styles.walletSelection} d-flex mt-3`}>
                <div className={Styles.walletSelectionContent}>
                  <b>Wallet</b>
                  <br></br>
                  <span>Your wallet balance is {walletBalance}</span>
                </div>
                <div className={Styles.walletCheckbox}>
                  <input type="checkbox" />
                </div>
              </div>

              <div className="mt-4"></div>
              {/* Total Amount Calculations */}
              <div>
                <Heading
                  size={MEDIUM}
                  align={LEFT}
                  title="Total Amount"
                  width={"70%"}
                />
                <div className={`${Styles.billDetailsContainer} mt-2`}>
                  {productList
                    ? productList.map((product: any, index) => {
                        // updateGstAmount(product);
                        // billGrandTotal +=
                        //   gstAmount_5 +
                        //   gstAmount_12 +
                        //   gstAmount_18 +
                        //   gstAmount_28 +
                        //   product.pricePreGst * product.qty;
                        return (
                          <div
                            className={`${Styles.billProductRow} d-flex`}
                            key={index}
                          >
                            <div className={Styles.billProductName}>
                              {product.name}
                            </div>
                            <div className={Styles.billProductQty}>
                              X {product.qty}
                            </div>
                            <div className={Styles.billProductTotal}>
                              {(product.price * product.qty).toFixed(2)}
                            </div>
                          </div>
                        );
                      })
                    : ""}

                  {/* Commented in case need to calculate GST seprately. RIght now the value coming through API is inc. of GST. */}
                  {/* GST Tax Rows */}
                  {/* <div className="mt-3"></div>
              {gstAmount_5 !== 0 ? (
                <div className={`${Styles.gstAmountRow} d-flex`}>
                  <div className={Styles.billGstTitle}>GST @ 5%</div>
                  <div className={Styles.billGstValue}>
                    &#8377;{gstAmount_5.toFixed(2)}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {gstAmount_12 !== 0 ? (
                <div className={`${Styles.gstAmountRow} d-flex`}>
                  <div className={Styles.billGstTitle}>GST @ 12%</div>
                  <div className={Styles.billGstValue}>
                    &#8377;{gstAmount_12.toFixed(2)}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {gstAmount_18 !== 0 ? (
                <div className={`${Styles.gstAmountRow} d-flex`}>
                  <div className={Styles.billGstTitle}>GST @ 18%</div>
                  <div className={Styles.billGstValue}>
                    &#8377;{gstAmount_18.toFixed(2)}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {gstAmount_28 !== 0 ? (
                <div className={`${Styles.gstAmountRow} d-flex`}>
                  <div className={Styles.billGstTitle}>GST @ 28%</div>
                  <div className={Styles.billGstValue}>
                    &#8377;{gstAmount_28.toFixed(2)}
                  </div>
                </div>
              ) : (
                <></>
              )} */}

                  <div className={`${Styles.billTotal} d-flex`}>
                    <div className={Styles.billTotalLabel}>Total</div>
                    <div className={Styles.billTotalValue}>
                      {totalPayableAmount}
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          ) : (
            <NotFound />
          )}
          {productList && productList.length > 0 ? (
            <div
              className={Styles.proceedButton}
              onClick={createOrderAndProceedToPayment}
            >
              <Button
                isLoading={isCreatingOrder === true ? true : false}
                disabled={isCreatingOrder === true ? true : false}
                style={primaryButtonStyle}
                hoveredStyle={primaryButtonStyle}
                size={LARGE}
              >
                Proceed to checkout
              </Button>
            </div>
          ) : (
            ""
          )}
        </>
      )}
      <BottomSheet
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        content={addressSelectionComponent}
        size={LARGE}
      />
    </>
  );
}
