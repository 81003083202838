import React, { useEffect, useState } from "react";
import Styles from "./lobbyScreen.module.scss";
import { useLocation, useParams } from "react-router-dom";
import {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";
import AgoraUIKit from "agora-react-uikit";

const CallScreen = () => {
  const { state } = useLocation();
  //   const [calling, setCalling] = useState(false);
  //   const isConnected = useIsConnected(); // Store the user's connection status
  //   const [appId, setAppId] = useState("");
  //   const [channel, setChannel] = useState("");
  //   const [token, setToken] = useState("");
  const [videoCall, setVideoCall] = useState(true);

  const rtcProps = {
    appId: process.env.REACT_APP_AGORA_APP_ID ?? "",
    channel: state && state.id ? state.id : "",
    token: process.env.REACT_APP_AGORA_TOKEN ?? null,
  };
  //   useJoin(
  //     {
  //       appid: process.env.REACT_APP_AGORA_APP_ID ?? "",
  //       channel: state && state.id ? state.id : "",
  //       token: process.env.REACT_APP_AGORA_TOKEN ?? null,
  //     },
  //     calling
  //   );

  //   const [micOn, setMic] = useState(true);
  //   const [cameraOn, setCamera] = useState(true);
  //   const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  //   const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  //   usePublish([localMicrophoneTrack, localCameraTrack]);

  //   const remoteUsers = useRemoteUsers();

  const callbacks = {
    EndCall: () => setVideoCall(false),
  };
  useEffect(() => {
    console.log(process.env.REACT_APP_AGORA_APP_ID);
    console.log(state);
    // setCalling(true);
  });
  return (
    <div className={`${Styles.room}`}>
      {videoCall ? (
        <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
          <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} />
        </div>
      ) : (
        <h3 onClick={() => setVideoCall(true)}>Join</h3>
      )}
    </div>
  );
};

export default CallScreen;
