import React, { useEffect, useRef, useState } from "react";
import Styles from "./doctorDateTimeSlots.module.scss";
import { DoctorsAPI } from "apis/doctorsAPI";
import moment from "moment";

function DoctorDateTimeSlots({ doctorID, sendDataToParent }) {
  const [dateIndexSelected, setDateIndexSelected] = useState(0);
  const [dateSelected, setDateSelected] = useState("");
  const [slotSelected, setSlotSelected] = useState("");
  const [slotsData, setSlotsData] = useState<any>();
  const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const dayCount = useRef(-1);
  const maxDate = useRef(30); //This will sotre the current date and updated to 1 when valid last date of the month is reached i.e.30/31
  let slotCounterI = -2;
  let slotCounterJ = -1;
  const newDate = new Date();
  const todaysDate =
    newDate.getFullYear() +
    "-" +
    (newDate.getMonth() + 1) +
    "-" +
    newDate.getDate();
  async function getDoctorSlots(todaysDate: string) {
    const params = {
      doctorId: doctorID,
      date: todaysDate,
    };
    const slotsDataResponse = await DoctorsAPI.getSlots(params)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        // console.log(e.response.status);
      });
    setSlotsData(slotsDataResponse.data.slots);
    // dayCount.current =
    //   weekDays.indexOf(slotsDataResponse.data.day.slice(0, 3)) - 1;
  }

  /**
   *    Select Date function
   *    @date input param for date
   *    1. Update the UI selection
   *    2. Rerun the API to fetch latest Slots
   */
  function selectDate(selectedDate, index) {
    setDateIndexSelected(index);
    setSlotSelected("");

    let monthWeight = 0;
    let yearWeight = 0;
    if (newDate.getDate() > selectedDate) {
      monthWeight = 1;
      if (newDate.getMonth() + 1 === 12) {
        yearWeight = 1;
        monthWeight = -1;
      }
    }

    let newMonth =
      monthWeight === -1 ? 1 : newDate.getMonth() + 1 + monthWeight;
    let userSelectedDate =
      newDate.getFullYear() +
      yearWeight +
      "-" +
      newMonth.toString().padStart(2, "0") +
      "-" +
      selectedDate.toString().padStart(2, "0");
    getDoctorSlots(userSelectedDate);
    setDateSelected(userSelectedDate);
  }
  useEffect(() => {
    getDoctorSlots(todaysDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={`${Styles.dateRangeList} d-flex justify-content-around`}>
        {slotsData
          ? Array(14)
              .fill(newDate.getDate())
              .map((val, index) => {
                let isValidDate = moment(
                  newDate.getFullYear() +
                    "-" +
                    (newDate.getMonth() + 1).toString().padStart(2, "0") +
                    "-" +
                    (val + index)
                ).isValid();
                // Keep track of maxValid date to get the Final Date
                maxDate.current = isValidDate ? val + index : maxDate.current;
                // Get the final date based on Max and Valid date
                let finalDate = isValidDate
                  ? val + index
                  : val + index - maxDate.current;
                dayCount.current += 1;
                if (dayCount.current > 6) {
                  dayCount.current = 0;
                }
                return (
                  <div
                    className={Styles.dateContainer}
                    key={index}
                    onClick={() => {
                      selectDate(finalDate, index);
                      sendDataToParent({});
                    }}
                    style={{
                      color: dateIndexSelected === index ? "white" : "#008080",
                      backgroundColor:
                        dateIndexSelected === index ? "#008080" : "white",
                    }}
                  >
                    <div className={Styles.date}>{finalDate}</div>
                    <div className={Styles.day}>
                      {
                        weekDays[
                          dayCount.current + 1 > 6 ? 0 : dayCount.current + 1
                        ]
                      }
                    </div>
                  </div>
                );
              })
          : ""}
      </div>
      <div className={`${Styles.timeSlotsContainer} d-flex`}>
        {slotsData && slotsData.length > 0 ? (
          slotsData.map((val, index, slots) => {
            slotCounterI += 2;
            slotCounterJ += 2;
            return (
              <div key={index}>
                {slotCounterI < slotsData.length ? (
                  <div
                    className={`${
                      !slots[slotCounterI].active ? Styles.disabledTimeSlot : ""
                    } ${Styles.timeSlot}`}
                    onClick={(e) => {
                      if (
                        e.currentTarget.classList.value.search(
                          "disabledTimeSlot"
                        ) === -1
                      ) {
                        sendDataToParent({
                          slot: dateSelected, // YYYY-MM-DD
                          time: e.currentTarget.innerHTML, // "09:00 AM"
                        });

                        setSlotSelected(index + "a");
                      }
                    }}
                    style={{
                      color: slotSelected === index + "a" ? "white" : "#008080",
                      backgroundColor:
                        slotSelected === index + "a" ? "#008080" : "white",
                    }}
                  >
                    {slots[slotCounterI].slot}
                  </div>
                ) : (
                  ""
                )}
                {slotCounterJ < slotsData.length ? (
                  <div
                    className={`${
                      !slots[slotCounterJ].active ? Styles.disabledTimeSlot : ""
                    } ${Styles.timeSlot}`}
                    onClick={(e) => {
                      if (
                        e.currentTarget.classList.value.search(
                          "disabledTimeSlot"
                        ) === -1
                      )
                        sendDataToParent({
                          slot: dateSelected, // YYYY-MM-DD
                          time: e.currentTarget.innerHTML, // "09:00 AM"
                        });
                      setSlotSelected(index + "b");
                    }}
                    style={{
                      color: slotSelected === index + "b" ? "white" : "#008080",
                      backgroundColor:
                        slotSelected === index + "b" ? "#008080" : "white",
                    }}
                  >
                    {slots[slotCounterJ].slot}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })
        ) : (
          <div className={Styles.noSlotsContainer}>No Slots available</div>
        )}
      </div>
    </>
  );
}

export default DoctorDateTimeSlots;
