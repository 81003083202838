import React from "react";
import Styles from "./toastMsg.module.scss";
import { Toast } from "bootstrap";

export function ToastMsg() {
  return (
    <div className={`d-flex text-center`}>
      <div
        className={`${Styles.toastContainer} toast-container position-fixed`}
      >
        <div
          id="liveToast"
          className={`${Styles.toaster} toast`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-body" id={"toast-content"}></div>
        </div>
      </div>
    </div>
  );
}

export enum Delay {
  Short,
  Normal,
  Long,
}

export const ToastMessage = {
  show: (message: string = "Done", delay: Delay = Delay.Normal) => {
    const toastLiveExample = document.getElementById("liveToast");
    document.getElementById("toast-content")!.innerHTML = message;
    const toastBootstrap = Toast.getOrCreateInstance(toastLiveExample!);
    const visibleForSeconds =
      delay === Delay.Normal ? 3000 : delay === Delay.Short ? 1500 : 5000;
    toastBootstrap.show();
    setTimeout(() => {
      toastBootstrap.hide();
    }, visibleForSeconds);
  },
};
