import {
  DISBALEDGRAY,
  PRIMARYORANGE,
  PRIMARYORANGEDARK,
  WHITE,
} from "./colors";

export const primaryButtonStyle = {
  borderRadius: "10px",
  backgroundColor: PRIMARYORANGE,
  buttonTextColor: WHITE,
  border: 0,
};
export const primaryButtonDisabledStyle = {
  borderRadius: "10px",
  backgroundColor: DISBALEDGRAY,
  buttonTextColor: WHITE,
  border: 0,
};

export const primaryButtonHoveredStyle = {
  borderRadius: "10px",
  backgroundColor: PRIMARYORANGEDARK,
  buttonTextColor: WHITE,
  border: 0,
};

export const primaryButtonLightStyle = {
  borderRadius: "10px",
  backgroundColor: WHITE,
  buttonTextColor: PRIMARYORANGE,
  border: "1px solid #E85222",
};
