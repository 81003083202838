import React from "react";
import Styles from "./cardView.module.scss";
import { DiseaseDefaultImage } from "shared/constants";

export default function CardView({ data, style }: any) {
  return (
    <div className={style}>
      {data &&
        data.map((item: any, index: number) => {
          return (
            <div className={`${Styles.card} text-center me-4`} key={index}>
              <div className={`${Styles.cardImage} d-flex align-items-center`}>
                <img
                  src={
                    item.image === "" || !item.image
                      ? DiseaseDefaultImage
                      : item.image
                  }
                  alt={item.title}
                />
              </div>
              <div className={Styles.cardTitle}>
                {item.title === "" || !item.title ? "" : item.title}
              </div>
            </div>
          );
        })}
    </div>
  );
}
