import React, { useEffect, useState } from "react";
import Styles from "./product.module.scss";
import { Link } from "react-router-dom";
import Button from "shared/button/button";
import { MEDIUM, SMALL } from "shared/buttonSize";
import {
  primaryButtonStyle,
  primaryButtonDisabledStyle,
} from "shared/buttonStyles";
import { DiseaseDefaultImage } from "shared/constants";

export default function ProductMiniCard({ productsList }: any) {
  const [inCartProducts, setInCartProducts] = useState<string[]>([]);

  useEffect(() => {
    const cartProducts = JSON.parse(localStorage.getItem("cartProducts")!);
    if (cartProducts) setInCartProducts(cartProducts.map((e) => e.productId));
  }, []);
  return (
    <div className={`${Styles.productMiniCards} d-flex mt-3`}>
      {productsList.map((product) => {
        return (
          <div key={product.id}>
            <div className={`${Styles.productCardContainer} me-3`}>
              <Link to={`/product/${product.id}`} reloadDocument={true}>
                <div
                  className={`${Styles.productMiniCardImage} d-flex align-items-center`}
                >
                  <img
                    src={product.thumbnail ?? DiseaseDefaultImage}
                    alt="Product Title"
                  />
                </div>
                <div className={`${Styles.productMiniCardContent}`}>
                  <div className={`${Styles.productCardTitle}`}>
                    {product.title}
                  </div>
                  <div className={`${Styles.productCardPriceContainer} d-flex`}>
                    <div className={`${Styles.productCardSellingPrice}`}>
                      &#8377;{product.sellingPrice}
                    </div>
                    <div className={`${Styles.productCardRegularPrice}`}>
                      &nbsp;&nbsp;MRP <del>&#8377;{product.regularPrice}</del>
                    </div>
                  </div>
                  <div
                    className={`${Styles.productCardRatings} d-flex align-items-center`}
                  >
                    <div className={Styles.ratingStars}>
                      <span
                        className={
                          product.ratingValue >= 1
                            ? Styles.activeRating
                            : Styles.inactiveRating
                        }
                      >
                        &#9733;
                      </span>
                      <span
                        className={
                          product.ratingValue >= 2
                            ? Styles.activeRating
                            : Styles.inactiveRating
                        }
                      >
                        &#9733;
                      </span>
                      <span
                        className={
                          product.ratingValue >= 3
                            ? Styles.activeRating
                            : Styles.inactiveRating
                        }
                      >
                        &#9733;
                      </span>
                      <span
                        className={
                          product.ratingValue >= 4
                            ? Styles.activeRating
                            : Styles.inactiveRating
                        }
                      >
                        &#9733;
                      </span>
                      <span
                        className={
                          product.ratingValue >= 5
                            ? Styles.activeRating
                            : Styles.inactiveRating
                        }
                      >
                        &#9733;
                      </span>
                    </div>
                    <div className={Styles.ratingValue}>
                      &nbsp;&nbsp;{product.ratingValue} ({product.ratingCount})
                    </div>
                  </div>
                </div>
              </Link>
              <div className={Styles.productMiniCardActions}>
                <div className={Styles.productCardBuyButton}>
                  <Button
                    disabled={
                      inCartProducts.indexOf(product.id) === -1 ? false : true
                    }
                    style={
                      inCartProducts.indexOf(product.id) === -1 &&
                      product.stockStatus !== "outofstock"
                        ? primaryButtonStyle
                        : primaryButtonDisabledStyle
                    }
                    size={MEDIUM}
                  >
                    {product.stockStatus === "outofstock"
                      ? "Out Of Stock"
                      : inCartProducts.indexOf(product.id) === -1
                      ? "Add"
                      : "In Cart"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
