import React from "react";
import Styles from "./webview.module.scss";
import AppBar from "shared/appBar/appBar";
export default function WebView({
  pageTitle = "Help",
  sourceUrl = "https://kapeefitshop.com/about-us-ayurvedic-pharmacy/",
}) {
  return (
    <>
      <AppBar title={pageTitle} />
      <div className={Styles.displayWebsite}>
        <iframe
          src={sourceUrl}
          frameBorder="0"
          width={"100%"}
          height={"100%"}
          title="Kapeefit Website"
        ></iframe>
      </div>
    </>
  );
}
