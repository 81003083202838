import React from "react";
import Styles from "./orderProductsList.module.scss";
import { ProductDefaultImage } from "shared/constants";

export default function OrderProductsList({ productsList }) {
  return (
    <>
      {productsList && productsList.length > 0
        ? productsList.map((product, index) => {
            return (
              <div
                className={`${Styles.productContainer} d-flex mt-2`}
                key={index}
              >
                <div className={`${Styles.productImage}`}>
                  <img
                    src={product.thumbnail ?? ProductDefaultImage}
                    alt="Product"
                  />
                </div>
                <div className={`${Styles.productDetails}`}>
                  <span className={`${Styles.productTitle}`}>
                    {product.name}
                  </span>
                  <br></br>
                  <span className={`${Styles.productPrice}`}>
                    &#8377; {product.price}
                  </span>
                </div>
                <div className={`${Styles.quantityContainer}`}>
                  <div className={`${Styles.quantity}`}>
                    Qty {product.quantity}
                  </div>
                </div>
              </div>
            );
          })
        : "No Products Found"}
    </>
  );
}
