import React, { useEffect, useState } from "react";
import AppBar from "shared/appBar/appBar";
import Styles from "./familyMembers.module.scss";
import { Link } from "react-router-dom";
import { ProfileAPI } from "apis/profileAPI";
import { relationsList } from "shared/constants";
import { getAgeFromDOB } from "utils/utils";

export default function FamilyMembers() {
  const [familyMembers, setFamilyMembers] = useState([]);

  function getFamilyMembers() {
    ProfileAPI.getFamilyMembers()
      .then((response) => {
        if (response.success === true) {
          setFamilyMembers(response.data);
          return;
        } else {
          return "Failed to get Family Members";
        }
      })
      .catch((e) => {
        return "Failed to get Family Members";
      });
  }

  function deleteMember(memberId) {
    if (window.confirm("You will lose access to this member details")) {
      ProfileAPI.deleteMember(memberId)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === true) {
              getFamilyMembers();
              return;
            } else {
              return "failed";
            }
          } else {
            return "Error deleting Family Member";
          }
        })
        .catch((e) => {
          return "Error deleting Family Member";
        });
    }
  }

  useEffect(() => {
    getFamilyMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AppBar title="Family Members" />
      <div className={`${Styles.heading} d-flex mb-3`}>
        <div className={Styles.countLable}>
          {familyMembers.length} family members added
        </div>
        <div className={Styles.addMember}>
          <Link to={"/profile/familyMembers/addNew"}>Add Member</Link>
        </div>
      </div>

      {familyMembers.length > 0
        ? familyMembers.map((member: any, index) => {
            return (
              <div key={index} className={`mb-3`}>
                <div className={`${Styles.memberCard} d-flex`}>
                  <div className={Styles.profilePic}>p</div>
                  <div className={Styles.info}>
                    <div className={Styles.memberName}>{member.name}</div>
                    <div className={Styles.subInfo}>
                      {relationsList[member.relation.toLowerCase()]}, Age:{" "}
                      {getAgeFromDOB(member.dob)} <br />
                      {member.mobile}
                    </div>
                    <div className={Styles.editButton}>
                      <Link
                        to={`/profile/familyMembers/edit/${member._id}`}
                        state={{
                          member,
                        }}
                      >
                        Edit
                      </Link>
                    </div>
                  </div>
                  <div
                    className={Styles.action}
                    onClick={() => deleteMember(member._id)}
                  >
                    <span className="material-symbols-outlined">delete</span>
                  </div>
                </div>
              </div>
            );
          })
        : ""}
    </>
  );
}
