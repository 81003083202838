import React from "react";
import Styles from "./button.module.scss";
import Loading from "shared/loading/loading";
import { LARGE, MEDIUM, SMALL } from "shared/buttonSize";

function Button({
  isLoading,
  disabled,
  style,
  hoveredStyle,
  size,
  ...props
}: any) {
  const [buttonStyle, setButtonStyle] = React.useState(style);
  return (
    <button
      className={
        size === LARGE ? Styles.primaryButton : Styles.primaryButtonSmall
      }
      style={
        size === LARGE
          ? { ...buttonStyle, minWidth: "100%", height: "50px" }
          : {
              ...buttonStyle,
              width:
                size === SMALL ? "80px" : size === MEDIUM ? "100px" : "50%",
              borderRadius: size === LARGE ? "10px" : "10px",
              height: size === LARGE ? "56px" : "35px",
            }
      }
      disabled={disabled}
      onMouseOver={() => setButtonStyle(disabled ? style : hoveredStyle)}
      onMouseOut={() => setButtonStyle(style)}
    >
      {isLoading ? (
        <Loading
          loadingColor={style.buttonTextColor}
          width={size === LARGE ? "12px" : "8px"}
          height={size === LARGE ? "12px" : "8px"}
        />
      ) : (
        <p
          className={`${Styles.buttonText}`}
          style={{
            color: style.buttonTextColor,
            fontSize: size === LARGE ? "18px" : "14px",
          }}
        >
          {props.children}
        </p>
      )}
    </button>
  );
}

export default Button;
