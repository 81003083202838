import React, { useEffect, useState } from "react";
import Styles from "./appointmentDetails.module.scss";
import AppBar from "shared/appBar/appBar";
import Button from "shared/button/button";
import {
  primaryButtonDisabledStyle,
  primaryButtonHoveredStyle,
  primaryButtonLightStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { LARGE } from "shared/buttonSize";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppointmentAPI } from "apis/appointmentAPI";
import Loading from "shared/loading/loading";
import NotFound from "shared/notFound/notFound";
import { Utils } from "utils";
import { ToastMessage } from "shared/toast/toastMsg";
import BottomSheet from "shared/bottomSheet/bottomSheet";
import DoctorDateTimeSlots from "shared/doctorDateTimeSlots/doctorDateTimeSlots";
import {
  DoctorDefaultImage_Female,
  DoctorDefaultImage_Male,
} from "shared/constants";
import { rescheduleAppointmentReqBody } from "apis/interface";
import { eventsURL } from "apis/apiEndpoints";
import { io } from "socket.io-client";
import { getAccessTokenFromLocal } from "utils/utils";

export default function AppointmentDetails() {
  const { appointmentId = "" } = useParams();
  const [tabActive, setTabActive] = useState(0);
  const [appointmentInfo, setAppointmentInfo] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [checkoutData, setCheckoutData] = useState<any>({});
  const navigate = useNavigate();
  const { state } = useLocation();
  const [doctorId, setDoctorId] = useState();
  const [canReschedule, setCanReschedule] = useState(false);
  const [chatState, setChatState] = useState({
    patientId: "",
    appointmentId: "",
    doctorId: "",
    doctorName: "",
    doctorImage: "",
  });

  const addSlotSelectionToCheckoutObj = (data: any) => {
    const doctorData = checkoutData.doctor;
    const patientData = checkoutData.patient;
    const symptomsData = checkoutData.symptoms;
    setCheckoutData({
      ...data,
      doctor: doctorData,
      patient: patientData,
      symptoms: symptomsData,
    });
  };

  const socket = io(
    eventsURL + "?appointmentId=" + appointmentId + "&role=patient",
    {
      // transports: ["websocket", "polling"], // Include both
      tryAllTransports: true,
      extraHeaders: {
        accesstoken: getAccessTokenFromLocal(),
      },
      closeOnBeforeunload: true,
    }
  );

  /**
   *    Handle Reschedule Button Click
   */
  const rescheduleAppointment = () => {
    if (appointmentInfo.rescheduleCount > 0)
      return ToastMessage.show("Appointment rescheduled is allowed once only!");
    // if Reschedule is allowed open the bottom sheet to select date-time
    onToggle();
  };

  /**
   *
   * Function fetches the details of particular appointment
   * @param appointmentId  - ID for which details needs to be fetched
   */
  function getAppointmentDetails(appointmentId: string) {
    AppointmentAPI.getDetails(appointmentId)
      .then((response) => {
        if (response.success === true) {
          const appointmentData = response.data.appointment;
          const info = {
            appointmentNo: appointmentData.appointmentNo,
            doctorName: appointmentData.doctorName,
            doctorImage:
              appointmentData.doctorId.profileImage &&
              appointmentData.doctorId.profileImage.length > 0
                ? appointmentData.doctorId.profileImage
                : appointmentData.doctorId.gender &&
                  appointmentData.doctorId.gender === "Male"
                ? DoctorDefaultImage_Male
                : DoctorDefaultImage_Female,
            specialization: appointmentData.doctorId.specialization,
            qualification: appointmentData.doctorId.qualifications,
            status: appointmentData.status,
            date:
              appointmentData.date.split("-")[2] +
              "-" +
              appointmentData.date.split("-")[1] +
              "-" +
              appointmentData.date.split("-")[0],
            day: appointmentData.day,
            timeStart: appointmentData.slot,
            timeEnd: appointmentData.slotEnd,
            symptoms: appointmentData.symptom,
            rescheduleCount: appointmentData.reSchedule,
            description: appointmentData.healthInfo ?? "NA",
          };
          setDoctorId(response.data.appointment.doctorId._id);
          setIsLoading(false);
          setAppointmentInfo(info);
          const appointmentDate = info.date.split("-").join("/");
          //   Check date if passed && reschedule count is 0(zero)
          if (
            !Utils.isPastDate(appointmentDate) &&
            info.rescheduleCount === 0
          ) {
            setCanReschedule(true);
          }
          setChatState({
            patientId: appointmentData.patientId,
            appointmentId: appointmentData._id,
            doctorId: appointmentData.doctorId._id,
            doctorName: info.doctorName,
            doctorImage: info.doctorImage,
          });
        }
      })
      .catch((err) => {
        return "Error fetching Appointment Info!";
      });
  }

  /**
   *    Process Reschedule Appointment
   */
  const handleRescheduleAppointment = () => {
    if (!canReschedule)
      return ToastMessage.show("Appointment cannot be rescheduled.");

    // Hit API to reschedule appointment
    const reqBody: rescheduleAppointmentReqBody = {
      date: checkoutData.slot,
      slot: checkoutData.time,
    };
    AppointmentAPI.rescheduleAppointment(appointmentId, reqBody)
      .then((response) => {
        console.log(response.data.data);
        onClose(); // Close the Bottom Sheet once response is recieved.
        if (response.success === true) {
          ToastMessage.show("Appointment Rescheduled Successfully!");
          navigate("/appontment");
        } else if (response.data?.data?.error_message) {
          return ToastMessage.show(response.data?.data?.error_message);
        } else {
          return ToastMessage.show(
            "Appointment rescheduling failed. Please try agian later."
          );
        }
      })
      .catch((error) => {
        return ToastMessage.show(
          "Error rescheduling appointment. Please try again after sometime.",
          error
        );
      });
  };

  /**
   *    Handle Cancel Appointment
   */
  const handleCancelAppointment = () => {
    // const appointmentDate = appointmentInfo.date.split("-").join("/");
    alert("Foo");
    // //   Check date if passed issue toast error
    // if (Utils.isPastDate(appointmentDate)) {
    //   return ToastMessage.show("Date has already passed");
    // }
    if (appointmentInfo.rescheduleCount > 0)
      return ToastMessage.show(
        "Appointment Can not be canceled as it is already rescheduled!"
      );

    //  Hit API to cancle request and show taost, change UI button
  };

  /**
   *    Move to Doctors Consultaiton Lobby Screen
   */
  function proceedToDoctorsLobby() {
    navigate("/appointment/lobby", {
      state: { appointmentInfo, id: appointmentId },
    });
  }

  function onClose() {
    setIsOpen(false);
  }

  function onOpen() {
    setIsOpen(true);
  }

  function onToggle() {
    setIsOpen(true);
  }

  /**
   *    Reschedule Bottom Sheet HTML content
   */
  const bottomSheetDataHTML = (
    <>
      {doctorId ? (
        <div className={"pt-2 mt-5 mx-3"}>
          <DoctorDateTimeSlots
            doctorID={doctorId}
            sendDataToParent={addSlotSelectionToCheckoutObj}
          />
          <div onClick={handleRescheduleAppointment} className={"pt-2"}>
            <Button style={primaryButtonStyle} size={LARGE}>
              Reschedule
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );

  useEffect(() => {
    setIsLoading(true);
    getAppointmentDetails(appointmentId);
    console.log("Appointment ID: ", appointmentId);

    // document.cookie =
    //   "accesstoken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NjJhMTIyNjBjMzM2YjdjZTc3MWVjYTMiLCJtb2JpbGUiOiI4ODY0MDcwMjI0IiwidmFsaWRBcHBDcmVkZW50aWFsS2V5IjoicmFubkxBQm13b2N3Y3NuZGNpbndjamZqY25qZWpqZGUyM2UyMzlyMHdjd2N3Y3d3aiIsImlhdCI6MTcxNDEwOTQ2Nn0.zSAvnEkSgrIJGEpnVXXGLdoA6f7NPz_66UwwEPvS410";

    socket.on("connect", () => {
      console.log("Socket COnnection Established: ", socket.id);
    });

    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    // return () => {
    //   if(socket.readyState())
    // }

    // socket.onclose = () => {
    //   console.log("WebSocket is closed");
    // };

    //  setWs(socket);

    return () => {
      socket.close();
    };
  }, [appointmentId]);
  return (
    <>
      <AppBar title="Appointment Details" />
      {isLoading ? (
        <Loading />
      ) : appointmentInfo && Object.keys(appointmentInfo).length > 0 ? (
        <div>
          <div>
            <div className={`${Styles.doctorDetailsContainer} d-flex`}>
              <div className={Styles.doctorsImage}>
                <img src={appointmentInfo.doctorImage} alt="Kapeefit Doctor" />
              </div>
              <div className={Styles.doctorsInfo}>
                <div className={Styles.doctorsName}>
                  {appointmentInfo.doctorName}
                </div>
                <div className={Styles.doctorsCatg}>
                  {appointmentInfo.specialization}
                </div>
                <div className={Styles.doctorsQualification}>
                  Qualification: {appointmentInfo.qualification}
                </div>
              </div>
            </div>
            <div
              className={` ${Styles.actionIconsContainer} d-flex justify-content-around`}
            >
              <div
                className={Styles.actionIcon}
                onClick={() => {
                  socket.emit("sendChatMessage", {
                    appointmentId: "662b3c45b11f91f4058ff8c1",
                    senderId: "662a12260c336b7ce771eca3",
                    receiverId: "660a9124397bff1e98ddc39f",
                    messageType: "txt",
                    text: "Hi from Patient",
                    file: null,
                  });
                  // navigate("/appointment/chat", { state: chatState });
                }}
              >
                <span className={`material-symbols-outlined`}>chat</span>
              </div>
              {/* <div className={Styles.actionIcon}>
                <span className={"material-symbols-outlined"}>call</span>
              </div> */}

              <div style={{ width: "75%" }} onClick={proceedToDoctorsLobby}>
                <Button style={primaryButtonStyle} size={LARGE}>
                  Join Call
                </Button>
              </div>
            </div>

            <div className={`${Styles.tabs} d-flex mt-3`}>
              <div
                className={` ${tabActive === 0 ? Styles.activeTab : ""} ${
                  Styles.tabItem
                }`}
                onClick={() => setTabActive(0)}
              >
                Appointments
              </div>
              <div
                className={`${tabActive === 1 ? Styles.activeTab : ""} ${
                  Styles.tabItem
                }`}
                onClick={() => setTabActive(1)}
              >
                Prescription
              </div>
            </div>
          </div>

          {tabActive === 0 ? (
            <div>
              <div className={Styles.appointmentDetails}>
                <div
                  className={`${Styles.status} d-flex justify-content-between`}
                >
                  <div className={Styles.green}>
                    {appointmentInfo.status.toUpperCase()}
                  </div>
                  <div className={Styles.date}>{appointmentInfo.date}</div>
                </div>
                <div className={Styles.doctorDetails}>
                  <div className={Styles.orange}>Online Consultation</div>
                  <div className={Styles.doctorsName}>
                    {appointmentInfo.doctorName}
                  </div>
                  <div className={Styles.doctorsCatg}>
                    {appointmentInfo.specialization}
                  </div>
                </div>
                <div className={Styles.patientDetails}>
                  <div className={Styles.patientName}>
                    <span className={Styles.green}>Patient Name: </span>
                    {state.patientName}
                  </div>
                  <div className={Styles.appointmentNo}>
                    <span className={Styles.green}>Apppointmnet No.: </span>
                    {appointmentInfo.appointmentNo}
                  </div>
                  <div className={Styles.slot}>
                    <span className={Styles.green}>Slot: </span>
                    {appointmentInfo.timeStart +
                      " - " +
                      appointmentInfo.timeEnd}{" "}
                    | {appointmentInfo.date} |{" "}
                    {appointmentInfo.day.toUpperCase()}
                  </div>
                </div>
              </div>

              <div className={`${Styles.buttonsContainer} d-flex mt-4`}>
                <div className="flex-grow-1" onClick={rescheduleAppointment}>
                  <Button
                    disabled={!canReschedule}
                    style={
                      !canReschedule
                        ? primaryButtonDisabledStyle
                        : primaryButtonStyle
                    }
                    hoveredStyle={primaryButtonHoveredStyle}
                  >
                    Reschedule
                  </Button>
                </div>
                {/* <div className=""></div> */}
                <div className="flex-grow-1" onClick={handleCancelAppointment}>
                  <Button
                    disabled={
                      appointmentInfo.rescheduleCount > 0 ? true : false
                    }
                    style={
                      appointmentInfo.rescheduleCount > 0
                        ? primaryButtonDisabledStyle
                        : primaryButtonStyle
                    }
                    hoveredStyle={primaryButtonHoveredStyle}
                  >
                    Cancel
                  </Button>
                </div>
              </div>

              <div className={`${Styles.medicalAssesmentSummary} mt-4`}>
                <div className={Styles.assesmentSummaryTitle}>
                  Medical Assesment Summary
                </div>
                <div className={`${Styles.assesmentSummaryInfo} mt-2`}>
                  <b>Symptoms : </b>
                  <span>{[...appointmentInfo.symptoms]}</span>
                  <br></br>
                  <b>Description : </b>
                  <span>{[...appointmentInfo.description]}</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-5">
              <Button
                disabled={false}
                style={primaryButtonLightStyle}
                hoveredStyle={primaryButtonLightStyle}
                size={LARGE}
              >
                View Prescription
              </Button>
              <div className="mt-3"></div>
              <Button
                disabled={false}
                style={primaryButtonStyle}
                hoveredStyle={primaryButtonHoveredStyle}
                size={LARGE}
              >
                Add Prescribed Medicine(s) to cart
              </Button>
            </div>
          )}
        </div>
      ) : (
        <NotFound />
      )}
      <BottomSheet
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        content={bottomSheetDataHTML}
      />
    </>
  );
}
