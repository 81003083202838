import React, { useEffect, useState } from "react";
import Styles from "./familyMembers.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppBar from "shared/appBar/appBar";
import { ACTIVE, ERROR, LARGE, VALID, relationsList } from "shared/constants";
import InputField from "shared/inputField/inputField";
import Button from "shared/button/button";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { ProfileAPI } from "apis/profileAPI";
import { ToastMessage } from "shared/toast/toastMsg";
import { getAgeFromDOB } from "utils/utils";

type inputFormat = {
  value: string | number;
  error?: boolean;
};

export default function EditAddFamilyMember() {
  const stateProps = useLocation();
  const { id = null } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState<inputFormat>({
    value: "",
  });
  const [dob, setDob] = useState<inputFormat>({
    value: 0,
  });
  const [gender, setGender] = useState<inputFormat>({
    value: "",
  });
  const [relation, setRelation] = useState<inputFormat>({
    value: "",
  });
  const [mobile, setMobile] = useState<inputFormat>({
    value: "",
  });

  const validateAge = () => {
    const age = getAgeFromDOB(dob.value);
    if (Number(age) < 18) setDob({ value: dob.value, error: true });
    else if (Number(age) >= 18 && Number(age) <= 100)
      setDob({ value: dob.value, error: false });
  };
  const validateName = () => {
    if (name.value.toString().length < 2)
      setName({ value: name.value, error: true });
    else setName({ value: name.value, error: false });
  };
  const validateGender = () => {
    if (gender.value === "") setGender({ value: gender.value, error: true });
    else setGender({ value: gender.value, error: false });
  };
  const validateRelation = () => {
    if (relation.value === "")
      setRelation({ value: relation.value, error: true });
    else setRelation({ value: relation.value, error: false });
  };
  const validateMobile = () => {
    const mobileNum = mobile.value.toString();
    if (mobileNum.length > 10 || mobileNum.length < 10)
      setMobile({ value: mobile.value, error: true });
    else if (mobileNum.length === 10)
      setMobile({ value: mobile.value, error: false });
  };

  /**
   *    Handle Data Update
   */
  const handleDataUpdate = (memberId: string) => {
    const member = {
      name: name.value,
      realtion: relation.value,
      dob: dob.value,
      gender: gender.value,
      mobile: mobile.value,
    };
    ProfileAPI.editFamilyMember(memberId, member)
      .then((response) => {
        if (response.success === true) {
          console.log(response.data);
          ToastMessage.show("Details Updated Successfully");
          navigate("/profile/familyMembers");
        }
      })
      .catch((err) => {
        console.log(err);
        ToastMessage.show("Error: Details could not be updated.");
        return "Error updating Family member!" + err;
      });
  };

  /**
   *    Add New Family Member
   */

  const handleAddMember = () => {
    const member = {
      name: name.value,
      relation: relation.value,
      dob: dob.value,
      gender: gender.value,
      mobile: mobile.value,
    };
    ProfileAPI.addNewMember(member)
      .then((response) => {
        console.log(response);
        if (response.success === true) {
          console.log(response.data);
          ToastMessage.show("Member Added successfully!");
          navigate("/profile/familyMembers");
        } else {
          ToastMessage.show("Error: Invalid Data!");
        }
      })
      .catch((err) => {
        ToastMessage.show("Member Could not be added!");
        return "Error adding Member!" + err;
      });
  };

  useEffect(() => {
    if (stateProps.state) {
      const member = stateProps.state.member;
      setName({ value: member.name });
      setDob({ value: member.dob });
      setGender({ value: member.gender });
      setRelation({ value: member.relation });
      setMobile({ value: member.mobile });
    }
  }, [stateProps.state]);

  return (
    <>
      <AppBar title={id ? "Edit Family Member" : "Add New Member"} />
      <div className="vstack gap-3 mt-3">
        <InputField
          placeholder="Enter your name"
          autoFocus
          onChange={(e: { target: { value: any } }) =>
            setName({ value: e.target.value })
          }
          onBlur={validateName}
          errorText="Invalid Name"
          isValidState={
            typeof name.error === "undefined"
              ? ACTIVE
              : name.error
              ? ERROR
              : VALID
          }
          value={name.value}
        />
        <select
          className={` ${Styles.dropdown} form-control form-control-lg`}
          value={relation.value}
          onChange={(e) => setRelation({ value: e.target.value })}
          onBlur={validateRelation}
          // defaultValue={relation.value}
        >
          <option value="" disabled>
            -- Select Relation --
          </option>
          {Object.keys(relationsList).map((key, index) => {
            return (
              <option value={key} key={index}>
                {relationsList[key.toLowerCase()]}
              </option>
            );
          })}
        </select>
        <InputField
          placeholder="Choose DOB"
          onChange={(e: { target: { value: any } }) =>
            setDob({ value: e.target.value })
          }
          onBlur={validateAge}
          errorText="You must be above 18 years"
          isValidState={
            typeof dob.error === "undefined"
              ? ACTIVE
              : dob.error
              ? ERROR
              : VALID
          }
          value={dob.value}
          type="date"
        />
        <select
          className={` ${Styles.dropdown} form-control form-control-lg`}
          value={gender.value}
          onChange={(e) => setGender({ value: e.target.value })}
          onBlur={validateGender}
        >
          <option value="" disabled>
            -- Select Gender --
          </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>

        <InputField
          id="mobile_number"
          type="number"
          placeholder="Enter your mobile number"
          labelTitle="+91"
          errorText="Please entera valid a Mobile Number"
          value={mobile.value}
          isIconLabel={true}
          isValidState={
            typeof mobile.error === "undefined"
              ? ACTIVE
              : mobile.error
              ? ERROR
              : VALID
          }
          onChange={(e) => {
            setMobile({ value: e.target.value });
          }}
          onBlur={validateMobile}
        />
        <div className={`d-flex`}>
          <div>
            <input type="checkbox" id="verifyMobile" name="verifyMobile" />
          </div>
          <div>
            <label htmlFor="verifyMobile">
              &nbsp;&nbsp;Want to verify this number?
            </label>
          </div>
        </div>
      </div>
      <div
        className={`${Styles.alignBottom} p-3`}
        onClick={() =>
          id ? handleDataUpdate(stateProps.state.member._id) : handleAddMember()
        }
      >
        <Button
          disabled={false}
          style={primaryButtonStyle}
          hoveredStyle={primaryButtonHoveredStyle}
          size={LARGE}
        >
          Update
        </Button>
      </div>
    </>
  );
}
