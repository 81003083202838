import DisplayScreen from "shared/displayScreen/displayScreen";
import React from "react";
import { useLocation } from "react-router-dom";
const RegistrationConfirmImage = require("../../assets/images/registration_confirm.png");
const RegistrationFailedImage = require("../../assets/images/registration_failed.png");

export default function RegistrationStatus() {
  const { state } = useLocation();
  return (
    <div>
      <DisplayScreen
        image={
          state.status ? RegistrationConfirmImage : RegistrationFailedImage
        }
        title={
          state.status ? "Registration Successfull" : "Registration Failed"
        }
        subTitle={
          state.status
            ? "Your number has been registered with us."
            : "Please try again later. You may continue to browse for now."
        }
        buttonText={state.status ? "Next" : "Skip"}
        buttonLink={""}
      />
    </div>
  );
}
