import React, { useEffect, useState } from "react";
import Styles from "./blogs.module.scss";
import AppBar from "shared/appBar/appBar";
import { ContentAPI } from "apis/contentAPI";
import Loading from "shared/loading/loading";
import NotFound from "shared/notFound/notFound";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  function getAllBlogs() {
    setIsLoading(true);
    ContentAPI.getAllBlogs().then((response) => {
      if (response?.success === true) {
        const blogsData =
          response.data.length > 0
            ? response.data.map((blog) => {
                return {
                  id: blog.id,
                  title: blog.title,
                  data: blog.date,
                  image: blog.featuredImage,
                  content: blog.content,
                };
              })
            : [];
        setBlogs(blogsData);
        setIsLoading(false);
      }
    });
  }

  function loadBlog(content: HTMLElement) {
    navigate("/readBlog", { state: content });
  }
  useEffect(() => {
    getAllBlogs();
  }, []);
  return (
    <>
      <AppBar title={"Blogs"} />
      {blogs.length > 0 ? (
        blogs.map((blog: any, index: number) => {
          console.log(blog);
          return (
            <div
              className={`${Styles.blog} d-flex mb-4`}
              key={index}
              onClick={() => loadBlog(blog.content)}
            >
              <div className={Styles.blogImage}>
                <img src={blog.image} alt="" />
              </div>
              <div className={Styles.blogInfo}>{blog.title}</div>
            </div>
          );
        })
      ) : isLoading ? (
        <Loading loadingColor={"#008080"} />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default Blogs;
