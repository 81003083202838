import React, { useState } from "react";
import Styles from "./onboarding.module.scss";
import InputField from "shared/inputField/inputField";
import { ACTIVE, ERROR, VALID } from "shared/constants";
import Button from "shared/button/button";
import {
  primaryButtonHoveredStyle,
  primaryButtonLightStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { LARGE } from "shared/buttonSize";
import { useNavigate } from "react-router-dom";
import { ToastMessage } from "shared/toast/toastMsg";
import { AuthAPI } from "apis/authAPI";
import { generateOtpRequest } from "apis/interface";

export default function MobileNumberSreen() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [isValid, setIsValid] = useState("");
  function validateInput(_inputValue: any) {
    if (isNaN(_inputValue)) setIsValid(ERROR);
    else if (_inputValue.length > 10) setIsValid(ERROR);
    else if (_inputValue.length === 10) setIsValid(VALID);
    else if (_inputValue.length < 10) setIsValid(ACTIVE);
  }

  const generateOtp = () => {
    // Check if Mobile number is NOT valid - show Error toast
    if (isValid !== VALID) {
      ToastMessage.show("Enter a valid Mobile Number");
      return;
    }
    const requestBody: generateOtpRequest = {
      mobile: inputValue.toString(),
      verificationCode: "hxhxhxhxhxx",
    };
    AuthAPI.getOTP(requestBody)
      .then((response) => {
        if (response.success === true) {
          navigate("/verifyOtp", {
            replace: true,
            state: { mobile: inputValue },
          });
        } else {
          ToastMessage.show("OTP could not be sent. Please try again.");
          return;
        }
      })
      .catch((error) => {
        console.log("Error sending OTP");
      });
  };

  return (
    <div className={`${Styles.pageContainer} text-left pt-5`}>
      <div
        className={`${Styles.progressContainer} d-flex justify-content-between mb-3`}
      >
        <div className={`${Styles.progressBar} ${Styles.progressBarActive}`}>
          {" "}
        </div>
        <div className={`${Styles.progressBar}`}> </div>
      </div>

      <div className={`${Styles.pageTitle} mb-3 text-start`}>
        What is your contact number?
      </div>
      <InputField
        id="mobile_number"
        type="number"
        placeholder="Enter your mobile number"
        labelTitle="+91"
        errorText="Please enter a valid Mobile Number"
        value={inputValue}
        isIconLabel={true}
        isValidState={isValid}
        onChange={(e) => {
          setInputValue(e.target.value);
          validateInput(e.target.value);
        }}
      />
      <div className={`${Styles.miniText} text-start mt-3`}>
        By sharing the follwofing information you agree with the terms & branch
        policy. And you agree to recieveing messages through socials and
        messenger.
      </div>
      <div className={`${Styles.alignBottom}`} onClick={generateOtp}>
        <Button
          disabled={false}
          style={primaryButtonStyle}
          hoveredStyle={primaryButtonHoveredStyle}
          size={LARGE}
        >
          Next
        </Button>
      </div>
      <div
        className={`${Styles.alignBottom2}`}
        onClick={() => {
          navigate("/", {
            replace: true,
          });
        }}
      >
        <Button
          disabled={false}
          style={primaryButtonLightStyle}
          hoveredStyle={primaryButtonLightStyle}
          size={LARGE}
        >
          Skip
        </Button>
      </div>
    </div>
  );
}
