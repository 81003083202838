import { getAccessTokenFromLocal } from "utils/utils";
import {
  wishlist_addProduct,
  wishlist_getList,
  wishlist_removeProduct,
} from "./apiEndpoints";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";
import { apiResponse } from "./interface";

export const WishlistAPI = {
  addProduct: async function (productID: any, cancel = false) {
    try {
      const response = await api.request({
        url: wishlist_addProduct,
        method: "POST",
        data: {
          productId: productID,
          type: "product",
        },
        headers: {
          Authorization: getAccessTokenFromLocal(),
        },
        signal: cancel
          ? cancelApiObject[this.addProduct.name].handelRequestCancellation()
              .signal
          : undefined,
      });
      const result = validateResponse(response);
      if (result.success === true) {
        const wishlist = result.data.products.map(
          (product: any) => product.productId
        );
        const wishlistId = result.data._id;
        updateProductsToLocalStorage(wishlist, wishlistId);
      }
      return result;
    } catch (err) {
      const result = {
        success: false,
        message: "Error adding to wishlist",
        data: null,
      };
      return result;
    }
  },
  getAll: async function (cancel = false) {
    const response = await api.request({
      url: wishlist_getList,
      method: "GET",
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.getAll.name].handelRequestCancellation().signal
        : undefined,
    });

    // Validate response
    const result = validateResponse(response);
    //  If success -> Add data to localStorage, update result data as per api
    if (result.success === true) {
      // Create array of wishlist items & Add to localStorage
      const wishlistItems = result.data[0].products.map(
        (product: any) => product.productId._id
      );
      const wishlistId = result.data[0]._id;
      updateProductsToLocalStorage(wishlistItems, wishlistId);

      result.data = result.data[0].products;
    }
    return result;
  },
  /**
   *  Handles Product Delete from DB and local
   * @param productId
   * @param wishlistType
   * @param cancel
   * @returns
   */
  deleteProduct: async function (
    formData: any,
    contentType: string,
    cancel = false
  ) {
    const wishlistId = localStorage.getItem("wishlistId");
    try {
      const response = await api.request({
        url: wishlist_removeProduct + wishlistId,
        method: "PUT",
        data: formData,
        headers: {
          Authorization: getAccessTokenFromLocal(),
          "Content-Type": contentType,
        },
        signal: cancel
          ? cancelApiObject[this.deleteProduct.name].handelRequestCancellation()
              .signal
          : undefined,
      });

      // Validate response
      const result = validateResponse(response);

      //  If success -> Add data to localStorage, update result data as per api
      if (result.success === true) {
        // Create array of wishlist items & Add to localStorage
        const wishlistItems = result.data.products.map(
          (product: any) => product.productId
        );
        const wishlistId = result.data._id;
        updateProductsToLocalStorage(wishlistItems, wishlistId);

        result.data = result.data.products;
      }
      return result;
    } catch (err) {
      const result = {
        success: false,
        message: "Error fetching wishlist",
        data: null,
      };
      return result;
    }
  },
};

/**
 *    Update product(s) to localStorage
 *    @param ids type array
 */
function updateProductsToLocalStorage(ids: [], wishlistId: string) {
  localStorage.setItem("wishlist", JSON.stringify(ids));
  localStorage.setItem("wishlistId", wishlistId);
}

/**
 *    Validate Response from API
 *    If response is success than return success+data else return error
 */
function validateResponse(response: any) {
  let result: apiResponse;
  if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === true
  ) {
    result = {
      success: true,
      message: "All wishlist items fetched successfully",
      data: response.data.data,
    };
  } else {
    result = {
      success: false,
      message: "Error fetching wishlist",
      data: response,
    };
  }
  return result;
}

// defining the cancel API object for WishlistAPI
const cancelApiObject = defineCancelApiObject(WishlistAPI);
