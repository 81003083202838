import moment from "moment";

export function getAgeFromDOB(dobSource) {
  const date = new Date(dobSource);
  const dob =
    date.getFullYear() +
    "-" +
    (Number(date.getMonth()) + 1).toString().padStart(2, "0") +
    "-" +
    date.getDay().toString().padStart(2, "0");
  return moment().diff(dob, "years");
}

export function getAccessTokenFromLocal() {
  if (localStorage.getItem("profileData")) {
    const profileData = localStorage.getItem("profileData") ?? "";
    return "Bearer " + JSON.parse(profileData ?? "")?.access_token;
  }
  return "";
}

export function getProfileDataFromLocal() {
  const profileData = localStorage.getItem("profileData");
  return JSON.parse(profileData ?? "{}");
}

export function getCartIdFromLocal() {
  const cartId = localStorage.getItem("cartId");
  return cartId ?? "";
}

export function swapYYYYMMDD_and_DDMMYYYY(originalDate: string) {
  return originalDate.split("T")[0].split("-").reverse().join("-");
}
