import { endpoint_getAllSymptoms } from "./apiEndpoints";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";

export const SymptomsAPI = {
  getAll: async function (cancel = false) {
    const response = await api.request({
      url: endpoint_getAllSymptoms,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handelRequestCancellation().signal
        : undefined,
    });
    return response;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(SymptomsAPI);
