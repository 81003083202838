import React, { useEffect, useState } from "react";
import Styles from "./consult.module.scss";
import { BottomNavigation } from "shared/bottomNavigation/bottomNavigation";
import {
  CONSULT,
  DoctorDefaultImage_Female,
  DoctorDefaultImage_Male,
} from "shared/constants";
import Symptoms from "shared/symptoms/symptoms";
import { SymptomsAPI } from "apis/symptomsAPI";
import DoctorsCard2 from "shared/doctors/doctorsCard2/doctorsCard2";
import BottomSheet from "shared/bottomSheet/bottomSheet";
import { DoctorsAPI } from "apis/doctorsAPI";
import TopNavigation from "shared/topNavigation/topNavigation";
import { Link, useLocation } from "react-router-dom";
import Loading from "shared/loading/loading";
import NotFound from "shared/notFound/notFound";

const sortByOptions = [
  "Price - Low to High",
  "Price - High to Low",
  "Experince - Low to High",
  "Experince - High to Low",
];
export default function Consult() {
  const [filters, setFilters] = useState({
    gender: ["male", "female", "other"],
    symptoms: [],
  });
  const [symptomsData, setSymptomsData] = useState([]);
  const [doctorsData, setDoctorsData] = useState([]);
  const [sortBy, setSortBy] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingDoctors, setIsLoadingDoctors] = useState(false);
  const [isLoadingSymptoms, setIsLoadingSymptoms] = useState(false);
  const { state } = useLocation();
  const [selectedFilter, setSelectedFilter] = useState<any>({
    symptoms: [],
  });

  const bottomSheetDataHTML = (
    <div className={Styles.bottomSheetContents}>
      <h3>Sort By</h3>
      {sortByOptions.map((sortByOption, index: any) => {
        return (
          <div
            key={index}
            className={`${sortBy === index ? Styles.active : ""} ${
              Styles.sortOption
            }`}
            onClick={() => {
              setDoctorsData(
                doctorsData.sort((a: any, b: any) =>
                  index <= 1
                    ? index === 0
                      ? a.fees > b.fees
                        ? 1
                        : -1
                      : a.fees > b.fees
                      ? -1
                      : 1
                    : index === 2
                    ? a.experience > b.experience
                      ? 1
                      : -1
                    : a.experience > b.experience
                    ? -1
                    : 1
                )
              );

              setSortBy(index);
            }}
          >
            {sortByOption}
          </div>
        );
      })}
    </div>
  );

  function getSymptomsData() {
    setIsLoadingSymptoms(true);
    SymptomsAPI.getAll()
      .then((response) => {
        //  Check if status is true proceed to create filtered object
        if (response.data.status === true) {
          const symptomNames = response.data.data.map((symptom: any) => {
            return symptom.name;
          });
          setFilters({ ...filters, symptoms: symptomNames });
          let symptom_temp_arr = response.data.data
            .slice(0, 4)
            .map((symptom: any) => {
              return {
                id: symptom._id,
                title: symptom.name,
                image: symptom.image,
              };
            });
          setSymptomsData(symptom_temp_arr);
          setIsLoadingSymptoms(false);
        }
      })
      .catch((e) => {
        return "Error While fetching symptoms";
      });
  }

  function getDoctorsData() {
    setIsLoadingDoctors(true);
    const doctorsDataReqBody = {
      symptoms: selectedFilter.symptom,
    };
    DoctorsAPI.getAll(doctorsDataReqBody)
      .then((response) => {
        //  Check if status is true proceed to create filtered object
        if (response.success === true) {
          let doctors_list = response.data.doctorList.map((doctor) => {
            return {
              id: doctor._id,
              title: doctor.name,
              profile_image: doctor.profileImage
                ? doctor.profileImage
                : doctor.gender === "Male"
                ? DoctorDefaultImage_Male
                : DoctorDefaultImage_Female,
              specialization: doctor.specialization,
              rating: doctor.rating,
              patients:
                doctor.appointmentCount && doctor.appointmentCount.length > 0
                  ? doctor.appointmentCount[0].total
                  : 0,
              reviews:
                doctor.reviewerCount && doctor.reviewerCount.length > 0
                  ? doctor.reviewerCount.totle
                  : 0,
              isKapeefitVerified:
                doctor.doctorType === "Kapeefit_Doctor_Membership" ||
                doctor.doctorType === "Kapeefit_Partner"
                  ? true
                  : false,
              fees: doctor.publishedFee,
              experience: doctor.experience,
              gender: doctor.gender ?? "Other",
            };
          });
          setDoctorsData(doctors_list);
          setIsLoadingDoctors(false);
        }
      })
      .catch((e) => {
        return "Error While fetching Doctors";
      });
  }

  function showDoctorsForSelectedSymptom() {}

  function onClose() {
    setIsOpen(false);
  }

  function onOpen() {
    setIsOpen(true);
  }
  useEffect(() => {
    setSelectedFilter({
      symptoms: state && state.symptom ? [state.symptom] : undefined,
    });
    getSymptomsData();
    setTimeout(() => {
      getDoctorsData();
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopNavigation />

      {/* Top Header for Symptoms */}
      <div className={"d-flex flex-nowrap mt-3"}>
        <div className={`${Styles.symptomsContainer}`}>
          {isLoadingSymptoms ? (
            <Loading loadingColor={"#E85222"} />
          ) : (
            <Symptoms
              symptoms={symptomsData}
              style={`d-flex flex-nowrap`}
              sendItemIdToParent={showDoctorsForSelectedSymptom}
            />
          )}
        </div>
        <div>
          <Link to="/filter" state={{ filters, activeFilter: "symptoms" }}>
            <div className={Styles.viewAllButton}>View All</div>
          </Link>
        </div>
      </div>

      <div
        className={`${Styles.filtersContainer} d-flex justify-content-between`}
      >
        <div
          className={Styles.sortByContainer}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Sort By &#8593;&#8595;&nbsp;&nbsp;&nbsp;{sortByOptions[sortBy]}
        </div>
        <div className={Styles.filters}>
          <Link to="/filter" state={{ filters }}>
            <span className="material-symbols-outlined">filter_alt</span>
            &nbsp;Filters
          </Link>
        </div>
      </div>
      {/* Doctor's List container */}
      <div className={Styles.doctorsList}>
        {isLoadingDoctors ? (
          <Loading loadingColor={"#E85222"} />
        ) : doctorsData.length > 0 ? (
          <div>
            <DoctorsCard2 doctorsData={doctorsData} />
            <br></br>
          </div>
        ) : (
          <NotFound />
        )}
      </div>
      <br></br>
      <BottomNavigation navigationSelection={CONSULT} />
      <BottomSheet
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        content={bottomSheetDataHTML}
      />
    </>
  );
}
