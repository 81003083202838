import React, { useEffect, useState } from "react";
import Styles from "./vitals.module.scss";
import AppBar from "shared/appBar/appBar";
import { useLocation, useNavigate } from "react-router-dom";
import InputFieldWithLabel from "shared/inputFieldWithLabel/inputFieldWithLabel";
import Button from "shared/button/button";
import { primaryButtonStyle } from "shared/buttonStyles";
import { LARGE } from "shared/constants";
import { ProfileAPI } from "apis/profileAPI";
import { ToastMessage } from "shared/toast/toastMsg";
import { I_Vitals } from "apis/interface";

const Vitals = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [vitalsData, setVitalsData] = useState<I_Vitals>({
    height: null,
    weight: null,
    blood_sugar_fasting: null,
    blood_sugar: null,
    bp_systolic: undefined,
    bp_diastolic: undefined,
  });
  const [vitalId, setVitalId] = useState("");

  /**
   *    Fetch Vitals Data
   */
  function getVitalsInfo() {
    ProfileAPI.getMemberVitals(state.id)
      .then((response) => {
        if (response.success === true) {
          if (response.data) {
            setVitalsData({
              height: response.data.heigth ?? null,
              weight: response.data.weight ?? null,
              blood_sugar_fasting: response.data.sugarFasting ?? null,
              blood_sugar: response.data.sugarPp ?? null,
              bp_systolic: response.data.systolicBp ?? undefined,
              bp_diastolic: response.data.diastolicBp ?? undefined,
            });
            setVitalId(response.data._id);
          }
        } else {
          ToastMessage.show(
            "Error fetching Vitals. Please refresh and try again."
          );
        }
      })
      .catch((error) => {
        ToastMessage.show(
          "Error fetching Vitals. Please refresh and try again."
        );
      });
  }

  /**
   *    Set Vitals Data for 1st time
   */
  function saveVitalsData() {
    if (
      vitalId === "" &&
      (vitalsData.height !== null ||
        vitalsData.weight !== null ||
        vitalsData.blood_sugar !== null ||
        vitalsData.blood_sugar_fasting !== null ||
        vitalsData.bp_diastolic !== undefined ||
        vitalsData.bp_systolic !== undefined)
    ) {
      const requestBody = { ...vitalsData, patientId: state.id };
      ProfileAPI.setMemberVitals(requestBody)
        .then((response) => {
          if (response.success === true) {
            ToastMessage.show("Data Saved Successfully!");
            navigate("../ehr/");
          } else {
            ToastMessage.show("Error saving Data! Please try again later.");
          }
        })
        .catch((error) => {
          ToastMessage.show("Error saving Data! Refresh and try again.");
        });
    }
  }

  /**
   *   Update Vitals Data
   */
  function updateVitalsData() {
    console.log("Updating Vitals ----- >");
    if (
      vitalId !== "" &&
      (vitalsData.height !== null ||
        vitalsData.weight !== null ||
        vitalsData.blood_sugar !== null ||
        vitalsData.blood_sugar_fasting !== null ||
        vitalsData.bp_diastolic !== undefined ||
        vitalsData.bp_systolic !== undefined)
    ) {
      const formData = new FormData();
      formData.append("patientId", state.id);
      formData.append(
        "weight",
        vitalsData.weight ? vitalsData.weight.toString() : "0"
      );
      formData.append(
        "heigth",
        vitalsData.height ? vitalsData.height.toString() : "0"
      );
      formData.append(
        "systolicBp",
        vitalsData.bp_systolic ? vitalsData.bp_systolic.toString() : "0"
      );
      formData.append(
        "diastolicBp",
        vitalsData.bp_diastolic ? vitalsData.bp_diastolic.toString() : "0"
      );
      formData.append(
        "sugarPp",
        vitalsData.blood_sugar ? vitalsData.blood_sugar.toString() : "0"
      );
      formData.append(
        "sugarFasting",
        vitalsData.blood_sugar_fasting
          ? vitalsData.blood_sugar_fasting.toString()
          : "0"
      );
      ProfileAPI.updateMemberVitals(formData, vitalId)
        .then((response) => {
          if (response.success === true) {
            ToastMessage.show("Data Saved Successfully!");
            navigate("../ehr/");
          } else {
            ToastMessage.show("Error saving Data! Please try again later.");
          }
        })
        .catch((error) => {
          ToastMessage.show("Error saving Data! Refresh and try again.");
        });
    }
  }
  useEffect(() => {
    getVitalsInfo();
  }, []);
  return (
    <div className={`${Styles.vitalsMainContainer}`}>
      <AppBar title={"Vitals"} />
      <div className={`${Styles.heading} mt-4`}>
        Vitals For: <span>{state.name}</span>
      </div>

      <div className={`${Styles.inputFieldsContainer} mt-3`}>
        <div className={"mb-3"}>
          <InputFieldWithLabel
            typeOfInput={"number"}
            placeholderText={"Height (cm)"}
            value={vitalsData.height}
            getValue={(val) => {
              setVitalsData({ ...vitalsData, height: Number(val) ?? 0 });
            }}
          />
        </div>
        <div className={"mb-3"}>
          <InputFieldWithLabel
            typeOfInput={"number"}
            placeholderText={"Weight (kg)"}
            value={vitalsData.weight}
            getValue={(val) => {
              setVitalsData({ ...vitalsData, weight: Number(val) ?? 0 });
            }}
          />
        </div>
        <div className={"mb-3"}>
          <InputFieldWithLabel
            typeOfInput={"number"}
            placeholderText={"Blood Sugar (fasting)"}
            value={vitalsData.blood_sugar_fasting}
            getValue={(val) => {
              setVitalsData({
                ...vitalsData,
                blood_sugar_fasting: Number(val) ?? 0,
              });
            }}
          />
        </div>
        <div className={"mb-3"}>
          <InputFieldWithLabel
            typeOfInput={"number"}
            placeholderText={"Blood Sugar (post-prandial)"}
            value={vitalsData.blood_sugar}
            getValue={(val) => {
              setVitalsData({ ...vitalsData, blood_sugar: Number(val) ?? 0 });
            }}
          />
        </div>
        <h3 className={`${Styles.headingWithLine}mt-4`}>
          &nbsp;&nbsp;Blood Pressure (BP)&nbsp;&nbsp;
        </h3>
        {/* BP 2 way input */}
        <div className={`${Styles.twoWayInput} d-flex`}>
          <input
            type="number"
            name=""
            id=""
            placeholder={"systolic"}
            value={vitalsData.bp_systolic}
            onChange={(e) => {
              setVitalsData({
                ...vitalsData,
                bp_systolic: Number(e.target.value) ?? 0,
              });
            }}
            min={111}
          />
          <span>/</span>
          <input
            type="number"
            name=""
            id=""
            placeholder={"diastolic"}
            value={vitalsData.bp_diastolic}
            onChange={(e) => {
              setVitalsData({
                ...vitalsData,
                bp_diastolic: Number(e.target.value) ?? 0,
              });
            }}
          />
        </div>
      </div>
      <div
        className={`${Styles.bottomButtonContainer}`}
        onClick={() => {
          vitalId && vitalId.length > 0 ? updateVitalsData() : saveVitalsData();
        }}
      >
        <Button style={primaryButtonStyle} size={LARGE}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default Vitals;
