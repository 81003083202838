import React, { useEffect, useState } from "react";
import Styles from "./ordersScreen.module.scss";
import AppBar from "shared/appBar/appBar";
import NotFound from "shared/notFound/notFound";
import { OrderAPI } from "apis/orderAPI";
import { ToastMessage } from "shared/toast/toastMsg";
import {
  getProfileDataFromLocal,
  swapYYYYMMDD_and_DDMMYYYY,
} from "utils/utils";
import Loading from "shared/loading/loading";
import OrderProductsList from "shared/orderProductsList/orderProductsList";
import { ProductDefaultImage } from "shared/constants";
import { useNavigate } from "react-router-dom";

export default function OrdersScreen() {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState([]);
  const navigate = useNavigate();
  function fetchAllOrders() {
    setIsPageLoading(true);
    OrderAPI.getOrders(getProfileDataFromLocal().id)
      .then((response) => {
        if (response.success === true) {
          const fetchedOrders = response.data.productOrderList.map((order) => {
            return {
              order_id: order.orderId,
              order_uid: order._id,
              status: order.status,
              payment_status:
                order.trackStatus.length > 0
                  ? order.trackStatus[order.trackStatus.length - 1].status
                  : "failed",
              payment_date:
                order.trackStatus.length > 0
                  ? order.trackStatus[order.trackStatus.length - 1].timeAndDate
                  : new Date(),
              delivery_date: "24th Oct 2024",
              productsList:
                order.line_items.length > 0
                  ? order.line_items.map((product) => {
                      return {
                        id: product.product_id,
                        name: product.name,
                        price: product.price,
                        thumbnail:
                          product.image.src.length > 0
                            ? product.image.src
                            : ProductDefaultImage,
                        quantity: product.quantity,
                      };
                    })
                  : [],
              shipping_address: order.shipping,
            };
          });
          setOrderDetails(fetchedOrders);
        } else {
          return ToastMessage.show(
            "Error Fetching orders. Refresh and try agian"
          );
        }
        setIsPageLoading(false);
      })
      .catch((error) => {
        setIsPageLoading(false);
        return ToastMessage.show("Network Error! Please try again later");
      });
  }
  useEffect(() => {
    fetchAllOrders();
  }, []);
  return (
    <>
      <AppBar title="My Orders" />
      {isPageLoading ? (
        <Loading />
      ) : orderDetails && orderDetails.length > 0 ? (
        orderDetails.map((order: any, index) => {
          return (
            <div
              className={`${Styles.order} mb-3`}
              key={index}
              onClick={() =>
                navigate("/shop/order/track", {
                  state: { order },
                })
              }
            >
              <div
                className={`${Styles.orderDetailsHeader1} d-flex justify-content-between mb-2`}
              >
                <div className={`${Styles.OrderID}`}>
                  Order ID: {order.order_id}
                </div>
                <div className={`${Styles.orderStatus}`}>
                  Status:
                  <span className={`${Styles.orange}`}> {order.status}</span>
                </div>
              </div>

              <div
                className={`${Styles.orderDetailsHeader2} d-flex justify-content-between mb-3`}
              >
                <div className={`${Styles.delivery}`}>
                  Delivered On<br></br>
                  <span className={`${Styles.deliveryStatus}`}>
                    {swapYYYYMMDD_and_DDMMYYYY(order.delivery_date)}
                  </span>
                </div>
                <div className={`${Styles.payment}`}>
                  Payment<br></br>
                  <span className={`${Styles.paymentStatus}`}>
                    {order.payment_status}
                  </span>
                  <br></br>
                  <span className={`${Styles.paymentDate}`}>
                    {swapYYYYMMDD_and_DDMMYYYY(order.payment_date)}
                  </span>
                </div>
              </div>

              <OrderProductsList productsList={order.productsList} />
            </div>
          );
        })
      ) : (
        <NotFound displayText={"No Orders Found"} />
      )}
    </>
  );
}
