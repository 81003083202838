import React, { useEffect, useState } from "react";
import Styles from "./profileAddresses.module.scss";
import AppBar from "shared/appBar/appBar";
import { Link } from "react-router-dom";
import { ProfileAPI } from "apis/profileAPI";
import { ToastMessage } from "shared/toast/toastMsg";

export default function ProfileAddresses() {
  const [addressList, setAddressList] = useState([]);

  /**
   *    Fetch All addresses
   *    for the logged in account
   */
  function getAddresses() {
    ProfileAPI.getAllAddresses()
      .then((response) => {
        if (response.success === true) {
          let addresses = response.data.map((address: any) => {
            return {
              addressId: address._id,
              userName: address.name,
              address:
                address.house_flat_no +
                ", " +
                address.area_street +
                ", " +
                address.city +
                ", " +
                address.state +
                " (" +
                address.pincode +
                ") " +
                address.mobile,
              selected: address.isSelect,
            };
          });
          setAddressList(addresses);
        } else {
          throw new Error("Error fetching addresses");
        }
      })
      .catch((error) => {
        return "Error: Unable to get addresses!";
      });
  }

  /**
   *    Delete address as per provided
   *    @param addressId
   */
  function deleteAddress(addressId: string) {
    if (window.confirm("Are you sure? Address will be deleted permanently!")) {
      ProfileAPI.deleteAddress(addressId)
        .then((response) => {
          if (response.success === true) {
            const newAddressList = addressList.filter(
              (address: any) => address.addressId !== addressId
            );
            setAddressList(newAddressList);
            ToastMessage.show("Address deleted successfully!");
          } else {
            throw new Error("Error deleting address or address does not exist");
          }

          // console.log(response);
        })
        .catch((error) => {
          return "Error: Address could not be deleted";
        });
    }
  }

  /**
   *    Update Default Address
   */
  function updateDefaultAddress(_id: string) {
    ProfileAPI.setDefaultAddress(_id)
      .then((response) => {
        if (response.success === true) {
          ToastMessage.show("Default Address updated successfully!");
        } else {
          throw new Error("Error deleting address or address does not exist");
        }
      })
      .catch((error) => {
        return "Error: Address could not be deleted";
      });
  }

  useEffect(() => {
    getAddresses();
  }, []);
  return (
    <>
      <AppBar title="My Addresses" />
      <div className={`${Styles.topHeader} d-flex mb-3`}>
        <div className={Styles.topLabel}>Choose your default address</div>
        <div className={Styles.addLabel}>
          <Link to={"/profile/addAddress"}>
            <div>
              <span
                className={`${Styles.alignVerticalMiddle} material-symbols-outlined`}
              >
                add
              </span>
              Add
            </div>
          </Link>
        </div>
      </div>

      <div>
        {addressList
          ? addressList.map((address: any, index) => (
              <div
                className={`${Styles.addressContainer} d-flex mb-3`}
                key={index}
              >
                <div
                  className={Styles.checkboxConatiner}
                  onClick={() => {
                    console.log(address);
                    updateDefaultAddress(address.addressId);
                  }}
                >
                  <input
                    type="radio"
                    name="address"
                    id=""
                    defaultChecked={address.selected ?? true}
                  />
                </div>
                <div className={Styles.displayAddressInfo}>
                  <div className={Styles.addressTitleName}>
                    {address.userName}
                  </div>
                  <div className={Styles.addressAddress}>{address.address}</div>
                  <Link to={`/profile/editAddress/${address.addressId}`}>
                    <div className={Styles.editButton}>Edit</div>
                  </Link>
                </div>
                <div className={Styles.deleteAddress}>
                  <span
                    className="material-symbols-outlined"
                    onClick={() => deleteAddress(address.addressId)}
                  >
                    close
                  </span>
                </div>
              </div>
            ))
          : ""}
      </div>
    </>
  );
}
