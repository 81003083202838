import React from "react";
import Styles from "./iconArrowButton.module.scss";
import { Link } from "react-router-dom";

const IconArrowButton = (props: any) => {
  return (
    <div className={"mb-4"}>
      <Link to={props.goto} state={{ status: true }}>
        <div className={`${Styles.iconArrowButton} d-flex`}>
          <div className={Styles.buttonIcon}>
            {props.iconImage ? (
              <div className={Styles.buttonImage}>
                <img src={props.iconImage} alt="Button Click" />
              </div>
            ) : (
              <span className="material-symbols-outlined">sell</span>
            )}
          </div>
          <div className={Styles.buttonText}>{props.title}</div>
          <div className={Styles.buttonArrow}>
            <span className="material-symbols-outlined">chevron_right</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default IconArrowButton;
