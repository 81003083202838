import React, { CSSProperties } from "react";
import Styles from "./carouselSlider.module.scss";
import Carousel from "nuka-carousel";

interface DefaultControlsConfigCarousel {
  containerClassName?: string;
  nextButtonClassName?: string;
  nextButtonStyle?: CSSProperties;
  nextButtonText?: React.ReactNode;
  pagingDotsClassName?: string;
  pagingDotsContainerClassName?: string;
  pagingDotsStyle?: CSSProperties;
  prevButtonClassName?: string;
  prevButtonStyle?: CSSProperties;
  prevButtonText?: React.ReactNode;
  prevButtonOnClick?(event: React.MouseEvent): void;
  nextButtonOnClick?(event: React.MouseEvent): void;
  pagingDotsOnClick?(event: React.MouseEvent): void;
}

const CarouselSlider = (imagesList: any) => {
  const defaultControlsConfigCarousle: DefaultControlsConfigCarousel = {
    prevButtonClassName: Styles.hideCarouselButtons,
    nextButtonClassName: Styles.hideCarouselButtons,
    pagingDotsClassName: Styles.carouselDots,
    pagingDotsContainerClassName: Styles.carousleDotsConstainer,
  };
  const imageData = imagesList;

  return (
    <>
      {/* Carousel */}
      <div className={`${Styles.carousleContainer} mb-5`}>
        <div className={Styles.carousleImagesContainer}>
          <Carousel
            autoplay={true}
            wrapAround={true}
            defaultControlsConfig={defaultControlsConfigCarousle}
          >
            {imageData.imagesList.map((image, index) => {
              return (
                <div key={index}>
                  <img src={image} alt="Banner" />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default CarouselSlider;
