import React, { useEffect, useState } from "react";
import Styles from "./shop.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import SearchInput from "shared/searchInput/searchInput";
import CartIcon from "shared/cartIcon/cartIcon";
import LabTestCard from "shared/product/labTestCard";
import { ProductsAPI } from "apis/productsAPI";
import { ToastMessage } from "shared/toast/toastMsg";
import BottomSheet from "shared/bottomSheet/bottomSheet";
import {
  LARGE,
  LEFT,
  SMALL,
  UserDefaultImage_Female,
  UserDefaultImage_Male,
} from "shared/constants";
import { ProfileAPI } from "apis/profileAPI";
import { getAgeFromDOB } from "utils/utils";
import { IntProfileData } from "apis/interface";
import Heading from "shared/heading/heading";
import Button from "shared/button/button";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";

/**
 *  This screen handles view list for both Medicine and Lab tests
 * @returns
 */
export default function Shop() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [labTestData, setLabTestData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [patientsData, setPatientsData] = useState<any>([]);
  const [labTestSelected, setLabTestSelected] = useState("");

  function goBack() {
    navigate(-1);
  }

  function onClose() {
    setIsOpen(false);
  }

  function onOpen() {
    setIsOpen(true);
  }

  function fetchPageData() {
    // Get Pathology Tests List
    ProductsAPI.getPathologyLabTests({ rowsPerPage: 50 })
      .then((response) => {
        if (response.success === true) {
          const labTests = response.data.serviceList.map((labtest) => {
            return {
              id: labtest._id,
              test_name: labtest.serviceName,
              brand: labtest.brandName,
              sale_price: labtest.salesPrice,
              published_price: labtest.publishedPrice,
            };
          });
          setLabTestData(labTests);
        } else {
          return ToastMessage.show(
            "Error fetching data. Refresh and try again"
          );
        }
      })
      .catch((error) => {
        return ToastMessage.show("Error fetching data. Please try agian later");
      });

    // Get Current User from LocalStorage
    const loggedInUserData: IntProfileData = JSON.parse(
      localStorage.getItem("profileData")!
    );

    // Get Family Members Data
    ProfileAPI.getFamilyMembers().then((response) => {
      if (response.success === true) {
        const familyMembers = response.data.map((member: any) => {
          return {
            id: member._id,
            title: member.name,
            image:
              member.image && member.image.length > 0
                ? member.image
                : member.gender === "male"
                ? UserDefaultImage_Male
                : UserDefaultImage_Female,
            gender: member.gender,
            age: getAgeFromDOB(member.dob),
          };
        });
        setPatientsData([
          {
            id: loggedInUserData.id,
            title: loggedInUserData.name + "(Self) ",
            image: loggedInUserData.image ?? UserDefaultImage_Male,
            gender: loggedInUserData.gender,
            age: getAgeFromDOB(loggedInUserData.dob),
          },
          ...familyMembers,
        ]);
      }
    });
  }

  /**
   *  Set State Variable for lab Test ID
   *  when click "Add" buttton for any lab test
   *  and open bottom sheet
   * @param test_id
   */
  function handleAddingLabTestToCart(test_id: string) {
    setLabTestSelected(test_id);
    onOpen();
  }

  /**
   *    Set/Update localStorage with member's info
   *    on clickof any member selected
   *    corresponding to the lab test ID from state variable
   */
  function addMemberSelectionToLocal(test_id, member_id) {
    let cartPathology = [];
    if (localStorage.getItem("cartPathalogy")) {
      cartPathology = JSON.parse(localStorage.getItem("cartPathalogy")!);
    }

    cartPathology.forEach((test: any) => {
      if (test.test_id === test_id) {
        // check if member ID exists in members Array
        test.members.map((e) => e.id === member_id);
      }
    });
  }

  function addLabTestSelectionToCart() {
    console.log();
  }

  const familyMembersListForBottomSheet = (
    <div className={`${Styles.bottomSheetFamilyList} p-4 text-start`}>
      <Heading title={"Choose your booking for"} size={SMALL} align={LEFT} />
      <div className={`${Styles.membersListContainer}`}>
        {patientsData.map((patient, index) => {
          return (
            <div
              className={`${Styles.userSelection} d-flex align-items-center`}
              key={index}
            >
              <div
                className={`${Styles.userSelectionCheckbox}`}
                onClick={() =>
                  addMemberSelectionToLocal(labTestSelected, patient.id)
                }
              >
                <input type={"checkbox"} value={patient.id} />
              </div>
              <div className={`${Styles.userSelectionName}`}>
                {patient.title}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={`${Styles.buttonContainer}`}
        onClick={addLabTestSelectionToCart}
      >
        <div className={`${Styles.psNote}`}>
          Note: You can remove selected member(s) from cart
        </div>
        <Button
          // isLoading={true}
          size={LARGE}
          disabled={true}
          style={primaryButtonStyle}
          hoveredStyle={primaryButtonHoveredStyle}
        >
          Confirm
        </Button>
      </div>
    </div>
  );

  useEffect(() => {
    console.log(state);
    fetchPageData();
  }, []);
  return (
    <>
      <div className={`${Styles.topBar} d-flex align-items-center mb-3`}>
        <div className={Styles.backButton} onClick={goBack}>
          <span className="material-symbols-outlined">arrow_back</span>
        </div>
        <div className={Styles.search}>
          <SearchInput />
        </div>
        <div className={`${Styles.cart}`}>
          <CartIcon type={"labtest"} />
        </div>
      </div>
      <LabTestCard
        productsList={labTestData}
        handleAddCallback={handleAddingLabTestToCart}
      />
      <BottomSheet
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        content={familyMembersListForBottomSheet}
        size={SMALL}
      />
    </>
  );
}
