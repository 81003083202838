export const baseURL = "https://securenetapi.kapeefit.com/api/";
export const eventsURL = "wss://securenetapi.kapeefit.com/";

export const endpoint_getAllSymptoms = "symptom/app/list";
export const endpoint_getAllDoctors = "doctor/mob/active/list";
export const endpoint_getDoctorSlots = "appointment/get/slot";
export const endpoint_getPopularDoctors = "doctor/mob/popular/list";
export const products_featuredProducts = "product/mob/list";
export const products_productViaId = "product/get/";
export const products_relatedProducts = "product/mob/get/related/";
export const pathalogy_getLabTests = "pathology/mob/fetch/list";

/**
 *      Wishilst API endpoints
 */
export const wishlist_addProduct = "wishlist/add/products";
export const wishlist_getList = "wishlist/get/patient";
export const wishlist_removeProduct = "wishlist/remove/one/";

/**
 *      Profile API Endpoints
 */
export const profile_getFamilyMembers = "patient/mob/familyMemberList";
export const profile_addFamilyMember = "patient/mob/addFamilyMember";
export const profile_editFamilyMember = "patient/mob/update/family/member/";
export const profile_deleteMember = "patient/mob/account/delete/family/";
export const profile_getProfileData = "patient/app/profile/";
export const profile_updateProfileData = "patient/mob/update/details";
export const profile_getAddresses = "patient/mob/fetch/address";
export const profile_deleteAddresses = "patient/mob/remove/address/";
export const profile_editAddress = "patient/mob/update/address/";
export const profile_setDefaultAddress = "patient/mob/select/address/";
export const profile_memberVitals = "patient/mob/healthInfo/";

/**
 *      Wallet API Endpoints
 */
export const wallet_getWallet = "wallet/get/account";
export const wallet_getTransactions = "wallet/mob/fetch/transaction";

/**
 *  WooCommerce direct APIs
 */
export const baseURL_wooCommerce = "https://rnq.dae.myftpupload.com/";
export const wooCom_getProductsViaId = "wp-json/wc/v3/products";

/**
 *      Appointments API
 */
export const appointment_getAll = "appointment/mob/fetch";
export const appointment_getDetailsByID = "appointment/get/appointment/";
export const appointment_checkoutDetails = "appointment/mob/checkout";
export const appointment_bookSlotDetails = "appointment/mob/book";
export const appointment_verifyPaymentDetails =
  "appointment/mob/payment/verify";
export const appointment_reschedule = "appointment/mob/reschedule/";

/**
 *      Content API
 */
export const banners_getAll = "banner/list";
export const homePage_getAll = "home/homeScreenAllList";
export const blogs_getTop = "WooCommerce/gettopblogslist";
export const blogs_getAll = "WooCommerce/getblogslist";
export const woocomerce_getCategories =
  "WooCommerce/getallproductcategory/parent/9";
export const medicinePage_getAll = "WooCommerce/getmedicinesectionlist";
export const medicinePage_getProductsBifurcations =
  "WooCommerce/getmedicinesectionwclist";

/**
 *      Membership Plans
 */
export const membership_getAllPlans = "/subscription/list";

/**
 *      EHR
 */
export const ehr_getCompleteData = "/patient/mob/ehrList/";
export const ehr_uploadReport = "/patient/mob/uploadEHR";

/**
 *      Authentication/Login/Signup
 */
export const auth_getOtp = "auth/sendOTP";
export const auth_singupSignIn = "patient/signUpAndSingIn";

/**
 *    Util APIs
 */
export const util_verifyReferralCode = "patient/checkReferral/";

/**
 *    Cart APIs
 */
export const cart_moveLocalToRemote = "cart/move/localtoremote";
export const cart_getMedicineCart = "cart/get/patient";
export const cart_removeProduct = "cart/remove/product";
export const cart_addProduct = "cart/product/add";
export const cart_addLabTest = "cart/labtest/add";
export const cart_getLabTest = "cart/get/labtest";

/**
 *    Order APIs
 */
export const order_createMedicineOrder = "productOrder/mob/create";
export const order_verifyMedicinePayment = "productOrder/mob/payment/verify";
export const order_getAll = "productOrder/mob/list/";
export const order_cancelMedicine = "productOrder/mob/cancel";
