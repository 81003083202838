import { AppointmentAPI } from "apis/appointmentAPI";
import React, { useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "shared/loading/loading";
import { ToastMessage } from "shared/toast/toastMsg";

const RazorpayView = () => {
  const { state } = useLocation();
  const navigator = useNavigate();
  const [Razorpay] = useRazorpay();
  const [isLoading, setIsLoading] = useState(false);

  function showRazorpay() {
    const rzPayOptions = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID!, // Enter the Key ID generated from the Dashboard
      amount: state.razorpay_checkout_data.amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: state.razorpay_checkout_data.currency,
      name: state.razorpay_checkout_data.business_name, //your business name
      description: state.razorpay_checkout_data.description,
      image: state.razorpay_checkout_data.image,
      order_id: state.razorpay_checkout_data.razorpay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (res) {
        setIsLoading(true);
        console.log("RazorPay resp: ", res);
        const verifyPayment_requestBody = {
          appointmentId: state.razorpay_checkout_data.appointment_id,
          patientId: state.razorpay_checkout_data.patient_id,
          status: "success",
          transactionGatewayId: res.razorpay_payment_id,
          paymentMode: "online",
          timeOut: true,
        };
        // verify Payment API with status success and timeout false
        const verifyPayment_response =
          await AppointmentAPI.verifyAppointmentPaymentDuringCheckout(
            verifyPayment_requestBody
          );

        if (verifyPayment_response.data.isBook === true) {
          // show popup
          ToastMessage.show("Appointment Booked Successfully!");
          // redirect to appointments
          navigator("/appointments", { replace: true });
        }
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
        name: state.razorpay_checkout_data.patient_name, //your customer's name
        email: state.razorpay_checkout_data.patient_email,
        contact: state.razorpay_checkout_data.patient_mobile, //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Kapeefit Office", // "Razorpay Corporate Office"
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: function () {
          ToastMessage.show("Appointment not Booked. Please try again!");
          // redirect to appointments
          navigator("/checkout", { state: state.checkoutState });
        },
      },
    };

    const rzp1 = new Razorpay(rzPayOptions);
    rzp1.on("payment.failed", async function (resp) {
      setIsLoading(true);
      console.log("Error RZP: ", resp);
      // in case of failed verify payment API with status failed to release slot block
      const verifyPayment_requestBody = {
        appointmentId: state.razorpay_checkout_data.appointment_id,
        patientId: state.razorpay_checkout_data.patient_id,
        status: "failed",
        transactionGatewayId: "",
        timeOut: true,
      };
      console.log(verifyPayment_requestBody);
      const verifyPayment_response =
        await AppointmentAPI.verifyAppointmentPaymentDuringCheckout(
          verifyPayment_requestBody
        );
      console.log("Verify payment response: ", verifyPayment_response);
      if (
        verifyPayment_response.data.isBook === false ||
        verifyPayment_response.data.gatewayStatus !== "captured"
      ) {
        // show popup
        ToastMessage.show("Appointment not Booked. Please try again!");
        // redirect to appointments
        navigator("/consult/booking", { replace: true });
      }
    });
    rzp1.open();
  }
  useEffect(() => {
    console.log(state);
    showRazorpay();
  }, []);
  return (
    <>
      {isLoading ? (
        <div>
          <p>Verifying Payment Details</p>
          <Loading loadingColor={"#E85222"} />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default RazorpayView;
