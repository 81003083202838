import React, { useEffect, useState } from "react";
import Styles from "./cartIcon.module.scss";
import { Link } from "react-router-dom";

/**
 *  Shows cart icon with bubble.
 *  Bubble data as per localStorage
 * @param type - medicine | labtest
 * @returns
 */
export default function CartIcon({ type = "medicine" }) {
  const [count, setCount] = useState(0);

  function getCount() {
    if (type === "medicine" && localStorage.getItem("cartProducts")) {
      setCount(JSON.parse(localStorage.getItem("cartProducts")!).length);
    } else if (type === "labtest" && localStorage.getItem("cartPathology")) {
      setCount(JSON.parse(localStorage.getItem("cartPathology")!).length);
    } else {
      setCount(0);
    }
  }
  useEffect(() => {
    getCount();
  }, []);
  return (
    <div className={`${Styles.cart}`}>
      <div className={`${Styles.cartBubble}`}>{count}</div>
      <Link to={"/cart"}>
        <span className="material-symbols-outlined">shopping_cart</span>
      </Link>
    </div>
  );
}
