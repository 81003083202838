import { getAccessTokenFromLocal, getProfileDataFromLocal } from "utils/utils";
import {
  profile_addFamilyMember,
  profile_deleteAddresses,
  profile_deleteMember,
  profile_editAddress,
  profile_editFamilyMember,
  profile_getAddresses,
  profile_getFamilyMembers,
  profile_getProfileData,
  profile_memberVitals,
  profile_setDefaultAddress,
  profile_updateProfileData,
} from "./apiEndpoints";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";
import { IntProfileData, apiResponse } from "./interface";
import {
  UserDefaultImage_Female,
  UserDefaultImage_Male,
} from "shared/constants";

export const ProfileAPI = {
  getFamilyMembers: async function (cancel = false) {
    try {
      const response = await api.request({
        url: profile_getFamilyMembers,
        method: "GET",
        headers: {
          Authorization: getAccessTokenFromLocal(),
        },
        signal: cancel
          ? cancelApiObject[
              this.getFamilyMembers.name
            ].handelRequestCancellation().signal
          : undefined,
      });

      return validateResponse(response);
    } catch (err) {
      return {
        success: false,
        message: "Error executing profile API",
        data: err,
      };
    }
  },
  addNewMember: async function (memberInfo: Object, cancel = false) {
    const response = await api.request({
      url: profile_addFamilyMember,
      method: "POST",
      headers: {
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
      },
      data: memberInfo,
      signal: cancel
        ? cancelApiObject[
            this.getFamilyMembers.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  editFamilyMember: async function (memberId: string, member, cancel = false) {
    const response = await api.request({
      url: profile_editFamilyMember + memberId,
      method: "PUT",
      headers: {
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
      },
      data: member,
      signal: cancel
        ? cancelApiObject[
            this.editFamilyMember.name
          ].handelRequestCancellation().signal
        : undefined,
    });

    const result = validateResponse(response);
    return result;
  },
  deleteMember: async function (memberId: string, cancel = false) {
    const response = await api.request({
      url: profile_deleteMember + memberId,
      method: "DELETE",
      headers: {
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
      },
      signal: cancel
        ? cancelApiObject[
            this.getFamilyMembers.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    return response;
  },
  getProfileData: async function (profileId: string, cancel = false) {
    const response = await api.request({
      url: profile_getProfileData + profileId,
      method: "GET",
      signal: cancel
        ? cancelApiObject[
            this.getFamilyMembers.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    return response;
  },
  editProfileData: async function (
    profileInfo: any,
    config?: string,
    cancel = false
  ) {
    const response = await api.request({
      url: profile_updateProfileData,
      method: "PUT",
      headers: {
        Authorization: getAccessTokenFromLocal().toString(),
        "Content-Type": config,
      },
      data: profileInfo,
      signal: cancel
        ? cancelApiObject[this.editProfileData.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    //   save success result to lacalstorage
    if (result.success === true) {
      const localProfileData = getProfileDataFromLocal();
      const data: IntProfileData = {
        id: result.data._id,
        name: result.data.name,
        dob: result.data.dob,
        mobile: result.data.mobile,
        gender: result.data.gender,
        referralCode: result.data.referralCode,
        walletPoints: localProfileData["walletPoints"],
        image:
          result.data.image && result.data.image.length > 0
            ? result.data.image
            : result.data.gender && result.data.gender === "male"
            ? UserDefaultImage_Male
            : UserDefaultImage_Female,
        email: result.data.email,
        access_token: localProfileData.access_token,
      };
      localStorage.setItem("profileData", JSON.stringify(data));
    }

    return result;
  },
  getAllAddresses: async function (cancel = false) {
    const response = await api.request({
      url: profile_getAddresses,
      method: "GET",
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.getAllAddresses.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  deleteAddress: async function (addressId: string, cancel = false) {
    const response = await api.request({
      url: profile_deleteAddresses + addressId,
      method: "DELETE",
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.deleteAddress.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  editAddress: async function (addressId: string, cancel = false) {
    const response = await api.request({
      url: profile_editAddress + addressId,
      method: "PUT",
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.editAddress.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  setDefaultAddress: async function (addressID: string, cancel = false) {
    console.log("Address ID: ", addressID);
    const response = await api.request({
      url: profile_setDefaultAddress + addressID,
      method: "PUT",
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[
            this.setDefaultAddress.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  getMemberVitals: async function (memberId: string, cancel = false) {
    const response = await api.request({
      url: profile_memberVitals + memberId,
      method: "GET",
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.getMemberVitals.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  setMemberVitals: async function (requestBody: object, cancel = false) {
    const response = await api.request({
      url: profile_memberVitals,
      method: "POST",
      data: requestBody,
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.getMemberVitals.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },

  updateMemberVitals: async function (
    requestBody: object,
    vital_id: string,
    cancel = false
  ) {
    console.log("Vital ID: ", vital_id);
    const response = await api.request({
      url: profile_memberVitals + vital_id,
      method: "PUT",
      data: requestBody,
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.getMemberVitals.name].handelRequestCancellation()
            .signal
        : undefined,
    });

    const result = validateResponse(response);
    return result;
  },
};

/**
 *    Validate Response from API
 *    If response is success than return success+data else return error
 */
function validateResponse(response: any) {
  let result: apiResponse;
  if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === true
  ) {
    result = {
      success: true,
      message: "Profile API successfull",
      data: response.data.data,
    };
  } else {
    result = {
      success: false,
      message: "Error executing profile API",
      data: response,
    };
  }
  return result;
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ProfileAPI);
