import { validateResponse } from "utils/apiValidations";
import { defineCancelApiObject } from "./config/axiosUtils";
import { api } from "./config/axiosConfig";
import {
  order_cancelMedicine,
  order_createMedicineOrder,
  order_getAll,
  order_verifyMedicinePayment,
} from "./apiEndpoints";
import { getAccessTokenFromLocal } from "utils/utils";

export const OrderAPI = {
  createOrderProducts: async function (data, cancel = false) {
    const response = await api.request({
      url: order_createMedicineOrder,
      method: "POST",
      data,
      signal: cancel
        ? cancelApiObject[
            this.createOrderProducts.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  verifyMedicineOrderPayment: async function (data, cancel = false) {
    const response = await api.request({
      url: order_verifyMedicinePayment,
      method: "POST",
      data,
      signal: cancel
        ? cancelApiObject[
            this.verifyMedicineOrderPayment.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  getOrders: async function (patientId: string, cancel = false) {
    const response = await api.request({
      url: order_getAll + patientId,
      method: "GET",
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.getOrders.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  cancelMedicineOrder: async function (data, cancel = false) {
    const response = await api.request({
      url: order_cancelMedicine,
      method: "PUT",
      data,
      signal: cancel
        ? cancelApiObject[
            this.cancelMedicineOrder.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(OrderAPI);
