import React, { useEffect, useState } from "react";
import Styles from "./ordersScreen.module.scss";
import AppBar from "shared/appBar/appBar";
import Heading from "shared/heading/heading";
import { LEFT } from "shared/constants";
import { useLocation, useNavigate } from "react-router-dom";
import OrderProductsList from "shared/orderProductsList/orderProductsList";
import { LARGE, SMALL } from "shared/buttonSize";
import Button from "shared/button/button";
import {
  primaryButtonDisabledStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { ToastMessage } from "shared/toast/toastMsg";
import { OrderAPI } from "apis/orderAPI";
import { getProfileDataFromLocal } from "utils/utils";

export default function OrderDetailsScreen() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isCancel, setIsCancel] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  /**
   *      Handle Cancel Order
   */
  function handleCancelOrder() {
    if (cancelReason.length < 5) {
      return ToastMessage.show("Invalid Cancel Reason");
    } else if (window.confirm("Are you sure you want to cancel order?")) {
      const cancelOrderReqBody = {
        patientId: getProfileDataFromLocal().id,
        orderId: state.order.order_uid,
        cancelReason: cancelReason,
      };
      setIsProcessing(true);
      OrderAPI.cancelMedicineOrder(cancelOrderReqBody)
        .then((response) => {
          if (response.success === true) {
            navigate("/medicine/orders");
          } else {
            return ToastMessage.show("Network Error. Cancelation failed");
          }
          setIsProcessing(false);
        })
        .catch((error) => {
          setIsProcessing(false);
          return ToastMessage.show(
            "Unexpected Error occured. Refresh and try again"
          );
        });
    }
  }

  useEffect(() => {}, []);
  return (
    <>
      <AppBar title="Track Order" />
      <div className={"mt-3"}></div>
      <Heading title="Order Status" align={LEFT} size={SMALL} />
      <div
        className={`${Styles.trackOrderStatus} d-flex justify-content-between mb-5`}
      >
        <div className={"d-flex"}>
          <span
            className={`${Styles.startIconTrackOrder} material-symbols-outlined`}
          >
            shopping_cart
          </span>
          <div className={`${Styles.trackOrderStausText}`}>
            Your order is {state.order.status}
          </div>
        </div>
        <span
          className={`${Styles.endIconTrackOrder} material-symbols-outlined`}
        >
          emoji_transportation
        </span>{" "}
      </div>

      <Heading title="Ordered Products" align={LEFT} size={SMALL} />
      <OrderProductsList productsList={state.order.productsList} />

      <div className={"mt-5"}></div>
      <Heading title="Shipping Address" align={LEFT} size={SMALL} />
      <div className={Styles.trackOrderStatus}>
        {state.order.shipping_address.first_name +
          " " +
          state.order.shipping_address.last_name}
        <br></br>
        {state.order.shipping_address.address_1 +
          ", " +
          state.order.shipping_address.address_2 +
          ", " +
          state.order.shipping_address.city +
          ", " +
          state.order.shipping_address.state +
          ", " +
          state.order.shipping_address.country +
          " - " +
          state.order.shipping_address.postcode}
        <br></br>
        {state.order.shipping_address.phone}
      </div>

      <div className={"mt-5"}></div>
      <Heading
        title="Do you want to cancel your order?"
        align={LEFT}
        size={SMALL}
      />
      <select
        name="cancel_order"
        id="cancel_order"
        className={`${Styles.cancelOrderDropdown}`}
        defaultValue={"default"}
        onChange={(e) => {
          if (state.order.status.toLowerCase() !== "cancelled") {
            setIsCancel(true);
            setCancelReason(e.target.value);
          }
        }}
        disabled={state.order.status === "cancelled" ? true : false}
      >
        <option value="default" disabled>
          Choose a Reason
        </option>
        <option value="Ordered By Mistake">Ordered By Mistake</option>
        <option value="Ordered Wrong Product">Ordered Wrong Product</option>
        <option value="Not Required Anymore">Not Required Anymore</option>
        <option value="Got Cheaper Alternative">Got Cheaper Alternative</option>
        <option value="Price Too High">Price Too High</option>
        <option value="Entered Wrong Shipping Details">
          Entered Wrong Shipping Details
        </option>
        <option value="Others">Others</option>
      </select>

      <div className={"mt-5"}></div>
      {isCancel ? (
        <div onClick={handleCancelOrder}>
          <Button
            loading={isProcessing ? true : false}
            disabled={isProcessing ? true : false}
            style={primaryButtonStyle}
            size={LARGE}
          >
            Cancel
          </Button>
        </div>
      ) : (
        <Button disabled={true} style={primaryButtonDisabledStyle} size={LARGE}>
          Cancel
        </Button>
      )}
    </>
  );
}
