import React, { useEffect, useState } from "react";
import Styles from "./shop.module.scss";
import AppBar from "shared/appBar/appBar";
import { useParams } from "react-router-dom";
import { ToastMessage } from "shared/toast/toastMsg";
import { ProductsAPI } from "apis/productsAPI";
import CartIcon from "shared/cartIcon/cartIcon";

export default function LabTestDetails() {
  const { id } = useParams();
  const [testData, setTestData] = useState<any>({});

  function fetchTestDetails() {
    ProductsAPI.getPathologyLabTests({ rowsPerPage: 50 })
      .then((response) => {
        if (response.success === true) {
          const labTest = response.data.serviceList
            .map((labtest) => {
              return {
                id: labtest._id,
                test_name: labtest.serviceName,
                brand: labtest.brandName,
                sale_price: labtest.salesPrice,
                published_price: labtest.publishedPrice,
                warning: labtest.testPrerequisite,
                description: labtest.description,
              };
            })
            .filter((e) => e.id === id);

          setTestData(labTest);
        } else {
          return ToastMessage.show(
            "Error fetching data. Refresh and try again"
          );
        }
      })
      .catch((error) => {
        return ToastMessage.show("Error fetching data. Please try agian later");
      });
  }
  useEffect(() => {
    fetchTestDetails();
  }, []);
  return (
    <>
      <AppBar
        title={"Lab Test Details"}
        action={<CartIcon type={"labtest"} />}
      />

      {testData && testData.length > 0 ? (
        <div>
          <div className={`${Styles.testDetails}`}>
            <div className={`${Styles.testDetailsTitle}`}>
              {testData[0].test_name}
            </div>
            <div className={`${Styles.testDetailsBrand} mb-3`}>
              {testData[0].brand}
            </div>
            <div className={`${Styles.testDetailsWarning}`}>
              <span className="material-symbols-outlined">warning</span>
              {testData[0].warning}
            </div>
          </div>
          <div className={`${Styles.aboutContainer}`}>
            <div className={`${Styles.testDetailsTitle}`}>About</div>
            <div
              className={`${Styles.details}`}
              dangerouslySetInnerHTML={{ __html: testData[0].description }}
            ></div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
