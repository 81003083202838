import React from "react";
import AppBar from "shared/appBar/appBar";
import PathologyTest from "shared/pathologyTest/pathologyTest";

export default function Pathology() {
  const labTests = [
    {
      id: 1,
      title:
        "My Product 1 jksdf jhgv jh akjsdb fabs dkjfb ajkbdsf jabhs dasjbdf ajksbdh f",
      sellingPrice: 456,
      regularPrice: 546,
      ratingValue: 4,
      ratingCount: 1.2,
    },
    {
      id: 2,
      title: "My Product 1",
      sellingPrice: 456,
      regularPrice: 546,
      ratingValue: 4,
      ratingCount: 1.2,
    },
    {
      id: 3,
      title: "My Product 1",
      sellingPrice: 456,
      regularPrice: 546,
      ratingValue: 4,
      ratingCount: 1.2,
    },
    {
      id: 4,
      title: "My Product 1",
      sellingPrice: 456,
      regularPrice: 546,
      ratingValue: 4,
      ratingCount: 1.2,
    },
    {
      id: 5,
      title: "My Product 1",
      sellingPrice: 456,
      regularPrice: 546,
      ratingValue: 4,
      ratingCount: 1.2,
    },
  ];
  return (
    <>
      <AppBar title="Pathology" />

      <PathologyTest labTestsList={labTests} />
    </>
  );
}
