import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from "react-router-dom";
import { ContextProvider } from "context/user.context";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";

const root = ReactDOM.createRoot(document.getElementById("root")!);
const agoraClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ContextProvider value={500}>
      <AgoraRTCProvider client={agoraClient}>
        <App />
      </AgoraRTCProvider>
    </ContextProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
