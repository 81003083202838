import React, { Fragment, useEffect, useState } from "react";
import Styles from "./subscriptions.module.scss";
import AppBar from "shared/appBar/appBar";
import BottomSheet from "shared/bottomSheet/bottomSheet";
import { LARGE, UserDefaultImage_Male } from "shared/constants";
import Button from "shared/button/button";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import Faqs from "shared/faqs/faqs";
import { MembershipAPI } from "apis/membershipAPI";
import Loading from "shared/loading/loading";

/**
 *  Component respnosible for displaying all the data related to
 *  Memberships / Plans / Subscriptions
 */
export default function Plans() {
  const [planSelectedId, setPlanSelecteId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [plansData, setPlansData] = useState([]);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [knowMoreData, setKnowMoreData] = useState<any>();

  function onClose() {
    setIsOpen(false);
  }

  function onOpen() {
    setIsOpen(true);
  }

  function onToggle() {
    setIsOpen(true);
  }

  function showPlanInfo(e, planId) {
    onToggle();
    // e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const planDetails: any = plansData.filter(
      (plan: any) => plan.id === planId
    );
    // htmlContentForPlan = (
    //   <>
    //     <img
    //       className={Styles.planDetailsImage}
    //       src={planDetails[0].image ?? DefaultProfileImage}
    //       alt="Plan"
    //     />
    //     <div className={Styles.planDetailsInfo}>
    //       {planDetails[0].title}
    //       <p>{planDetails[0].description}</p>
    //     </div>
    //   </>
    // );
    const knowMoreHtml = (
      <>
        <div className={Styles.planDetailsImageContainer}>
          <img
            className={Styles.planDetailsImage}
            src={planDetails[0].image ?? UserDefaultImage_Male}
            alt="Membership Plan"
          />
        </div>
        <div className={`${Styles.planDetailsInfo} p-4`}>
          <h4 className={`${Styles.planDetailsTitle} pb-3`}>
            {planDetails[0].title}
          </h4>
          <p
            dangerouslySetInnerHTML={{ __html: planDetails[0].description }}
          ></p>
        </div>
      </>
    );
    setKnowMoreData(knowMoreHtml);
  }

  const faqsList = [
    {
      ques: "How can I avail my discount?",
      ans: "ajbsdf abjks dbfkjabs dkfjbhas kjdbhfkjasb dfkjbhasdkbj.",
    },
    {
      ques: "How can I avail my discount?",
      ans: "ajbsdf abjks dbfkjabs dkfjbhas kjdbhfkjasb dfkjbhasdkbj.",
    },
    {
      ques: "How can I avail my discount?",
      ans: "ajbsdf abjks dbfkjabs dkfjbhas kjdbhfkjasb dfkjbhasdkbj.",
    },
    {
      ques: "How can I avail my discount?",
      ans: "ajbsdf abjks dbfkjabs dkfjbhas kjdbhfkjasb dfkjbhasdkbj.",
    },
    {
      ques: "How can I avail my discount?",
      ans: "ajbsdf abjks dbfkjabs dkfjbhas kjdbhfkjasb dfkjbhasdkbj.",
    },
    {
      ques: "How can I avail my discount?",
      ans: "ajbsdf abjks dbfkjabs dkfjbhas kjdbhfkjasb dfkjbhasdkbj.",
    },
    {
      ques: "How can I avail my discount?",
      ans: "ajbsdf abjks dbfkjabs dkfjbhas kjdbhfkjasb dfkjbhasdkbj.",
    },
  ];

  function getPlansData() {
    setIsLoadingPlans(true);
    MembershipAPI.getMembershipPlans()
      .then((response) => {
        if (response.success === true) {
          const membershipPlansData = response.data.subscriptionList.filter(
            (plan) => plan.membershipPlanId.isActive === true
          );
          const plansFetched = membershipPlansData.map(
            (subscriptionPlan: any) => {
              return {
                id: subscriptionPlan.membershipPlanId._id,
                title: subscriptionPlan.membershipPlanId.planName,
                amount: subscriptionPlan.price,
                validity: subscriptionPlan.membershipPlanId.expiryMonth,
                description: subscriptionPlan.membershipPlanId.description,
                image: subscriptionPlan.membershipPlanId.image,
              };
            }
          );

          setPlansData(plansFetched);
          setIsLoadingPlans(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching membership data", error);
        setIsLoadingPlans(false);
      });
  }
  useEffect(() => {
    getPlansData();
  }, []);
  return (
    <>
      <AppBar title="Plans" />

      {/* {banners.PatientHomeTop.length > 0 ? (
        <CarouselSlider imagesList={banners.PatientHomeTop} />
      ) : (
        <Loading loadingColor={"#E85222"} />
      )} */}
      <div className={Styles.carousleContainer}></div>
      <div className={Styles.plansContainer}>
        <div className={Styles.plansHeading}>
          Choose the plan right for you!
        </div>
        <div className={Styles.plansContainer}>
          {isLoadingPlans ? (
            <Loading loadingColor={"#E85222"} />
          ) : (
            plansData.map((plan: any) => {
              return (
                <div
                  className={`${Styles.plan} d-flex justify-content-between`}
                  onClick={() => setPlanSelecteId(plan.id)}
                  key={plan.id}
                >
                  <div className={Styles.planCardlhs}>
                    <span
                      className={`${Styles.materialIconFilled} material-symbols-outlined`}
                      style={{
                        verticalAlign: "middle",
                        fontSize: "20px",
                        color:
                          planSelectedId === plan.id ? "#008080" : "silver",
                        fill: "#008080",
                      }}
                    >
                      check_circle
                    </span>
                    &nbsp;{plan.title}
                    <div
                      className={Styles.knowMore}
                      onClick={(e) => {
                        showPlanInfo(e, plan.id);
                      }}
                    >
                      Know More
                    </div>
                  </div>
                  <div className={Styles.planCardrhs}>
                    <div className={Styles.planAmount}>
                      &#8377; {plan.amount}
                    </div>
                    <div className={Styles.planValidity}>
                      Validity:{" "}
                      <span className={Styles.validity}>
                        {plan.validity} Months
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        <br />
        <Button
          disabled={false}
          style={primaryButtonStyle}
          hoveredStyle={primaryButtonHoveredStyle}
          size={LARGE}
        >
          Make Payment
        </Button>

        <div className={Styles.faqsSection}>
          <div className={Styles.faqsHeading}>FAQs</div>
          <div className={Styles.faqsContent}>
            <Faqs faqList={faqsList} />
          </div>
        </div>
      </div>
      <BottomSheet
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        size={LARGE}
        content={knowMoreData}
      />
    </>
  );
}
