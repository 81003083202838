import { apiResponse } from "apis/interface";

/**
 *    Validate Response from API
 *    If response is success than return success=true else false
 *    @returns result{success, message, data}
 */
export function validateResponse(response: any) {
  let result: apiResponse;
  if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === true
  ) {
    result = {
      success: true,
      message: "API successfull",
      data: response.data.data,
    };
  } else {
    result = {
      success: false,
      message: "Error executing API",
      data: response,
    };
  }
  return result;
}
