import React from "react";
import Styles from "./display.module.scss";
import { Link } from "react-router-dom";
import Button from "shared/button/button";
import { LARGE } from "shared/buttonSize";
import {
  primaryButtonStyle,
  primaryButtonHoveredStyle,
} from "shared/buttonStyles";

type DisplayData = {
  image: string;
  title: string;
  subTitle: String;
  buttonText: String;
  buttonLink: String;
};

export default function DisplayScreen(data: DisplayData) {
  return (
    <div className={`${Styles.pageContainer}`}>
      <img src={data.image} alt={data.title} className="img-fluid" />
      <div className={`${Styles.title}`}>{data.title}</div>
      <div className={`${Styles.subTitle}`}>{data.subTitle}</div>
      <div className={`${Styles.alignBottom}`}>
        <Link to="/" state={{ mobile: "" }}>
          <Button
            disabled={false}
            style={primaryButtonStyle}
            hoveredStyle={primaryButtonHoveredStyle}
            size={LARGE}
          >
            {data.buttonText}
          </Button>
        </Link>
      </div>
    </div>
  );
}
