import React from "react";
import Styles from "./productCartList.module.scss";
import { ProductDefaultImage } from "shared/constants";
import { CartAPI } from "apis/cartAPI";
import { getCartIdFromLocal, getProfileDataFromLocal } from "utils/utils";
import { ToastMessage } from "shared/toast/toastMsg";

export default function ProductCartList({
  productList = [{}],
  qtyUpdateCallback,
  deleteProductCallback,
}: any) {
  function updateCartProducts(productId, qty) {
    const updateCartReqBody = {
      patientId: getProfileDataFromLocal().id,
      productId: productId,
      quantity: qty,
    };
    // Update Cart
    CartAPI.addCartProduct(updateCartReqBody)
      .then((response) => {
        if (response.success === true) {
          qtyUpdateCallback();
        } else {
          return ToastMessage.show(
            "Error updating cart. Refresh and try again."
          );
        }
      })
      .catch((error) => {
        return ToastMessage.show("Network error! Please refresh and try again");
      });
  }

  /**
   *  Handle Product Delete from cart
   */
  function handleDeleteProduct(productId) {
    if (window.confirm("Remove Product from cart?")) {
      const reqBodyRemoveProduct = {
        productId,
        cartId: getCartIdFromLocal(),
        removeType: "all",
      };
      CartAPI.removeCartProduct(reqBodyRemoveProduct)
        .then((response) => {
          if (response.success === true) {
            deleteProductCallback();
            return ToastMessage.show("Product deleted successfully!");
          } else
            return ToastMessage.show("Product not deleted. Please try again");
        })
        .catch((error) => {
          return ToastMessage.show("Netowrk Error. Product not deleted", error);
        });
    }
  }
  return (
    <>
      {productList.map((product, index) => {
        return (
          <div className={`${Styles.product} d-flex`} key={index}>
            <div className={Styles.image}>
              <img
                src={product.thumbnail ?? ProductDefaultImage}
                alt="Product"
              />
            </div>
            <div className={Styles.productInfo}>
              <div className={Styles.productTitle}>{product.name}</div>
              <select
                name=""
                id=""
                className={Styles.qtySelector}
                defaultValue={product.qty}
                onChange={(e) => {
                  updateCartProducts(product.id, e.target.value);
                }}
              >
                <option value="1">Qty: 1</option>
                <option value="2">Qty: 2</option>
                <option value="3">Qty: 3</option>
                <option value="4">Qty: 4</option>
                <option value="5">Qty: 5</option>
              </select>
            </div>
            <div className={Styles.rhsContainer}>
              <div
                className={Styles.closeButton}
                onClick={() => handleDeleteProduct(product.id)}
              >
                <span className="material-symbols-outlined">close</span>
              </div>
              <div className={Styles.priceLabel}>&#8377; {product.price}</div>
            </div>
          </div>
        );
      })}
    </>
  );
}
