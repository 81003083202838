import React from "react";
import WebView from "shared/webView/webview";

export default function AboutUs() {
  return (
    <>
      <WebView pageTitle={"About Us"} />
    </>
  );
}
