import React, { useEffect, useState } from "react";
import Styles from "./cart.module.scss";
import AppBar from "shared/appBar/appBar";
import { BottomNavigation } from "shared/bottomNavigation/bottomNavigation";
import { CART } from "shared/constants";
import { Link } from "react-router-dom";
import { CartAPI } from "apis/cartAPI";
import { ToastMessage } from "shared/toast/toastMsg";

export default function Cart() {
  const [medicineCartCount, setMedicineCartCount] = useState(0);
  const [labTestCartCount, setLabTestCartCount] = useState(0);

  // Fetch Medicine cart
  function fetchMedicineCart() {
    CartAPI.getCartProducts()
      .then((response) => {
        if (response.success === true) {
          // set product's Cart in local
          if (response.data && response.data.items.length > 0) {
            const cartProducts = response.data.items.map((product) => {
              return {
                productId: product.productId._id,
                qty: product.quantity,
              };
            });
            localStorage.setItem("cartProducts", JSON.stringify(cartProducts));
            localStorage.setItem("cartId", response.data._id);
          }
          if (response.data === null || response.data.items.length === 0) {
            localStorage.removeItem("cartProducts");
          }

          return setMedicineCartCount(
            response.data && response.data.totalItems
              ? response.data.totalItems
              : 0
          );
        }
        return ToastMessage.show("Network Error! Please try agian later");
      })
      .catch((error) => {
        return ToastMessage.show(
          "Network Error! Please try agian later",
          error
        );
      });
  }
  // Fetch Lab Test Cart
  function fetchLabTestCart() {
    CartAPI.getCartLabTest()
      .then((response) => {
        if (response.success === true) {
          return setLabTestCartCount(
            response.data && response.data.totalItems
              ? response.data.totalItems
              : 0
          );
        }
        return ToastMessage.show("Error fetch Pathology tests!");
      })
      .catch((error) => {
        return ToastMessage.show(
          "Network Error! Please try agian later",
          error
        );
      });
  }

  useEffect(() => {
    fetchMedicineCart();
    fetchLabTestCart();
  }, []);
  return (
    <>
      <AppBar title="My Cart" />
      <h5 style={{ textAlign: "left" }} className="mt-4">
        Select a cart to checkout
      </h5>

      <Link to={"/cart/medicine"}>
        <div className={`${Styles.cartType} d-flex mt-4`}>
          <div className={Styles.cardInfo}>
            <div>Medicines</div>
            <div>{medicineCartCount} items</div>
          </div>
          <div className={Styles.medicineCartIcon}>
            <span className="material-symbols-outlined">pill</span>
          </div>
        </div>
      </Link>

      <Link to={"/cart/pathology"}>
        <div className={`${Styles.cartType} d-flex`}>
          <div className={Styles.cardInfo}>
            <div>Pathology Tests</div>
            <div>{labTestCartCount} items</div>
          </div>
          <div className={Styles.medicineCartIcon}>
            <span className="material-symbols-outlined">vaccines</span>
          </div>
        </div>
      </Link>

      {/* Bottom Navigtion */}
      <BottomNavigation navigationSelection={CART} />
    </>
  );
}
