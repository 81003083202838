import React, { useEffect, useState } from "react";
import Styles from "./product.module.scss";
import {
  primaryButtonDisabledStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import Button from "shared/button/button";
import { ProductDefaultImage, SMALL } from "shared/constants";
import { Link } from "react-router-dom";
import { ToastMessage } from "shared/toast/toastMsg";
import { CartAPI } from "apis/cartAPI";
import { getProfileDataFromLocal } from "utils/utils";

export default function LabTestCard({ productsList, handleAddCallback }) {
  const [inCartProducts, setInCartProducts] = useState<string[]>([]);

  /**
   *    Handle "Add to Cart" button click
   */
  function addLabTestToCart(e, testId) {
    handleAddCallback(testId);
    e.preventDefault();
    const addProductToCartReqBody = {
      // productId,
      patientId: getProfileDataFromLocal().id,
      quantity: 1,
    };
    CartAPI.addCartProduct(addProductToCartReqBody)
      .then((response) => {
        if (response.success === true) {
          // add product ID to state variable
          setInCartProducts([...inCartProducts, testId]);
          return ToastMessage.show("Product added to cart.");
        } else {
          return ToastMessage.show("Error adding product.");
        }
      })
      .catch((err) => {
        return ToastMessage.show("Error adding product.", err);
      });
  }
  useEffect(() => {
    const cartProducts = JSON.parse(localStorage.getItem("cartPathology")!);
    if (cartProducts) setInCartProducts(cartProducts.map((e) => e.productId));
  }, []);
  return (
    <div>
      {productsList.map((product) => {
        return (
          <div key={product.id}>
            <div className={`${Styles.productCardContainer} d-flex mb-3`}>
              <div className="">
                <Link to={`/pathology/${product.id}`}>
                  <div className="d-flex">
                    <div
                      className={`${Styles.productCardImage} d-flex align-items-center`}
                    >
                      <img
                        src={
                          product.thumbnail
                            ? product.thumbnail
                            : ProductDefaultImage
                        }
                        alt="Product Title"
                      />
                    </div>
                    <div className={`${Styles.productCardContent}`}>
                      <div className={`${Styles.labtestCardTitle}`}>
                        {product.test_name}
                      </div>
                      <div className={`${Styles.labtestBrand}`}>askhfklahs</div>
                      <div
                        className={`${Styles.labtestCardPriceContainer} d-flex align-contents-bottom`}
                      >
                        <div className={`${Styles.productCardSellingPrice}`}>
                          &#8377;{product.sale_price}
                        </div>
                        {product.sale_price !== product.published_price ? (
                          <div className={`${Styles.productCardRegularPrice}`}>
                            &nbsp;&nbsp;MRP{" "}
                            <del>&#8377;{product.published_price}</del>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className={Styles.productCardActions}>
                <div
                  className={Styles.productCardBuyButton}
                  onClick={(e) => addLabTestToCart(e, product.id)}
                >
                  <Button
                    disabled={
                      inCartProducts.indexOf(product.id) === -1 ? false : true
                    }
                    style={
                      inCartProducts.indexOf(product.id) === -1
                        ? primaryButtonStyle
                        : primaryButtonDisabledStyle
                    }
                    size={SMALL}
                  >
                    {inCartProducts.indexOf(product.id) === -1
                      ? "Add"
                      : "In Cart"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
