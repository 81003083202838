import React, { useState } from "react";

export const UserContext = React.createContext({});

const initalState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
};

export const ContextProvider = (props) => {
  const [loginState, setLoginState] = useState(initalState);

  const setLoginPending = (isLoginPending) => setLoginState(isLoginPending);
  const setLoginSuccess = (isLoggedIn) => setLoginState(isLoggedIn);
  const setLoginError = (loginError) => setLoginState(loginError);

  const login = (email, password) => {
    setLoginPending(true);
    setLoginSuccess(false);
    setLoginError(null);

    fetchLogin(email, password, (error) => {
      if (!error) setLoginSuccess(true);
      else setLoginError(error);
    });
  };

  const logout = () => {
    setLoginPending(false);
    setLoginSuccess(false);
    setLoginError(null);
  };

  return (
    <UserContext.Provider value={{ loginState, login, logout }}>
      {props.children}
    </UserContext.Provider>
  );
};

// fake login
const fetchLogin = (email, password, callback) =>
  setTimeout(() => {
    if (email === "admin" && password === "admin") {
      return callback(null);
    } else {
      return callback(new Error("Invalid email and password"));
    }
  }, 1000);
