import React, { useEffect, useState } from "react";
import Styles from "./addressSelection.module.scss";
import { ProfileAPI } from "apis/profileAPI";
import { useNavigate } from "react-router-dom";
import Heading from "shared/heading/heading";
import { LEFT } from "shared/constants";
import NotFound from "shared/notFound/notFound";

export default function AddressSelection({ addressSelectionCallback }) {
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState([]);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [selectedAddressData, setSelectedAddressData] = useState({});
  /**
   *    Fetch All addresses
   *    for the logged in account
   */
  function getAddresses() {
    ProfileAPI.getAllAddresses()
      .then((response) => {
        if (response.success === true) {
          const addresses = response.data.map((address: any) => {
            if (!isAddressSelected && address.isSelect) {
              setIsAddressSelected(true);
            }
            return {
              addressId: address._id,
              userName: address.name,
              address:
                address.house_flat_no +
                ", " +
                address.area_street +
                ", " +
                address.city +
                ", " +
                address.state +
                " (" +
                address.pincode +
                ") " +
                address.mobile,
              selected: address.isSelect,
            };
          });
          setAddressList(addresses);
        } else {
          throw new Error("Error fetching addresses");
        }
      })
      .catch((error) => {
        return "Error: Unable to get addresses!";
      });
  }

  /**
   *  Handle Address Selection
   */
  function handleConfirmButton(address) {
    // setaddressIdSelected();
    // return to parent address details.
    addressSelectionCallback(address);
  }

  useEffect(() => {
    getAddresses();
  }, []);
  return (
    <div className={`${Styles.mainContainer} p-3 mt-3`}>
      <Heading title={"Select Address"} align={LEFT} />
      <div className={`${Styles.topHeader} d-flex mb-3`}>
        <div className={Styles.topLabel}>
          Select your delivery address for this order
        </div>
      </div>
      <div className={`${Styles.addressesContainer}`}>
        {addressList && addressList.length > 0 ? (
          addressList.map((address: any, index) => (
            <div
              className={`${Styles.addressContainer} d-flex mb-3`}
              key={index}
            >
              <div
                className={Styles.checkboxConatiner}
                onClick={() => {
                  setIsAddressSelected(true);
                  setSelectedAddressData(address);
                }}
              >
                <input type="radio" name="address" id="" />
              </div>
              <div className={Styles.displayAddressInfo}>
                <div className={Styles.addressTitleName}>
                  {address.userName}
                </div>
                <div className={Styles.addressAddress}>{address.address}</div>
              </div>
            </div>
          ))
        ) : (
          <NotFound />
        )}
      </div>
      <div
        className={`${Styles.buttonsContainer} d-flex justify-content-between`}
      >
        <div
          className={`${Styles.lightButton}`}
          onClick={() => navigate("/profile/addAddress")}
        >
          Add Address
        </div>
        <div
          className={`${Styles.primaryButton} ${
            isAddressSelected ? Styles.active : ""
          }`}
          onClick={() => {
            if (isAddressSelected) handleConfirmButton(selectedAddressData);
          }}
        >
          Confirm
        </div>
      </div>
    </div>
  );
}
