import { validateResponse } from "utils/apiValidations";
import {
  baseURL_wooCommerce,
  pathalogy_getLabTests,
  products_featuredProducts,
  products_productViaId,
  products_relatedProducts,
  wooCom_getProductsViaId,
} from "./apiEndpoints";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";

export const ProductsAPI = {
  getFeatured: async function (cancel = false) {
    const response = await api.request({
      url: products_featuredProducts,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getFeatured.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    // const result = validateResponse(response);
    return response;
  },
  getProductById: async function (id, cancel = false) {
    const response = await api.request({
      url: products_productViaId + id,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getProductById.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    return response;
  },
  getProductById_wooCom: async function (ids: [], cancel = false) {
    const response = await api.request({
      baseURL: baseURL_wooCommerce,
      url: wooCom_getProductsViaId,
      method: "GET",
      data: {
        include: { ids },
      },
      auth: {
        username: "ck_c5867ad58359a5e3b0c76ef9423a1bde00815518",
        password: "cs_9ad790041288062cf566d3f87b582bfd995fce19",
      },
      signal: cancel
        ? cancelApiObject[
            this.getProductById_wooCom.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    return response;
  },
  getRelatedProductsById: async function (id, cancel = false) {
    const response = await api.request({
      url: products_relatedProducts + id,
      method: "GET",
      signal: cancel
        ? cancelApiObject[
            this.getRelatedProductsById.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    return validateResponse(response);
  },
  getPathologyLabTests: async function (data, cancel = false) {
    const response = await api.request({
      url: pathalogy_getLabTests,
      method: "POST",
      data,
      signal: cancel
        ? cancelApiObject[
            this.getPathologyLabTests.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ProductsAPI);
