import "./App.css";
import React from "react";
import withSplashScreen from "components/withSplashScreen/withSplashScreen";
import SysRouter from "sysRouter";
import { ToastMsg } from "shared/toast/toastMsg";

function App() {
  return (
    <div className="App p-3">
      <ToastMsg />
      <SysRouter />
    </div>
  );
}

export default withSplashScreen(App);
