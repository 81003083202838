import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const ReadBlog = () => {
  const { state } = useLocation();
  useState(() => {
    console.log(state);
  });
  return <div dangerouslySetInnerHTML={{ __html: state }}></div>;
};

export default ReadBlog;
