export const Utils = {
  /**
   *  Check if the date is passed w.r.t today's date.
   *
   * @param dateToCheck Date to be checked. Format: dd/mm/yyyy.
   *
   * @returns boolean True/False
   */
  isPastDate: (dateToCheck: string) => {
    dateToCheck =
      dateToCheck.split("/")[1] +
      "/" +
      dateToCheck.split("/")[0] +
      "/" +
      dateToCheck.split("/")[2];
    const d1 = new Date(dateToCheck).getTime();
    const d2 = new Date().getTime();

    return d1 < d2;
  },
};
