import React, { useMemo } from "react";
import Styles from "./otpInput.module.scss";
import { REG_DIGIT } from "shared/constants";

export interface OtpInputProps {
  value: string;
  otpLength: number;
  onChange: (value: string) => void;
}

export default function OtpInput(props: OtpInputProps) {
  // Get input string as unique char array
  const valueItems = useMemo(() => {
    const valueArray = props.value.split("");
    const items: Array<string> = [];

    for (let i = 0; i < props.otpLength; i++) {
      const char = valueArray[i];

      if (REG_DIGIT.test(char)) items.push(char);
      else items.push("");
    }
    return items;
  }, [props.value, props.otpLength]);

  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    let targetValue = e.target.value.trim();
    const isDigit = REG_DIGIT.test(targetValue);
    if (!isDigit && targetValue !== "") return;
    //    If target Value is not digit assign it with empty Space to show empty field
    targetValue = isDigit ? targetValue : " ";

    if (targetValue.length === 1) {
      const newValue =
        props.value.substring(0, idx) +
        targetValue +
        props.value.substring(idx + 1);

      props.onChange(newValue);

      if (!isDigit) return;

      //    If next input field exists shift the focus to that
      const nextElement = e.target
        .nextElementSibling as HTMLInputElement | null;
      if (nextElement) nextElement.focus();
    } else if (targetValue.length === props.otpLength) {
      props.onChange(targetValue);
      e.target.blur();
    }
  };

  // To handle moving to prev field when backspace
  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    target.setSelectionRange(0, target.value.length);

    if (e.key !== "Backspace" || target.value !== "") return;
    const prevElement =
      target.previousElementSibling as HTMLInputElement | null;
    if (prevElement) prevElement.focus();
  };

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    const prevElement =
      target.previousElementSibling as HTMLInputElement | null;

    if (prevElement && prevElement.value === "") return prevElement.focus();
    target.setSelectionRange(0, target.value.length);
  };
  return (
    <div className={Styles.otpGroup}>
      {valueItems.map((digit, idx) => (
        <input
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          autoFocus={true}
          pattern="\d{1}"
          maxLength={props.otpLength}
          className={`${Styles.otpInput} form-control`}
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
        />
      ))}
    </div>
  );
}
