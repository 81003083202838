import { validateResponse } from "utils/apiValidations";
import { defineCancelApiObject } from "./config/axiosUtils";
import { api } from "./config/axiosConfig";
import {
  cart_addProduct,
  cart_getLabTest,
  cart_getMedicineCart,
  cart_moveLocalToRemote,
  cart_removeProduct,
} from "./apiEndpoints";
import { getAccessTokenFromLocal } from "utils/utils";

export const CartAPI = {
  getCartProducts: async function (cancel = false) {
    const response = await api.request({
      url: cart_getMedicineCart,
      method: "GET",
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.getCartProducts.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  addCartProduct: async function (data, cancel = false) {
    const response = await api.request({
      url: cart_addProduct,
      method: "POST",
      data,
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.addCartProduct.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    if (result.success === true) {
      const cartProducts = result.data.items.map((product) => {
        return { productId: product.productId, qty: product.quantity };
      });

      // Save this to localStorage
      localStorage.setItem("cartProducts", JSON.stringify(cartProducts));
    }
    return result;
  },
  removeCartProduct: async function (data, cancel = false) {
    const response = await api.request({
      url: cart_removeProduct,
      method: "PUT",
      data,
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[
            this.removeCartProduct.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  syncLocalCartToRemote: async function (data, cancel = false) {
    const response = await api.request({
      url: cart_moveLocalToRemote,
      method: "POST",
      data,
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[
            this.syncLocalCartToRemote.name
          ].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
  getCartLabTest: async function (cancel = false) {
    const response = await api.request({
      url: cart_getLabTest,
      method: "GET",
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.getCartLabTest.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(CartAPI);
