import React, { useEffect, useRef, useState } from "react";
import Styles from "./updateProfile.module.scss";
import AppBar from "shared/appBar/appBar";
import InputField from "shared/inputField/inputField";
import {
  ACTIVE,
  ERROR,
  LARGE,
  UserDefaultImage_Male,
  VALID,
} from "shared/constants";
import { useNavigate } from "react-router-dom";
import Button from "shared/button/button";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";
import { ProfileAPI } from "apis/profileAPI";
import { ToastMessage } from "shared/toast/toastMsg";
import { IntProfileData } from "apis/interface";
import { swapYYYYMMDD_and_DDMMYYYY } from "utils/utils";

export default function UpdateProfile() {
  const navigate = useNavigate();
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
  const [profileData, setProfileData] = useState<IntProfileData>({
    id: "",
    name: "Guest",
    dob: "",
    mobile: "",
    gender: "",
    referralCode: "",
    walletPoints: 0,
    email: "",
    image: UserDefaultImage_Male,
    access_token: "",
  });
  const hiddenFileInput = useRef<any>(null);
  const [name, setName] = useState<{ value: string; error?: boolean }>({
    value: "",
  });
  const [dob, setDob] = useState<{ value: string; error?: boolean }>({
    value: "",
  });
  const [gender, setGender] = useState<{ value: string; error?: boolean }>({
    value: "",
  });
  const [email, setEmail] = useState<{ value: string; error?: boolean }>({
    value: "",
  });
  const validateDob = () => {
    setDob({ value: dob.value, error: false });
  };
  const validateName = () => {
    if (name.value.length < 2) setName({ value: name.value, error: true });
    else setName({ value: name.value, error: false });
  };
  const validateGender = () => {
    if (gender.value === "") setGender({ value: gender.value, error: true });
    else setGender({ value: gender.value, error: false });
  };

  const validateEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (emailRegex.test(email.value))
      setEmail({ value: email.value, error: false });
    else setEmail({ value: email.value, error: true });
  };

  const handleEditClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  const handleProfileImageUpload = (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    ProfileAPI.editProfileData(formData, "multipart/form-data")
      .then((response) => {
        if (response.success === true) {
          ToastMessage.show("Profile Image Updated Successfully!");
          setProfileData({ ...profileData, image: response.data.image });
        } else {
          ToastMessage.show("Error updating Profile Image!");
        }
      })
      .catch((err) => {
        ToastMessage.show("Error updating Profile Image!");
        console.log("Error updateing file: ", err);
      });
  };

  function updateProfileChanges() {
    // Disbale Button Once clicked
    setButtonIsDisabled(true);
    const formData = new FormData();
    formData.append("name", name.value);
    formData.append("email", email.value);
    formData.append("dob", swapYYYYMMDD_and_DDMMYYYY(dob.value));
    formData.append("gender", gender.value);
    formData.append("mobile", profileData.mobile);

    ProfileAPI.editProfileData(formData, "multipart/form-data")
      .then((response) => {
        if (response.success === true) {
          ToastMessage.show("Profile Details Updated");
          navigate("/profile");
        }
        setButtonIsDisabled(false);
      })
      .catch((error) => {
        return "Error: Failed to update profile info!" + error;
      });
  }

  useEffect(() => {
    const profileInfo: IntProfileData = localStorage.getItem("profileData")
      ? JSON.parse(localStorage.getItem("profileData")!)
      : {
          id: "",
          name: "Guest",
          dob: "",
          mobile: "",
          gender: "",
          image: UserDefaultImage_Male,
          access_token: "",
        };
    profileInfo.image = profileInfo.image ?? UserDefaultImage_Male;
    setName({ value: profileInfo.name });
    setDob({ value: profileInfo.dob.split("T")[0] });
    setEmail({ value: profileInfo.email });
    setGender({ value: profileInfo.gender });
    setProfileData(profileInfo);
  }, []);
  return (
    <>
      <AppBar title="Update Profile" />
      <div className={Styles.profileCard_Image}>
        <img
          src={profileData.image}
          alt="Profile Pic"
          className={Styles.profileImage}
        />
        <div className={Styles.editIcon} onClick={handleEditClick}>
          <span className="material-symbols-outlined">edit</span>
        </div>
      </div>
      <input
        type="file"
        onChange={(e) => handleProfileImageUpload(e)}
        ref={hiddenFileInput}
        style={{ display: "none" }}
      />
      <div className={"vstack gap-3"}>
        <InputField
          placeholder="Enter your name"
          autoFocus
          onChange={(e: { target: { value: any } }) => {
            setButtonIsDisabled(false);
            setName({ value: e.target.value });
          }}
          onBlur={validateName}
          errorText="Invalid Name"
          isValidState={
            typeof name.error === "undefined"
              ? ACTIVE
              : name.error
              ? ERROR
              : VALID
          }
          value={name.value}
        />
        <InputField
          placeholder="Email Id"
          onChange={(e: { target: { value: any } }) => {
            setButtonIsDisabled(false);
            setEmail({ value: e.target.value });
          }}
          onBlur={validateEmail}
          errorText="Invalid Email"
          isValidState={
            typeof email.error === "undefined"
              ? ACTIVE
              : email.error
              ? ERROR
              : VALID
          }
          value={email.value.length !== 0 ? email.value : ""}
        />
        <InputField
          placeholder="Date Of Birth"
          onChange={(e: { target: { value: any } }) => {
            setButtonIsDisabled(false);
            setDob({ value: e.target.value });
          }}
          onBlur={validateDob}
          errorText="Invalid DOB"
          isValidState={
            typeof dob.error === "undefined"
              ? ACTIVE
              : dob.error
              ? ERROR
              : VALID
          }
          value={dob.value}
          type="date"
        />
        <select
          className={` ${Styles.dropdown} form-control form-control-lg`}
          onChange={(e) => {
            setButtonIsDisabled(false);
            setGender({ value: e.target.value });
          }}
          onBlur={validateGender}
          value={gender.value.length !== 0 ? gender.value : ""}
        >
          <option value="" disabled>
            -- Select Gender --
          </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      </div>

      <div
        className={Styles.bottomButtonContainer}
        onClick={() => updateProfileChanges()}
      >
        <Button
          disabled={buttonIsDisabled}
          style={primaryButtonStyle}
          hoveredStyle={primaryButtonHoveredStyle}
          size={LARGE}
        >
          Save Changes
        </Button>
      </div>
    </>
  );
}
