import React, { Component } from 'react';
import Styles from './withSplashScreen.module.scss';
const KapeefitLogo = require("../../assets/images/logo.svg");

function LoadingMessage() {
    return (
        <div className={`${Styles.splashScreen} row mx-auto`}>
            {/* Wait a moment while we load your app. */}
            {/* <div className={Styles.loadingDot}>.</div> */}
            <img src={KapeefitLogo} alt="" width="300px" className={`mx-auto d-block`} />
        </div>
    );
}

function withSplashScreen(WrappedComponent) {
    return class extends Component<{}, { loading: boolean }> {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
            };
        }

        async componentDidMount() {
            try {
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 0);  //  Set time to show the splash Screen
            } catch (err) {
                console.log("Error: ", err);
                this.setState({
                    loading: false,
                });
            }
        }

        render() {
            if (this.state.loading) return LoadingMessage();

            return <WrappedComponent {...this.props} />
        }
    }
}

export default withSplashScreen;